import { Component, OnInit, Optional, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../../model/data.model';
import { Router } from '@angular/router';
import {SharedDataService} from '../../services/shared-data.service';
import {ApiService} from '../../services/api.service'
@Component({
  selector: 'app-choose-policy',
  templateUrl: './choose-policy.component.html',
  styleUrls: ['./choose-policy.component.scss']
})
export class ChoosePolicyComponent implements OnInit {
  policy_details:any|undefined;
  policy_id:any|undefined;
  flag:boolean=false
  constructor(
    private dialogRef: MatDialogRef<ChoosePolicyComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData | any,
    private router:Router,
    private sharedDataService:SharedDataService,
    private apiService:ApiService
) {
 
 }

closeDialog() {
    this.dialogRef.close({ event: 'close' });
}

ngOnInit(): void {
  this.policy_details=this.data;
  
}
radioChange($event:any){
  this.policy_id=$event.value;
  this.sharedDataService.getPolicyID(this.policy_id)
}
proposer(){
  let url='/api/v2/plan/plan_details/?policy_no='+this.policy_id;
  this.apiService.getRequestedResponse(url).subscribe(res=>{
    localStorage.setItem("proposerFormData",JSON.stringify(res));
    localStorage.setItem("listhide","false");
    this.flag=true;
    this.dialogRef.close();
  })
}
reloadPage() {
  const urlOBtained = this.router['url'];
  this.router.navigate([urlOBtained]).then(() => {
      window.location.reload();
  });
}
ngOnDestroy(): void {
  if(this.flag==false){
    this.reloadPage()
  }
}
}
