import { Component, Input, Inject, Optional, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogData } from "src/app/model/data.model";

@Component({
  selector: 'app-plan-not-available',
  templateUrl: './plan-not-available.component.html',
  styleUrls: ['./plan-not-available.component.scss'],
})
export class PlanNotAvailableComponent implements OnInit {
  message : string = 'It appears that plans are not available for the selected combination. Please review and edit your details accordingly.'

  constructor(
    private dialogRef: MatDialogRef<PlanNotAvailableComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData | any,
  ) {
    dialogRef.disableClose = true;
  }

  

  ngOnInit(): void {}

  closeDialog() {
    this.dialogRef.close({ event: 'close' });
  }

}
