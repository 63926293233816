import { Directive, ElementRef, HostListener, Renderer2, } from '@angular/core';

@Directive({
  selector: '[adharFormate]'
})
export class AdharFormateDirective {
  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef
) { }
  @HostListener('input', ['$event'])
  onKeyUp(e: KeyboardEvent) {
    let value = this.elementRef.nativeElement.value;

    if (value && (value.length === 4 || value.length === 9)) {
        this.renderer.setProperty(this.elementRef.nativeElement, 'value', value + ' ');
    }
}

}
