import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { CommonModalService } from "../../services/common-modal.service";
import { DialogData } from "../../model/data.model";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ApiService } from "src/app/services/api.service";
import { ApiConstants } from "src/app/api.constant";
import { map, Observable, startWith } from "rxjs";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { environment } from "src/environments/environment";
declare const webengage: any;

@Component({
  selector: "app-network-hospitals",
  templateUrl: "./network-hospitals.component.html",
  styleUrls: ["./network-hospitals.component.scss"],
})
export class NetworkHospitalsComponent implements OnInit {
  seachHospitalForm!: FormGroup;
  networkcity: any;
  networkhospital: any;
  hospitaldetails: {
    modalType: number;
    widthObtained: string;
    heightObtained: string;
    topObtained: string;
    classObtained: string;
  } = {
    modalType: 32,
    widthObtained: "50%",
    heightObtained: "auto",
    topObtained: "auto",
    classObtained: "hospitaldetails",
  };
  filteredCityStateList: Observable<any> | any;
  cityStateList: any;
  citylist: any;
  selectedCity: any = "PAN India";
  hospitalList: any;
  pageSize = 10;
  currentPage = 0;
  paginatedCities: string[] = [];
  detail: any;
  state: any;
  provider: any = "";
  hospitalName: string = "";
  cashlessHospitalCount: number = 0;
  totalPageHospitals: number = 0;
  page: any;
  city: any = "";
  insurer: any = [];
  isLocationTracked : boolean = false;
  isSearchTracked : boolean  = false;
  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;

  constructor(
    private fb: FormBuilder,
    private commonModal: CommonModalService,
    private apiService: ApiService
  ) {
    this.getHospitalProviderList();
    this.seachHospitalForm = this.fb.group({
      selectedInsurer: ["All"],
      networkcity: [""],
      networkhospitals: [""],
    });
    this.detail = "";
    this.city = "";
    this.state = "";
    this.page = 1;
    this.getHospitalLists(
      this.city,
      this.state,
      this.provider,
      this.page,
      this.hospitalName
    );
  }

  /**
   * This function retrieves the list of hospital providers from the API.
   * It subscribes to the API response and assigns the received list to the `insurer` property.
   *
   * @remarks
   * The function uses the `ApiService` to make a GET request to the `HOSPITAL_PROVIDER_LISTING` API endpoint.
   * The received list is then assigned to the `insurer` property.
   *
   * @returns {void}
   */

  getHospitalProviderList() {
    this.apiService
      .getRequestedResponse(`${ApiConstants.HOSPITAL_PROVIDER_LISTING}`)
      .subscribe((list) => {
        this.insurer = list.map(provider => provider.name === 'Eidelweiss' ? { name: 'Zuno', updated_name: provider.updated_name } : provider);
      });
  }

  ngOnInit(): void {
    this.getHospitalCityState();
  }

  hospitalNameModal(hospitalListData) {
    const userType =  sessionStorage.getItem('journer_name ') || {}
    /**web engage start**/
        if (environment?.dev && userType === 'Customer') {
             webengage.track(  'RB_Health_Hospital_Share_Clicked' , {
              User_Type: 'Customer', 
               });
             }
     /**web engage end**/
    let resWidth;
    let resTop;
    if (window.screen.width <= 767) {
      resWidth = "90%";
      resTop = "5%";
    } else {
      resWidth = this.hospitaldetails["widthObtained"];
      resTop = "5%";
    }

    const obj: DialogData = {
      type: this.hospitaldetails["modalType"],
      width: this.hospitaldetails["widthObtained"],
      height: this.hospitaldetails["heightObtained"],
      classNameObtained: this.hospitaldetails["classObtained"],
      minWidth: resWidth,

      dataInfo: {
        top: resTop,
        hospitalListData: hospitalListData,
      },
    };
    this.commonModal.openDialog(obj).subscribe((data: any) => {});
  }

  cityStateDisplay(cityState?: any): any | undefined {
    this.filteredCityStateList = cityState;
    return cityState;
  }
  /**
   * This function handles the city selection event.
   * It logs the event object, the selected pincode object from the form control, and a custom message.
   *
   * @param event - The event object that triggered the function.
   * @returns {void}
   */
  onCitySelection(event: any) {
    if (event.source.filteredCityStateList) {
      this.selectedCity = event.source.filteredCityStateList.split(",")[0];
      this.city = event.source.filteredCityStateList.split(",")[0];
      this.state = event.source.filteredCityStateList.split(",")[1];
      (this.detail = event.source.filteredCityStateList.split(",")[0]),
        (this.state = event.source.filteredCityStateList.split(",")[1]),
        this.getHospitalLists(
          event.source.filteredCityStateList.split(",")[0],
          event.source.filteredCityStateList.split(",")[1],
          this.provider,
          this.page,
          this.hospitalName
        );
    }
  }
  /**
   * This function initializes the filteredCityStateList observable with the valueChanges of the networkcity form control.
   * It uses RxJS operators to start with an empty string, then map the valueChanges to the filterCityState function.
   * This allows for real-time filtering of the cityStateList based on the user's input.
   */
  getHospitalCityState() {
    this.filteredCityStateList = this.seachHospitalForm.controls[
      "networkcity"
    ].valueChanges.pipe(
      startWith(""),
      map((value: any) => this.filterCityState(value))
    );
  }

  /**
   * get hospital city and state  on api call
   */
  filterCityState(valueObtained: any) {
    if (valueObtained && valueObtained.length >= 3) {
      this.apiService
        .getRequestedResponse(
          `${ApiConstants.HOSPITAL_CITY_STATE}?detail=${valueObtained}`
        )
        .subscribe((response: any) => {
          if (response) {
            this.cityStateList = response;

            let results = this.cityStateList.map((group: any) => ({
              name: group.city + "," + group.state,
            }));

            const isValueAvail = results.length === 0;
            if (isValueAvail) {
              results = [{ name: "No data found" }];
              this.cityStateList = [{ name: "No data found" }];
            }
            return results;
          }
        });

      return this.cityStateList;
    }
  }

  /**
   * This function is used to fetch the list of hospitals based on the provided parameters.
   *
   * @param {string} city - The city for which the hospital list needs to be fetched.
   * @param {string} state - The state for which the hospital list needs to be fetched.
   * @param {string} provider - The insurer name for which the hospital list needs to be fetched.
   * @param {number} page - The page number for pagination.
   * @param {string} hospitalName - The name of the hospital to be searched.
   *
   * @returns {void}
   */
  getHospitalLists(city, state, provider, page, hospitalName) {
    this.apiService
      .getRequestedResponse(
        `${ApiConstants.HOSPITAL_LIST}?city=${city}&state=${state}&provider=${provider}&hospital_name=${hospitalName}&page=${page}`
      )
      .subscribe((list) => {
        this.cashlessHospitalCount = list?.count;
        this.hospitalList = list?.results;
        this.totalPageHospitals = list?.total_pages;

        /**web engage start**/
        const userType = sessionStorage.getItem("journer_name ");
        if (
          environment?.dev &&
          userType === "Customer" &&
          city &&
          state &&
          this.isLocationTracked === false
        ) {
          webengage.track("RB_Health_Hospital_Location_Viewed", {
            User_Type: "Customer",
            Location: city + "," + state,
          });
          this.isLocationTracked = true;
        }

        const formattedInputName = hospitalName.trim().toLowerCase();

        // Check if at least one hospital name includes the input name
        const isHospitalFound = this.hospitalList.some(
          (hospital) =>
            hospital.hospital_name.trim().toLowerCase().includes(formattedInputName)
        );
        
        if (isHospitalFound && environment?.dev && userType === "Customer" && hospitalName) {
          webengage.track("RB_Health_Hospital_Searched", {
            User_Type: "Customer",
            Keyword_Searched: hospitalName,
          });
          this.isSearchTracked = true;
        }
        
      });
    /**web engage end**/
  }
  /**
   * This function is used to fetch the list of hospitals based on the provided parameters.
   *
   * @param {string} city - The city for which the hospital list needs to be fetched.
   * @param {string} state - The state for which the hospital list needs to be fetched.
   * @param {string} provider - The insurer name for which the hospital list needs to be fetched.
   * @param {number} page - The page number for pagination.
   * @param {string} hospitalName - The name of the hospital to be searched.
   *
   * @returns {void}
   */
  onPageChange(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    this.getHospitalLists(
      this.city,
      this.state,
      this.provider,
      this.page,
      this.hospitalName
    );
    this.currentPage = event.pageIndex;
  }
  /**
   * This function is used to filter hospitals based on the selected insurer.
   *
   * @param {string} insurerName - The name of the insurer selected by the user.
   *
   * @returns {void}
   */
  getInsurerByName(insurerName) {
    if (insurerName != "All") {
      const userType =  sessionStorage.getItem('journer_name ') || {}
      // /**web engage start**/
          if (environment?.dev && userType === 'Customer') {
               webengage.track(  'RB_Health_Insurer_Selected' , {
                User_Type: 'Customer', 
                Option_Selected : insurerName
                 });
              }
       /**web engage end**/

      this.provider = insurerName;
      this.getHospitalLists(
        this.city,
        this.state,
        insurerName,
        this.page,
        this.hospitalName
      );
    } else {
      this.provider = "";
      this.getHospitalLists(
        this.city,
        this.state,
        this.provider,
        this.page,
        this.hospitalName
      );
    }
  }
  /**
   * This function is used to search for hospitals based on the entered name.
   * It triggers a search only when the entered name is more than 3 characters long.
   *
   * @param {string} event - The entered hospital name.
   *
   * @returns {void}
   */
  searchByHospitalsName(event) {
    if (event && event.length > 3 && event != "") {
      this.hospitalName = event;
      this.getHospitalLists(
        this.city,
        this.state,
        this.provider,
        this.page,
        this.hospitalName
      );
    } else if (event == "") {
      this.isSearchTracked = false
      this.hospitalName = event;
      this.getHospitalLists(
        this.city,
        this.state,
        this.provider,
        this.page,
        this.hospitalName
      );
    }
  }
  /**
   * This function sorts the hospital list alphabetically by hospital name.
   *
   * @returns {void}
   *
   * @remarks
   * The hospital list is sorted in ascending order based on the hospital name.
   * If two hospitals have the same name, their relative order is preserved.
   *
   * @example
   * ```typescript
   * // Assuming hospitalList is an array of hospital objects with a property 'hospital_name'
   * sortCities();
   * console.log(hospitalList); // Output: Array of hospital objects sorted by hospital name
   * ```
   */
  sortCities() {
    this.hospitalList.sort((a, b) => {
      if (a.hospital_name > b.hospital_name) {
        return 1;
      } else if (a.hospital_name < b.hospital_name) {
        return -1;
      } else {
        return 0;
      }
    });
  }
  /**
   * This function is used to download the hospital list based on the provided parameters.
   * It opens a new browser tab with the URL constructed using the environment URL, API constant,
   * and the current component's properties (city, state, provider, and hospitalName).
   *
   * @returns {void}
   *
   * @remarks
   * The function uses the `window.location.href` property to open a new browser tab.
   * The URL is constructed using the `environment?.finsureUrl`, `ApiConstants.DOWNLOAD_HOSPITAL_LIST`,
   * and the current component's properties (city, state, provider, and hospitalName).
   *
   */
  downloadHospitals() {
   
   const userType =  sessionStorage.getItem('journer_name ') || {}
           /**web engage start**/
               if (environment?.dev && userType === 'Customer') {
                    webengage.track(  'RB_Health_Hospital_List_downloaded' , {
                     User_Type: 'Customer', 
                      });
                    }
            /**web engage end**/
    window.location.href = `${environment?.finsureUrl}${ApiConstants.DOWNLOAD_HOSPITAL_LIST}?city=${this.city}&state=${this.state}&provider=${this.provider}&hospital_name=${this.hospitalName}`;
  }
  /**
   * This function is used to clear all the filters and reset the component's properties.
   * It resets the form controls, sets the selected insurer to 'All', resets the selected city to 'PAN India',
   * clears the provider, detail, and hospitalName properties, and calls the getHospitalLists function with
   * the reset parameters.
   *
   * @returns {void}
   */
  clearAll() {
    this.seachHospitalForm.get("networkcity")?.reset();
    this.seachHospitalForm.get("networkhospitals")?.reset();
    this.seachHospitalForm.patchValue({
      selectedInsurer: "All",
    });
    this.selectedCity = "PAN India";
    this.provider = "";
    this.detail = "";
    this.hospitalName = "";
    (this.city = ""),
      (this.state = ""),
      this.getHospitalLists(
        this.city,
        this.state,
        this.provider,
        this.page,
        this.hospitalName
      );
  }
  /**
   * This function handles the reset of city and state selection.
   * It checks if the event is an empty string, and if so, it resets the selected city to 'PAN India',
   * clears the form control for network hospitals, resets the city, state, and hospitalName properties,
   * and calls the getHospitalLists function with the reset parameters.
   *
   * @param {string} event - The event object that triggered the function.
   *
   * @returns {void}
   */
  getCityStateData(event) {
    if (event == "") {
      this.isLocationTracked = false;
      this.selectedCity = "PAN India";
      this.seachHospitalForm.get("networkhospitals")?.reset(),
        (this.city = ""),
        (this.state = ""),
        (this.hospitalName = "");
      this.getHospitalLists(
        this.city,
        this.state,
        this.provider,
        this.page,
        this.hospitalName
      );
    }
  }
}
