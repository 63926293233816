import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
// import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { valueOrDefault } from 'chart.js/dist/helpers/helpers.core';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatRadioButton } from '@angular/material/radio';
import { error } from 'console';

@Component({
  selector: 'app-pay',
  templateUrl: './pay.component.html',
  styleUrls: ['./pay.component.scss']
})
export class PayComponent implements OnInit {

  adminForm!: FormGroup;
  usersadmin : any[]=[];
  adminobj:any={
    emp_code:'',
    name:'',
    email:'',
    mobile_no:''
  };
  emp_code:any;
  name: any;
  emailFormControl: any;
  mobile_no:any;
  errormeassge: any;
  isLang:any=(localStorage.getItem('language')=='Hindi')?'hi':'en'
  existingPartners: any = 'no';
  roleData: any;
  permissionsData: any;

  selectedValue: any;
  employeeCodeInput: boolean=false;
  PaymentUrl: any;
  EmployeeDetails: any;
  timeout: any = null;
  amount: any=2000;
  email: any;
  mobile: any;
  constructor(
    private formBuild:FormBuilder,
    private apiService: ApiService,
    // private dialogRef: MatDialogRef<CreateUserComponent>,
    public translate: TranslateService,
    private _snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private route:Router) 
    {
      
      this.translate.use(this.isLang);  
      this.adminForm = this.formBuild.group({
        employee_code:['',Validators.required],
        name:[this.EmployeeDetails?.full_name,[Validators.required, Validators.minLength(3), Validators.pattern(new RegExp('^[a-zA-Z][a-zA-Z ]*$'))]],
        email:[this.EmployeeDetails?.email, [Validators.required, Validators.pattern(new RegExp(
          '^[_a-zA-Z0-9]+(.[_a-zA-Z0-9  ]+)@[a-zA-Z0-9-  ]+(.[a-zA-Z0-9-  ]+)(.[a-zA-Z  ]{2,4})$'))]],
        mobile_no:[this.EmployeeDetails?.mobile, [Validators.required, Validators.pattern(new RegExp('^[6-9]{1}[0-9]{9}$'))]],
      })
      this.activatedRoute.queryParams.subscribe(params => {
        this.name = params['name'];
        this.email=params['email']
        this.mobile=params['mobile']
        this.adminForm?.patchValue({
          name:this.name,
          email:this.email,
          mobile_no:this.mobile
        })
         
    });
    }
    message:boolean= false;
  ngOnInit(): void {



// this.roles();
//     const localData = localStorage.getItem('useradmin');
//     if(localData != null) {
//       this.useradmin = JSON.parse(localData);
//     }
  }
  get m(){
    return this.adminForm.controls;
  }


/**
 * Api integration for Creating user for Dashboard
 **/
@ViewChild('yesRadio') yesRadio!: MatRadioButton;
  @ViewChild('noRadio') noRadio!: MatRadioButton;

onRadioChange(event: any) {
  const selectedValue = event.source.value;
  // const selectedLabel = event.source === this.yesRadio ? 'Yes' : 'No';
  if(selectedValue=='yes'){
    this.employeeCodeInput=true
    this.amount=1000
  }else if(selectedValue=='no'){
    this.employeeCodeInput=false
    this.amount=2000
  }
}
  useradmin(isValid:boolean){
    if(!this.errormeassge){
      this.adminobj={
        name:this.adminForm.get('name')?.value,
        email:this.adminForm.get('email')?.value,
        mobile:this.adminForm.get('mobile_no')?.value,
        is_our_agent:this.employeeCodeInput,
        agent_code:this.adminForm.get('employee_code')?.value,
      };
      let url='/api/v1/retirement_expert/get_payment_url/'
      this.apiService.postRequestedResponsewithoutpopup(url,this.adminobj,false,true,false,true).subscribe((res)=>{
        this.message=true;
  
        this.PaymentUrl=res['pay_url']
        window.location.href=this.PaymentUrl
  
      })
    }
    
  }
  invested(event: any) {
    if(event.length>=1){
      clearTimeout(this.timeout);
      var $this = this;
      this.timeout = setTimeout(function () {
        if (event.keyCode != 13) {
          // $this.investedAmount = Number(event)
          $this.employeeDetails(event);
        }
      }, 1000);
    }
      
    }
   
  
  employeeDetails(id){
    this.adminobj={
      user_id:id
    };
    let url='/api/v1/retirement_expert/get_agent_profile/'
    this.apiService.postRequestedResponsewithoutpopup(url,this.adminobj,false,true,false,true).subscribe((res)=>{
      this.EmployeeDetails=res
      this.adminForm.get('name')?.setValue(this.EmployeeDetails?.full_name)
      this.adminForm.get('name')?.updateValueAndValidity()
      this.adminForm.get('email')?.setValue(this.EmployeeDetails?.email)
      this.adminForm.get('email')?.updateValueAndValidity()
      this.adminForm.get('mobile_no')?.setValue(this.EmployeeDetails?.mobile)
      this.adminForm.get('mobile_no')?.updateValueAndValidity()
      this.adminForm.get('mobile_no')
      this.errormeassge=""
    },(error)=>{
      this.errormeassge=error; 
    })
  }

  close(): void {
    // this.dialogRef.close();
  }
  roles(){
    // this.roleSelect();
    let url='/api/v1/accounts/role/?permissions__project_name=';
    this.apiService.getRequestedResponse(url,'renewbuy-backend-header',true,false,true).subscribe((res)=>{
      this.roleData=res;
      
      this.permissionsData=res[0]['permissions']
      // this.QuickIcons=res[this.roleId]['permissions']['Quick Icons']
      // this.Accordians=res[this.roleId]['permissions']['Accordians']
      // this.settings=res[this.roleId]['permissions']['Settings']
     
      
    })
  }


}
