import { Component, Inject, OnInit, Optional } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogData } from "src/app/model/data.model";
import { SharedDataService } from "src/app/services/shared-data.service";
import { ApiConstants } from "src/app/api.constant";
import { ApiService } from "src/app/services/api.service";

@Component({
  selector: 'app-magma-maternity-pop',
  templateUrl: './magma-maternity-pop.component.html',
  styleUrls: ['./magma-maternity-pop.component.scss']
})
export class MagmaMaternityPopComponent implements OnInit {
  premium: any;
  constructor(
    private dialogRef: MatDialogRef<MagmaMaternityPopComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData | any,
    private apiService: ApiService,
    private sharedDataService: SharedDataService
  ) {
    
    dialogRef.disableClose = true;
    this.premium = data?.premium
    // this.premium = sessionStorage.getItem('maternityPremium');
    
  }

  ngOnInit(): void {}

  
/** Closes the dialog and sends a close event with a predefined payload. **/
closeDialog() {
  this.dialogRef.close({ event: "close" , check_journey : this.data?.journey });
}


optOut() {
  // Handle opt-out logic here
  sessionStorage.removeItem('maternityAddons');
  this.dialogRef.close({ event: "close" ,remove_maternity : "remove_maternity" });
}

keepMaternityCover() {
  // Handle keeping Maternity Cover logic here
  this.dialogRef.close({ event: "close"  , maternity : "maternity"});  
}

addMaternity() {
  this.apiService
  .getRequestedResponse(ApiConstants.MAGMA_ADD_ONS)
  .subscribe((res: any) => {
    // Filter to find the addon with the name "Maternity-Magma"
    const maternityAddon = res.find( (addon: any) => addon.name === "Maternity-Magma" );

    // Check if the addon is found and log its ID
    if (maternityAddon) {
      // Retrieve existing addons from sessionStorage or initialize an empty array
      const existingAddons = JSON.parse( sessionStorage.getItem("maternityAddons") || "[]" );

      // Add the new addon to the array
      existingAddons.push(maternityAddon.id);

      // Store the updated array back into sessionStorage
      sessionStorage.setItem( "maternityAddons", JSON.stringify(existingAddons) );

      // Close the dialog with the necessary data
      this.dialogRef.close({ event: "close", data: { addOnsId: maternityAddon.id }, });
    }
  });
}

continueWithoutTopUp() {
  this.dialogRef.close({ addMaternity :  false });
}

}
