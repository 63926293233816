<header class="fixed-top mainHeader " >
	<!-- <div class="container"> -->
		<nav class="navbar navbar-expand-lg navbar-light">
			<div class="">
				<a class="navbar-brand" href="#">
					<img class="img-fluid"   src="assets/RB-logo/rb-logo.png">
				</a>
				<!-- <button class="navbar-toggler" type="button" (click)="navbarCollapsed = !navbarCollapsed">
					<span class="navbar-toggler-icon"></span>
				</button> -->
				<div class="collapse navbar-collapse justify-content-end" [class.collapse]="navbarCollapsed">
					<ul class="navbar-nav">
						<li class="nav-item" *ngFor="let items of headerList">
							<a class="nav-link" href="{{items['link']}}">
								<img class="img-fluid" src="{{items['icon']}}">
								<div>{{items['label']}}</div>
							</a>
						</li>
						<!-- <li class="nav-item">
							<span #widgetId></span>
                        </li> -->
					</ul>
				</div>
             <div>
                   <ul class="navbar-nav">
                    <ng-container *ngIf="getUserProfileData">
                        <li class="nav-item" *ngFor="let items of headerList">
                            <a class="nav-link" href="{{items['link']}}">
                                <img class="img-fluid product-icon" src="{{items['icon']}}">
                                <div>{{items['label']}}</div>
                            </a>
                        </li>
                    </ng-container>

                    <!-- login section -->

                    <!-- login -->
                    <!-- <li class="nav-item login-menu">
                        <span class="nav-link cursor-pointer" (click)="redirectToLoginPage()">
                            <span>Login</span>
                            <img class="img-fluid ps-2" src="assets/images/user-profile.svg">
                        </span>
                    </li> -->

                    <!-- logout -->
                    <!-- <li class="nav-item dropdown" *ngIf="getUserProfileData">
                        <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown">
                            <span>{{userProfileIcon}}</span>
                        </a>
                        <ul class="dropdown-menu drop_menu">
                            <li>
                                <div class="dropdown-item" (click)="logout()">Logout</div>
                            </li>
                        </ul>
                    </li> -->
                    <!-- login section -->
                </ul>
            </div>

        </div>
    </nav>
</header>
