import { Injectable } from "@angular/core";
import { Subject, BehaviorSubject } from "rxjs";
import { ApiService } from "../services/api.service";
import { environment } from "../../environments/environment";
import { DialogData } from "../model/data.model";
import { CommonModalService } from "./common-modal.service";
import { ApiConstants } from "../api.constant";
import { LoaderService } from "./loader.service";
@Injectable({
  providedIn: "root",
})
export class SharedDataService {
  userExecutiveCode = new BehaviorSubject<string>("");
  getPlanDetails = new BehaviorSubject<string>("");
  getOrderApiRes: Subject<any> = new Subject();
  getOrderApiRes2: Subject<any> = new Subject();
  getleadsId: Subject<any> = new BehaviorSubject<string>("");
  getOrderApiLifeRes: Subject<any> = new Subject();
  getOrderApiRSARes: Subject<any> = new Subject();
  retrievePlan: Subject<any> = new Subject();
  retrievePlanForlife: Subject<any> = new Subject();
  getPolicy: Subject<any> = new BehaviorSubject<string>("");
  getdobForlife: Subject<any> = new BehaviorSubject<string>("");
  setrsadata = new BehaviorSubject<string>("");
  dataDecryptFromApiRes: Subject<any> = new Subject();
  getPincode = new BehaviorSubject<string>("");
  updatePlanId = new BehaviorSubject<string>("");
  loginComponentLoadingData: any;
  topFeaturesPlan: Subject<any> = new Subject();
  getMagmaPremium: Subject<any> = new Subject();
  hospitalModalPopup: {
    modalType: number;
    widthObtained: string;
    heightObtained: string;
    topObtained: string;
    classObtained: string;
  } = {
    modalType: 23,
    widthObtained: "80%",
    heightObtained: "65%",
    topObtained: "auto",
    classObtained: "hospitalNetworkListPopup",
  };
  checkPincodeModalPopup: {
    modalType: number;
    widthObtained: string;
    heightObtained: string;
    topObtained: string;
    classObtained: string;
  } = {
    modalType: 24,
    widthObtained: "78%",
    heightObtained: "65%",
    topObtained: "auto",
    classObtained: "check-pincode-modal",
  };
  private data = {};

  private aadhaarDataSubject = new BehaviorSubject<any>(null);
  aadhaarData$ = this.aadhaarDataSubject.asObservable();

  constructor(
    private apiService: ApiService,
    private commonModal: CommonModalService,
    private loaderService: LoaderService
  ) {}

  loadAadhaarData(): void {
    const storedData = sessionStorage.getItem("storeAadhaarData");
    if (storedData) {
      this.aadhaarDataSubject.next(JSON.parse(storedData));
    }
  }

  saveAadhaarData(data: any): void {
    sessionStorage.setItem("storeAadhaarData", JSON.stringify(data));
    this.aadhaarDataSubject.next(data);
  }

  clearAadhaarData(): void {
    sessionStorage.removeItem("storeAadhaarData");
    this.aadhaarDataSubject.next(null);
  }

  getUserExecutiveCode(data: any) {
    this.userExecutiveCode.next(data);
  }

  getPlanData(data: any) {
    this.getPlanDetails.next(data);
  }

  setData(element: any) {
    this.data = element;
  }
  getData() {
    return this.data;
  }

  getOrderApi(orderId: string) {
    const url = "/api/v1/order/get_order/?id=" + orderId;
    this.apiService.getRequestedResponse(url).subscribe((res: any) => {
      if (res) {
        this.getOrderApiRes.next(res);
      }
    });
  }
  getOrderApi2(orderId: string) {
    const url = "/api/v3/health/order2/" + orderId;
    this.apiService.getRequestedResponse(url).subscribe((res: any) => {
      if (res) {
        this.getOrderApiRes2.next(res);
      }
    });
  }
  getOrderApiForLife(orderId: string) {
    const url = "/api/v2/rblifeorder/get_order_details/?id=" + orderId;
    this.apiService.getRequestedResponse(url).subscribe((res: any) => {
      if (res) {
        this.getOrderApiLifeRes.next(res);
      }
    });
  }
  getOrderApiForRSA(orderId: string) {
    const url = "/api/rsa/v1/order/" + orderId + "/";
    this.apiService
      .getRequestedResponse(url, "renewbuy-backend-header", false, true, false)
      .subscribe((res: any) => {
        if (res) {
          this.getOrderApiRSARes.next(res);
        }
      });
  }
  getPolicyID(policy: any) {
    this.getPolicy.next(policy);
    this.loginComponentLoadingData = true;
  }
  getLoginScreen(data: any) {
    this.loginComponentLoadingData = data;
  }
  getLeadsIdData(data: any) {
    this.getleadsId.next(data);
  }

  getRetrievePlan(plan_id: string) {
    // Construct the base URL with the plan ID
    let url = `/api/v2/plan/retrieve_plan/?${plan_id}`;

    let maternityAddons = sessionStorage.getItem("maternityAddons");
    let addOnsValue;
    if (maternityAddons) {
      let addOns = JSON.parse(maternityAddons); // `addOns` is now an array
      addOnsValue = addOns[0]?.toString();
      url += `&add_on_type=${addOns}`;
    }

    // Retrieve `tenXTopup` from sessionStorage and add as query parameter
    const tenXTopup = sessionStorage.getItem("tenXTopup");
    if (tenXTopup) {
      url += `&is_top_up=${tenXTopup}`;
    }

    // Call the API service
    this.apiService.getRequestedResponse(url).subscribe((res: any) => {
      if (res) {
        this.retrievePlan?.next(res);
      }
    });
  }

  // getRetrievePlanForLife(plan_id) {
  //   const url = "/api/v2/rblifeplan/plan_card/?id=" + plan_id;
  //   this.apiService.getRequestedResponse(url).subscribe((res: any) => {
  //     if (res) {
  //       this.retrievePlanForlife?.next(res);
  //     }
  //   });
  // }
  getDOB(dob) {
    this.getdobForlife.next(dob);
  }
  pageVisit(body) {
    let url = "/api/v2/page_visit/";
    this.apiService.postRequestedResponse(url, body).subscribe((res) => {});
  }
  getRsadata(data) {
    this.setrsadata.next(data);
  }
  dataDecryptFromApi(encrypted) {
    let url = `/api/v2/token/decrypt?encrypted=${encrypted}`;
    this.apiService.getRequestedResponse(url).subscribe((res: any) => {
      this.dataDecryptFromApiRes.next(res);
    });
  }

  /**
   * proposer page and declaration page data downloads as pdf
   */
  downLoadProposerPage(
    orderId,
    check_Upgrade_Renewals_Journey,
    proceedWithEazyPay
  ) {
    if (orderId) {
      // Add the query parameter when the conditions are met
      let url = `${environment["finsureUrl"]}api/v2/customer_docs/dynamic_proposer_form/?order=${orderId}`;
      if (check_Upgrade_Renewals_Journey === "Renewals" && proceedWithEazyPay) {
        url += "&bima_renew=true";
      }
      window.open(url, "_blank");
    }
  }
  /**
   * get encrypted data
   */

  encryptedResponse(encrypted_data) {
    if (encrypted_data) {
      return this.apiService.getRequestedResponse(
        `/api/v2/token/decrypt?encrypted=${encrypted_data}`
      );
    }
    return null;
  }

  openHospitalNetwork() {
    let resWidth;
    let resTop;
    if (window.screen.width <= 767) {
      resWidth = "95%";
      resTop = "5%";
    } else {
      resWidth = this.hospitalModalPopup["widthObtained"];
      resTop = "5%";
    }

    const obj: DialogData = {
      type: this.hospitalModalPopup["modalType"],
      width: this.hospitalModalPopup["widthObtained"],
      height: this.hospitalModalPopup["heightObtained"],
      classNameObtained: this.hospitalModalPopup["classObtained"],
      minWidth: resWidth,

      dataInfo: {
        data: "",
        top: resTop,
      },
    };

    this.commonModal.openDialog(obj);
  }
  openCheckPincodeModal() {
    let resWidth;
    let resTop;
    if (window.screen.width <= 767) {
      resWidth = "95%";
      resTop = "5%";
    } else {
      resWidth = this.checkPincodeModalPopup["widthObtained"];
      resTop = "5%";
    }

    const obj: DialogData = {
      type: this.checkPincodeModalPopup["modalType"],
      width: this.checkPincodeModalPopup["widthObtained"],
      height: this.checkPincodeModalPopup["heightObtained"],
      classNameObtained: this.checkPincodeModalPopup["classObtained"],
      minWidth: resWidth,

      dataInfo: {
        data: "",
        top: resTop,
      },
    };

    this.commonModal.openDialog(obj).subscribe((res) => {
      this.getPincode.next(res);
    });
  }
  /**
   * This function is used to validate a pincode.
   * It sends a GET request to the API endpoint with the pincode as a query parameter.
   * The API returns a response indicating whether the pincode is valid.
   *
   * @param {string} pincode - The pincode to be validated.
   *
   * @returns {Observable<any>} - An Observable that emits the API response.
   *
   * @throws Will throw an error if the API request fails.
   *
   * @remarks
   * This function is used to check the validity of a pincode before allowing the user to proceed with the order process.
   *
   */
  getValidPincode(pincode) {
    let url = `/api/v1/order/check_pincode/?pincode=${pincode}`;
    return this.apiService.getRequestedResponse(url);
  }
  /**
   * This function is used to share top features plans data across the application.
   * It sends the provided data to all subscribers of the `topFeaturesPlan` Subject.
   *
   * @param {any} data - The data to be shared across the application.
   *
   * @returns {void} - This function does not return any value.
   *
   */
  getTopFeaturesPlans(data: any) {
    this.topFeaturesPlan.next(data);
  }

  /**Get magma premium**/
  getMagmaPremiumPlans(data: any) {
    this.getMagmaPremium.next(data);
  }

  /**
   * This function is used to validate a pincode against a specific plan.
   * It sends a GET request to the API endpoint with the pincode and planId as query parameters.
   * The API returns a response indicating whether the pincode is valid for the given plan.
   *
   * @param {string} pincode - The pincode to be validated.
   * @param {string} planId - The ID of the plan for which the pincode is being validated.
   *
   * @returns {Observable<any>} - An Observable that emits the API response.
   *
   */
  getValidPincodes(pincode, planId) {
    let url = `/api/v1/order/check_pincode/?pincode=${pincode}&plan_code=${planId}`;
    return this.apiService.getRequestedResponse(url);
  }
  getUpdatePlanId(newPlanId) {
    this.updatePlanId.next(newPlanId);
  }
  /**
   * This function updates the encrypted token in the session storage.
   * It sends a GET request to the API endpoint to retrieve a new encrypted token based on the provided ORDER_ID.
   * The retrieved token is then stored in the session storage.
   *
   * @param {string} ORDER_ID - The unique identifier of the order for which the encrypted token needs to be updated.
   *
   * @returns {void} - This function does not return any value.
   *
   * @remarks
   * This function is used to ensure that the encrypted token used for authentication and authorization is always up-to-date.
   * It is called whenever a new order is created or when the existing token becomes invalid.
   *
   */
  update_encrypted_token(ORDER_ID) {
    sessionStorage.removeItem("encrypted_token");
    this.apiService
      .getRequestedResponse(
        `${ApiConstants.SHARE_NEW_TOKEN}?order_id=${ORDER_ID}`
      )
      .subscribe((res: any) => {
        if (res) {
          this.loaderService.hide();
          sessionStorage.setItem("encrypted_token", res["token"]);
        }
      });
  }

  // Store payment data in local storage
  storePaymentData(data: any): void {
    localStorage.setItem("paymentData", JSON.stringify(data));
  }

  // Retrieve payment data from local storage
  getPaymentData(): any {
    const data = localStorage.getItem("paymentData");
    return data ? JSON.parse(data) : null;
  }

  // Clear payment data
  clearPaymentData(): void {
    localStorage.removeItem("paymentData");
  }


    // Store payment data in local storage
    storeOrderDataForUpgrade(data: any): void {
      sessionStorage.setItem("orderData", JSON.stringify(data));
    }
  
    // Retrieve payment data from local storage
    getOrderDataForUpgrade(): any {
      const data = sessionStorage.getItem("orderData");
      return data ? JSON.parse(data) : null;
    }
  
    // Clear payment data
    clearOrderDataForUpgrade(): void {
      sessionStorage.removeItem("orderData");
    }

}
