<div id="plan-card">
  <div class="card-flex">
    <div class="partner-logo flex-1">
      <div>
        <img src="{{ cardata?.provider_plans[1]?.logo }}" class="company-img" />
      </div>
      <div class="plus" *ngIf=" !cardata?.name || cardata?.name.indexOf('RenewBuy Health Wellness') !== 0 ">
        <img src="../../../assets/images/health-v2/icons//plus.svg" />
      </div>
      <div class="plus" *ngIf=" cardata?.name || cardata?.name.indexOf('RenewBuy Health Wellness') !== 0 "></div>
      <div>
        <img src="{{ cardata?.provider_plans[0]?.logo }}" class="company-img" [ngClass]="{'health-assure-logo': cardata?.provider_name === 'Health Assure'}" />
      </div>
    </div>
    <div class="plan-detail flex-2">
      <!-- <div class="mobile-only"> <img src="{{ cardata?.provider_plans[0]?.logo }}" class="company-img" width="84px" /> <div class="plus">&#43;</div> <img src="{{ cardata?.provider_plans[1]?.logo }}" class="company-img" width="84px" /> </div> -->
      <div class="plan-name">{{ cardata?.name }}</div>
      <div class="plan-dtl-flex">
        <div class="" *ngIf="cardata?.sum_insured != 0">
          <div class="plan-text">Sum Insured :</div>
          <div class="plan-period">₹ {{ cardata?.sum_insured }}</div>
        </div>
        <div class="">
          <div class="plan-text">Family Size :</div>

          <div class="plan-period">
            {{ cardata?.max_adults }}Adults<span *ngIf="cardata?.max_child !== 0">
              , {{ cardata?.max_child }}Child</span>
          </div>
        </div>

      </div>
    </div>

    <div [ngClass]="cardata?.installment_amount ? 'payable-amount-bimapay' : 'flex-3' ">
      <div class="web-only">
        <div class="left-border">
          <div class="premium-text">Payable Amount</div>
          <div class="premium-amount">
            ₹ {{ cardata?.installment_amount ? cardata?.installment_amount : cardata?.premium }}
            <span class="including-gst"> 
              <span *ngIf="cardata?.installment_amount ">/month </span> 
              {{ cardata?.installment_amount ? '(Incl. GST / Excl. Interest Amount)' : '(Incl. GST)' }}
             </span>
          </div>
          <div class="breakup-text" (click)="viewPriceBreakupModal(cardata)">
            Price Breakup
          </div>
          <ng-container *ngIf="!familyType && provider_name === 'Magma' && baseURl !== '/rbhealth-renewals/' ">
          <section class="example-section"   *ngIf="  (!urlEndPoint.includes('proposer') && isMaternityChecked === true) || (urlEndPoint.includes('proposer') && (isMaternityChecked === true || isMaternityChecked === false))">
            <mat-checkbox class="example-margin" [checked]="isMaternityChecked" [disabled]="isAddOnTypePresent && !urlEndPoint.includes('proposer')  " (change)="maternityCheck($event.checked)">Maternity Cover </mat-checkbox>
          </section>
        </ng-container>
        </div>
      </div>
    </div>


  </div>

  <div class="mobile-only">
    <hr />
    <div class="mweb-premium-show">
      <div class="premium-text">Payable Amount</div>
      <div class="premium-text">₹ {{ cardata?.installment_amount ? cardata?.installment_amount : cardata?.premium }} {{
        cardata?.installment_amount ? '/month' : '/Year'}} {{ cardata?.installment_amount ? '(Incl. GST / Excl. Interest
        Amount)' : '(Incl. GST)' }} </div>

    </div>
    <div class="breakup-text" (click)="viewPriceBreakupModal(cardata)"> Price Breakup</div>
    <ng-container *ngIf="!familyType && provider_name === 'Magma'">
    <section class="example-section"   *ngIf="baseURl !== '/rbhealth-renewals/'  && (!urlEndPoint.includes('proposer') && isMaternityChecked === true) || (urlEndPoint.includes('proposer') && (isMaternityChecked === true || isMaternityChecked === false)) ">
      <mat-checkbox class="example-margin" [checked]="isMaternityChecked" [disabled]="isAddOnTypePresent && !urlEndPoint.includes('proposer')  " (change)="maternityCheck($event.checked)" >Maternity Cover</mat-checkbox>
    </section>
  </ng-container>
  </div>
</div>