import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CommonModalService } from "../../services/common-modal.service";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { SharedDataService } from "../../services/shared-data.service";
import { DialogData } from "../../model/data.model";
import { DateToAgePipe } from "../../pipes/date-to-age.pipe";
import { ApiService } from "../../services/api.service";
import { ApiConstants } from "src/app/api.constant";
@Component({
  selector: "app-plan-card",
  templateUrl: "./plan-card.component.html",
  styleUrls: ["./plan-card.component.scss"],
})
export class PlanCardComponent implements OnInit {
  @Output() healthCardDataEvent = new EventEmitter<any>(); // Create an output event

  dobAge: any;
  eldest_age: any | null;
  planData: any | undefined;
  healthPlan: any | undefined;
  bgColor: any;
  bgCardsColor: any;
  isCheckpath: boolean = true;
  baseURl : string = "";
  @Input() orderId = "";
  cardata: any;
  isSwap: boolean = false;
  isMaternityChecked: boolean = false;
  // Add this property to track add_on_type status
isAddOnTypePresent: boolean = false;

  pricebreakuppopup: {
    modalType: number;
    widthObtained: string;
    heightObtained: string;
    topObtained: string;
    classObtained: string;
  } = {
    modalType: 31,
    widthObtained: "50%",
    heightObtained: "auto",
    topObtained: "auto",
    classObtained: "pricebreakuppopup",
  };

  magmaTopupPopUp: {
    modalType: number;
    widthObtained: string;
    heightObtained: string;
    topObtained: string;
    classObtained: string;
  } = {
    modalType: 35,
    widthObtained: "30%",
    heightObtained: "auto",
    topObtained: "auto",
    classObtained: "MagmaTopupComponent",
  };

  paymentDataObject: any;
  is_bimapay: boolean = false;
  urlEndPoint: any;
  check_Upgrade_Renewals_Journey: any;
  provider_name: any;
  familyType: boolean = false;

  constructor(
    private sharedDataService: SharedDataService,
    private commonModal: CommonModalService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService
  ) {
    this.baseURl = location.pathname;
    this.urlEndPoint = location.hash.split("/")[1];
    this.paymentDataObject = this.sharedDataService.getPaymentData();

    let maternityAddons = sessionStorage.getItem("maternityAddons");
    if (maternityAddons) {
      this.isMaternityChecked = true;
    }
    

    this.sharedDataService.updatePlanId.subscribe((data) => {
      this.healthPlan = data;
      if (data && data != undefined) {
        this.isSwap = true;
        this.healthcardData(`?plan_id=${data}`, "", "", false);
      }
    });
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: any) => {
      if (
        params?.plan &&
        params?.plan != undefined &&
        params?.orderId == undefined
      ) {
        this.healthPlan = params["plan"];
        this.healthcardData(`?plan_id=${params?.plan}`, "", "", true);
      } else if (
        params?.plan &&
        params?.plan != undefined &&
        params?.orderId &&
        params?.orderId != undefined &&
        this.isSwap == false
      ) {
        this.healthPlan = params["plan"];
        this.healthcardData(`?order_id=${params?.orderId}`, "", "", true);
        const token = sessionStorage.getItem("encrypted_token");
        if (token) {
          this.apiService
            .getRequestedResponse(
              `${ApiConstants.ENCRYPTED_TOKEN}?encrypted=${token}`
            )
            .subscribe((res) => {
              if (this.baseURl === "/rbhealth-renewals/") {
                if (res?.is_journey === "Upgrade") {
                  this.check_Upgrade_Renewals_Journey = "Upgrade";
                } else if (!res?.is_journey) {
                  this.check_Upgrade_Renewals_Journey = "Renewals";
                }
              }
              this.healthPlan = res?.plan_id;
              this.orderId = res?.order_id;
              if (res?.order_id && res?.order_id != undefined) {
                this.healthcardData(`?order_id=${res?.order_id}`, "", "", true);
              }
            });
        }
      } else {
        const token = sessionStorage.getItem("encrypted_token");
        if (token) {
          this.apiService
            .getRequestedResponse(
              `${ApiConstants.ENCRYPTED_TOKEN}?encrypted=${token}`
            )
            .subscribe((res) => {
              // Check if `add_on_type` exists and is not an empty array
    if (res?.add_on_type && Array.isArray(res.add_on_type) && res.add_on_type.length > 0) {
      this.isMaternityChecked = true;
      sessionStorage.setItem("maternityAddons", JSON.stringify(res.add_on_type));
    } else {
      this.isMaternityChecked = false;
      // sessionStorage.removeItem("maternityAddons"); 
      // Check if baseUrl is '/rbhealth-renewals/' and 'is_customer_upgrade_journey' is set in sessionStorage
  if (!(this.baseURl === '/rbhealth-renewals/' && sessionStorage.getItem('is_customer_upgrade_journey'))) {
    sessionStorage.removeItem("maternityAddons");
  }
    }

              if (this.baseURl === "/rbhealth-renewals/") {
                if (res?.is_journey === "Upgrade") {
                  this.check_Upgrade_Renewals_Journey = "Upgrade";
                } else if (!res?.is_journey && !sessionStorage.getItem('is_customer_upgrade_journey' )) {
                  this.check_Upgrade_Renewals_Journey = "Renewals";
                }  else if (!res?.is_journey && sessionStorage.getItem('is_customer_upgrade_journey' )) {
                  this.check_Upgrade_Renewals_Journey = "Upgrade";
                }
              }
              // Set isAddOnTypePresent based on res.add_on_type
             this.isAddOnTypePresent = !!res?.add_on_type;
              this.healthPlan = res?.plan_id;
              this.orderId = res?.order_id;
              if (res?.order_id && res?.order_id != undefined) {
                this.healthcardData(`?order_id=${res?.order_id}`, "", "", true);
              }
            });
        }
      }
    });
    this.sharedDataService.getdobForlife.subscribe((dob) => {
      this.dobAge = dob;
    });
  }

  /*** Fetches health card data based on the provided plan ID.**/
  healthcardData( healthPlan, selectedPaymentMonth, eazyPayLeadId, isBimaPayActive: boolean ) {
    this.paymentDataObject = this.sharedDataService.getPaymentData();
    let url = `${ApiConstants.GET_HEALTHCARD_BY_ID}${healthPlan}`;

    const paymentMonth = selectedPaymentMonth === "Full Payment" ? "" : selectedPaymentMonth || this.paymentDataObject?.selectedPaymentMonth;

    const bimaPayId = eazyPayLeadId || this.paymentDataObject?.eazyPayLeadId;
    // When the boolean is true, apply the condition
    if (isBimaPayActive && paymentMonth !== "" && bimaPayId) {
      url += `&easy_pay_month=${paymentMonth}&bima_pay_id=${bimaPayId}`;
    }


      let addOnsValue;
// Check if this.maternityAddOn is defined, not empty, null, or undefined
if (this.maternityAddOn !== undefined && this.maternityAddOn !== null && this.maternityAddOn !== '') {
  addOnsValue = this.maternityAddOn.toString();
} else {
  // Fallback to session storage if this.maternityAddOn is not valid
  let maternityAddons = sessionStorage.getItem("maternityAddons");
  if (maternityAddons) {
    let addOns = JSON.parse(maternityAddons); // `addOns` is now an array
    addOnsValue = addOns[0]?.toString();
  }
}

if(this.baseURl === '/renewbuy-health/' && this.isMaternityChecked === true) {
this.isAddOnTypePresent = true;
}

// Append to URL if addOnsValue is valid
if (addOnsValue && addOnsValue !== 'undefined' && addOnsValue !== 'null' && addOnsValue !== '') {
  url += `&add_on_type=${addOnsValue}`;
}

    this.apiService.getRequestedResponse(url).subscribe((res: any) => {
      if (res) {
        res[0].order_id = this.orderId;
        this.cardata = res[0];
        this.healthCardDataEvent.emit(this.cardata); // Emit the data to parent
        this.provider_name = res[0]?.provider_name;
       if(this.provider_name === 'Magma' ) sessionStorage.setItem("insurer", this.provider_name);
        if(res[0]?.max_adults === 1  && res[0]?.max_child  === 0){
          this.familyType = true;
        } else if (res[0]?.max_adults === 2  && res[0]?.max_child  === 2){
        this.familyType = true;
        };
        // Check if maternityPremium is not already set in session storage
      if (!sessionStorage.getItem("maternityPremium") && this.maternityAddOn) {
        const premiumValue = this.cardata.installment_amount ?? this.cardata.premium;
        sessionStorage.setItem("maternityPremium", premiumValue);
      }
      if(sessionStorage.getItem("maternityPremium") && this.maternityAddOn && this.isMaternityChecked === true ){
        this.magmaTopup()
      }
      }
    });
  }

  viewPriceBreakupModal(data: any) {
    let resWidth;
    let resTop;
    if (window.screen.width <= 767) {
      resWidth = "95%";
      resTop = "5%";
    } else {
      resWidth = this.pricebreakuppopup["widthObtained"];
      resTop = "5%";
    }

    const obj: DialogData = {
      type: this.pricebreakuppopup["modalType"],
      width: this.pricebreakuppopup["widthObtained"],
      height: this.pricebreakuppopup["heightObtained"],
      classNameObtained: this.pricebreakuppopup["classObtained"],
      minWidth: resWidth,

      dataInfo: {
        top: resTop,
        data: data,
        plan_id: this.healthPlan,
        is_renewals: this.check_Upgrade_Renewals_Journey,
      },
    };
    this.commonModal.openDialog(obj).subscribe((data: any) => {
      // if (data) {
      //   this.sharedDataService.getRetrievePlan(data["data"]);
      //   this.router.navigate(["customer-proposer", data["data"]]);
      // }
    });
  }

  maternityCheck(isChecked: boolean): void {
    if (isChecked) {
      this.addMaternity()
      this.isMaternityChecked = true;
    } else {
      this.magmaTopup();
      this.isMaternityChecked = false;
    }
  }

  maternityAddOn:any
  addMaternity() {
    this.apiService
    .getRequestedResponse(ApiConstants.MAGMA_ADD_ONS)
    .subscribe((res: any) => {
      // Filter to find the addon with the name "Maternity-Magma"
      const maternityAddon = res.find( (addon: any) => addon.name === "Maternity-Magma" );
      // Check if the addon is found and log its ID
      if (maternityAddon ) {
        this.maternityAddOn = maternityAddon?.id
        this.healthcardData(`?plan_id=${this.healthPlan}`, "", "", true)
      }
    });
  }

  /** Opens the MagmaTopupComponent modal to allow users to top up their Magma balance. **/
  magmaTopup() {
    this.isMaternityChecked = false;
    let resWidth;
    let resTop;
    if (window.screen.width <= 767) {
      resWidth = "95%";
      resTop = "7%";
    } else {
      resWidth = this.magmaTopupPopUp["widthObtained"];
      resTop = "8%";
    }

    const obj: DialogData = {
      type: this.magmaTopupPopUp["modalType"],
      width: this.magmaTopupPopUp["widthObtained"],
      height: this.magmaTopupPopUp["heightObtained"],
      classNameObtained: this.magmaTopupPopUp["classObtained"],
      minWidth: resWidth,

      dataInfo: {
        top: resTop,
        journey: sessionStorage.getItem("maternityAddons")
          ? "maternity-covered"
          : "remove-maternity",
        premium: sessionStorage.getItem("maternityPremium"),
      },
    };
    this.commonModal.openDialog(obj).subscribe((data: any) => {
      if (data?.data) {
        this.isMaternityChecked = true;
        // this.healthcardData(`?plan_id=${this.healthPlan}`, "", "", true);
      } else  if(data?.maternity){
        this.isMaternityChecked = true;
      } else if (data?.remove_maternity) {
        this.maternityAddOn = '';
        this.healthcardData(`?plan_id=${this.healthPlan}`, "", "", true);
        this.isMaternityChecked = false;
      } else {
        this.isMaternityChecked = false;
      }
    });
  }
}
