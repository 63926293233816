import { Component, OnInit, Inject, Optional } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogData } from "../../../model/data.model";
import { ApiConstants } from "../../../api.constant";
import { ApiService } from "src/app/services/api.service";
import { SharedDataService } from "src/app/services/shared-data.service";
@Component({
  selector: "app-price-breakup-popup",
  templateUrl: "./price-breakup-popup.component.html",
  styleUrls: ["./price-breakup-popup.component.scss"],
})
export class PriceBreakupPopupComponent implements OnInit {
  plan_id: any;
  viewBreakupData: any;
  constructor(
    private dialogRef: MatDialogRef<PriceBreakupPopupComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData | any,
    private apiService: ApiService,
    private sharedDataService : SharedDataService,
  ) {    
    this.plan_id = data["plan_id"];
    this.view_beakup(this.plan_id,data?.data?.order_id,data?.is_renewals);
  }

  ngOnInit(): void {}
  closeDialog(): void {
    this.dialogRef.close();
  }
  view_beakup(plan_id,order_id,renewals) {    
    let queryParams = '';

    if (plan_id && ( !renewals  && renewals !== 'Renewals' )  ) {
      // If plan_id exists, pass it as a query parameter
      queryParams = `plan_id=${plan_id}`;
    } else {
      if(renewals === 'Renewals'){
      queryParams = `order_id=${order_id}&is_renewal=${renewals === 'Renewals'}`;
      } else if(renewals === 'Upgrade'){
        queryParams = `plan_id=${plan_id}`;
      }
    }
    let maternityAddons = sessionStorage.getItem("maternityAddons");
    let addOnsValue;
    if (maternityAddons) {
      let addOns = JSON.parse(maternityAddons); // `addOns` is now an array
      addOnsValue = addOns[0]?.toString();
      // Only append `add_on_type` if `addOnsValue` is truthy and not an empty string or empty array
      if ( addOnsValue && addOnsValue !== "undefined" && addOnsValue !== "null" && addOnsValue !== "" ) {
        queryParams += `&add_on_type=${addOnsValue}`;
      }
    }
    const tenXTopup = sessionStorage.getItem('tenXTopup');
    if (tenXTopup) {
      queryParams += `&is_top_up=${tenXTopup}`;
    }
    this.apiService .getRequestedResponse(`${ApiConstants.VIEW_BREAKUP}?${queryParams}`) .subscribe((res) => {
        this.viewBreakupData = res;
      });
  }

  getDisplayAmount(): number | null {
    if (this.data?.data?.installment_amount) {
      return this.data.data.installment_amount;
    } else if (this.viewBreakupData?.total_amount) {
      return this.viewBreakupData.total_amount;
    }
    return null; // Fallback if no value is available
  }
  
}
