import { Component, Inject, inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../../model/data.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { timer } from 'rxjs';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-otp-verification-popup',
  templateUrl: './otp-verification-popup.component.html',
  styleUrls: ['./otp-verification-popup.component.scss']
})
export class OtpVerificationPopupComponent implements OnInit {

otp:Boolean = true; 
@ViewChild("ngOtpInput", { static: false }) ngOtpInput: any; config = { allowNumbersOnly: true, length: 6, isPasswordInput: false, disableAutoFocus: false, placeholder: "*", inputStyles: { width: "50px", height: "50px", }, inputClass:{borderradius:"25px"} }

  mobile: string;
  orderId: any;
  time = 90;
  validOtp: boolean = false;
  errorMessage!: string;
  otpNumber:any|undefined;
 submitOtp:boolean=false
 isUser:boolean=false
  constructor(
    private apiService :ApiService,
    private formBuild:FormBuilder,
    private dialogRef: MatDialogRef<OtpVerificationPopupComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData | any,
  ) {
        this.mobile = data['mobile_no'];
        this.orderId = data['orderId'];
        this.isUser=(data['isUser'])?true:false
      
   }
   onOtpChange(event){
    this.otpNumber=event
    if(this.otpNumber.length>=6){
     this.submitOtp=true
    }else{
      this.submitOtp=false
    }
  }
   submitOtpForm(otpnumber){
    if(otpnumber.length<=6){
        const data = {
        otp: Number(otpnumber),
        mobile_no: this.mobile,
        orderId:this.orderId
        };
        this.verifyOTP(data,this.isUser);
    }
   }
  // reset Timer

  resetTimer() {
    this.time = 90;
}

initateOTP() {
    this.resetTimer();

    const url = '/api/v2/lifeotp/create_otp/';
    const data = {
        order_id: this.data['orderId'],
           }
    this.apiService.postRequestedResponse(url, data).subscribe((res: any) => {
        if (res) {
            const otpres = res;
        }
    });

}

closeDialog() {
    this.dialogRef.close({ event: 'close' });
}


verifyOTP(data,isUser) {
  let endpath_otp=(isUser==true)?'/rblifeorder/validate_otp_by_customer/':'/lifeotp/validate_otp/'
    const url = '/api/v2'+endpath_otp;
    this.apiService.postRequestedResponse(url, data).subscribe((res: any) => {
        if (res && res['valid']) {
            this.validOtp = true;
            this.dialogRef.close({ event: 'close', data: res });
        } else if (res['message']) {
            this.ngOtpInput.setValue('');
            this.errorMessage = res['message'];
        } else {
            this.submitOtp=false;
            this.ngOtpInput.setValue('');
            this.errorMessage = 'Invalid OTP';
        }
    });

}
  ngOnInit(): void {
    timer(0, 1000).subscribe((ellapsedCycles: any) => {
      if (this.time > 0) {
          this.time--;
      }
  });
  }

}
