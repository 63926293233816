import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateToAge'
})
export class DateToAgePipe implements PipeTransform {

    transform(dob: string) {
        if (dob) {
            /**
             * Get year on subtracting from date of birth year from current year
             * then compare date  and ignore year for a while
             * then assigning years
             */
            const d = new Date(dob);
            const now = new Date();

            // Get year difference from date of birth year
            let years = now.getFullYear() - d.getFullYear();

            // Compare dates ignoring years
            d.setFullYear(now.getFullYear());
            if (d > now) {
                years--;
            }

            const age = years;
            return age ? Math.floor(age) : 0;
        }
        return null;
    }

}
