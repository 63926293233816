import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { SharedDataService } from "src/app/services/shared-data.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogData } from "src/app/model/data.model";
import { CommonModalService } from "src/app/services/common-modal.service";
interface Feature {
  title: string;
  value1: string;
  value2?: string;
  value3?: string;
  value4?: string;
}
@Component({
  selector: "app-top-features",
  templateUrl: "./top-features.component.html",
  styleUrls: ["./top-features.component.scss"],
})
export class TopFeaturesComponent implements OnInit {
  numRowsToShow: number = 8;
  showAllRows: boolean = false;
  featureData: any;
  showMagma = false;
  showEidelweiss = false;
  baseURl: any;
  provider: any;
  hideFeaturesTableData: boolean = false;
  @Input() getPlanType: string = "Wellness + Health Insurance";
  featuresTableData: any;
  featuresTableDataComboPlan: any[] = [
    {
      title: "Room Rent Cover",
      value1:
        "1% of Sum Insured for SI up to 5 Lacs, Single Private AC Room for SI above 5 Lacs",
      value2: "Rs. 5000 for SI up to 4 Lacs, 1.5% of SI for 5 Lacs and Above",
      value3: "No Room Rent Capping",
      value4 : "Single A/C Private Room",
      value5:  "2% of Sum Insured for SI 2L, 3L & 4L, Single Private AC Room for SI 5 Lacs and above "  ,
    },
    {
      title: "Personal Accident Cover ",
      value1:
        "Not Covered",
      value2: "Not Covered",
      value3: "Not Covered",
      value4: "As per Actuals",
      value5:  "Covered up to Chosen Health SI for both Self & Spouse "  ,
    },
    {
      title: "Automatic Restoration of SI",
      value1: "Not Covered",
      value2: "Not Covered",
      value3: "Coverd up to SI for Unrelated illness",
      value4: "Coverd up to SI for Unrelated illness",
      value5:  "Coverd up to SI for Unrelated illness"  ,
    },
    {
      title: "Ayush Treatment",
      value1: "Up to SI",
      value2: "Up to SI",
      value3: "Up to SI",
      value4: "Up to SI",
      value5:  "Up to SI"  ,
    },
    {
      title: "Bariatric Surgery",
      value1: "Not Covered",
      value2: "Not Covered",
      value3: "Covered up to 5% of SI",
      value4: "Covered up to 50% of SI",
      value5:  "Covered up to 50% of SI"  ,
    },
    {
      title: "Psychiatric Illness",
      value1: "Not Covered",
      value2: "Not Covered",
      value3: "Up to SI",
      value4: "Up to SI",
      value5:  "Up to SI"  ,
    },
    {
      title: "Modern Treatment",
      value1: "All covered up to Sum Insured",
      value2: "Coverage as per LGI Terms & Conditions",
      value3: "Up to 50% of Sum Insured",
      value4: "All covered up to Sum Insured",
      value5:  "All covered up to Sum Insured"  ,
    },
    {
      title: "Maternity Cover",
      value1: "Available for SI 7.5 Lacs & 10 Lacs",
      value2: "Available for SI 4 Lacs and Above",
      value3: "Not Covered",
      value4: "Covered with all Sum Insured ",
      value5:  "Covered with all Sum Insured "  ,
    },
    {
      title: "Maternity Amount Cover",
      value1: "50K cover for Normal and C Section ( for first 2 Children)",
      value2: "50K cover for Normal and C Section ( for first 2 Children)",
      value3: "Not Covered",
      value4: "50K cover for Normal and C Section ( for first 2 Children)",
      value5:  "50K cover for Normal/C Section (For first 2 Children)"  ,
    },
    {
      title: "Maternity Waiting Period",
      value1: "24 Months for Applicable Plans",
      value2: "9 Months for Applicable Plans",
      value3: "Not Covered",
      value4: "9 Months for Applicable Plans",
      value5:  "12 Months for Applicable Plans"  ,
    },
    {
      title: "Day Care Cover",
      value1: "Up to SI",
      value2: "Up to SI",
      value3: "Up to SI",
      value4: "Up to SI",
      value5:  "Up to SI"  ,
    },
    {
      title: "Domiciliary Treatment",
      value1: "covered up to 30% of SI",
      value2: "covered up to 10% of SI",
      value3: "Up to SI",
      value4: "Up to SI",
      value5:  "Up to SI"  ,
    },
    {
      title: "Domestic Road Ambulance",
      value1: "Up to Rs. 1000",
      value2: "Up to Rs. 1000",
      value3: "Up to Rs. 5000",
      value4: "Up to Rs. 2000",
      value5:  "Up to Rs. 2000"  ,
    },
    {
      title: "Pre-Post Hospitalization",
      value1: "30 Days/ 60 Days",
      value2: "30 Days/60 Days",
      value3: "30 Days/60 Days",
      value4: "Up to Rs. 2000",
      value5:  "60 Days/90 Days"  ,
    },
    {
      title: "Co-Pay",
      value1:
        "No Co-Pay in cashless claims, 20% Co-Pay applicable for all Reimbursement claims",
      value2: "No Co-Pay",
      value3: "No Co-Pay",
      value4: "No Co-Pay",
      value5:  "No Co-Pay"  ,
    },
    {
      title: "Pre-existing Disease Coverage",
      value1:
        "No PED Covered except Diabetes or Hypertension cases where such diseases are not more than 3 years Old. Waiting period for such PED is 24 Months",
      value2:
        "No PED Covered except Diabetes or Hypertension cases where such diseases are not more than 3 years Old. Waiting period for such PED is 24 Months",
      value3:
        "No PED Covered except Diabetes or Hypertension cases where such disease are not more than 3 years Old. Waiting period for such PED is 24 Months",
        value4: "No PED Covered except Diabetes or Hypertension cases where such disease are not more than 3 years Old. Waiting period for such PED is 24 Months",
        value5:  "No PED Covered except Diabetes or Hypertension cases where such disease are not more than 3 years Old. Waiting period for such PED is 24 Months"  ,
    },
    
    {
      title: "Disease Sub-limits",
      value1: "No Sub-Limits, up to SI",
      value2: "No Sub-Limits, up to SI",
      value3: "No Sub-Limits, up to SI",
      value4: "No Sub-Limits, up to SI",
      value5:  "No Sub-Limits, up to SI"  ,
    },
    {
      title: "Family Combination Max",
      value1: "2 Adult & 2 Child",
      value2: "2 Adult & 2 Child",
      value3: "2 Adult & 2 Child",
      value4: "2 Adult & 2 Child",
      value5:  "2 Adult & 2 Child"  ,
    },
    {
      title: "Sum Insured Variants",
      value1: "2L, 3L, 4L, 5L, 7.5 L & 10L",
      value2: "2L, 3L, 4L, 5L, 7.5 L & 10L",
      value3: "2L, 3L, 4L, 5L, 7.5 L & 10L",
      value4: "2L, 3L, 4L, 5L, 7.5 L & 10L",
      value5:  "2L, 3L, 4L, 5L, 7.5 L & 10L"  ,
    },
    {
      title: "Min Entry Age",
      value1: "91 days for Child & 18 Y for Adult",
      value2: "91 days for Child & 18 Y for Adult",
      value3: "91 days for Child & 18 Y for Adult",
      value4: "91 days for Child & 18 Y for Adult",
      value5:  "91 days for Child & 18 Y for Adult"  ,
    },
    {
      title: "Max Entry Age",
      value1: "65 Years - Up to 5 Lacs SI, 50 Years for Above 5 Lacs SI",
      value2: "up to 50 Yrs for all Sum Insured",
      value3: "up to 65 Years for All Sum Insured",
      value4: "65 Years - Up to 5 Lacs SI, 50 Years for Above 5 Lacs SI",
      value5:  "65 Years - Up to 5 Lacs SI, 50 Years for Above 5 Lacs SI"  ,
    },
    {
      title: "Dependent Child Age",
      value1: "91 days to 30 Y",
      value2: "91 days to 30 Y",
      value3: "91 days to 25 Y",
      value4: "91 days to 25 Y",
      value5:  "91 days to 25 Y"  ,
    },
  ];
  featuresTableDataWellnesPlan: any[] = [
    {
      title: "Tele Consultations- GP+ Routine Specialists",
      value4:
        "Up to 4* tele consultations per month in Silver Plan, Up to 5* tele consultations per month in Gold Plan & Unlimited tele consultations per month in Platinum Plan ",
    },
    {
      title:
        "Health check up- Home Visit- 1 per member- Active Life Silver - CBC, FBS, Urine Routine, Lipid Profile, Liver, Kidney",
      value4: "1 voucher for Silver Plan & 2 vouchers for Gold & Platinum Plan",
    },
    {
      title: "Flexi Health Wallet",
      value4: "Wallet amount is equal to OPD Plan price",
    },
    {
      title: "Diagnostics- Lab tests & Scans",
      value4:
        "25% of Wallet in Silver Plan, 40% of Wallet Price in Gold Plan & 50% of Wallet price in Platinum Plans",
    },
    {
      title: "In Clinic OPD Consults- GP",
      value4:
        "10% of Wallet price in Gold Plans & 30% of Wallet price in Platinum Plans. Not available for Silver plans",
    },
    {
      title: "Dental check up-  per member- by clove dental",
      value4: "Up to Wallet Amount",
    },
    {
      title: "Live Online Fitness Class- Yoga, Zumba, Pilates etc",
      value4: "Unlimited",
    },
    {
      title: ` <ul>Discounted Network Benefits:
      <li>Up to. 50% discount on routine Lab tests listed on Healthassure platform via prior appointments in HA network centers</li>
      <li>Up to 20% discount on home delivery of prescription medicines ordered via HealthAssure Platform</li>
      </ul>`,
      value4: "Yes",
    },
  ];

  opentandc: {
    modalType: number;
    widthObtained: string;
    heightObtained: string;
    topObtained: string;
    classObtained: string;
  } = {
    modalType: 27,
    widthObtained: "90%",
    heightObtained: "auto",
    topObtained: "auto",
    classObtained: "tandc-modal",
  };

  constructor(
    private sharedDataService: SharedDataService,
    private commonModal: CommonModalService
  ) {
    this.baseURl = location.pathname;
    if (this.baseURl == "/rbhealth-renewals/") {
      this.hideFeaturesTableData = true;
    }

    this.topFeatures();
  }

  ngOnChanges() {
    if (this.getPlanType == "Wellness + Health Insurance") {
      this.featuresTableData = this.featuresTableDataComboPlan;
    } else {
      this.featuresTableData = this.featuresTableDataWellnesPlan;
    }
  }

  ngOnInit(): void {}
  /**
   * this function is used for show all rows for show top features
   **/
  readMore() {
    this.showAllRows = !this.showAllRows;
  }

  // health terms and conditions popup code [start]
  HealthTermsAndConditionsPopup() {
    let resWidth;
    let resTop;
    if (window.screen.width <= 767) {
      resWidth = "95%";
      resTop = "5%";
    }

    const obj: DialogData = {
      type: this.opentandc["modalType"],
      width: this.opentandc["widthObtained"],
      height: this.opentandc["heightObtained"],
      classNameObtained: this.opentandc["classObtained"],
      minWidth: resWidth,
      dataInfo: {},
    };

    this.commonModal.openDialog(obj).subscribe((res) => {});
  }

  // health terms and conditions popup onclick open [start]
  HealthTandCPopup() {
    this.HealthTermsAndConditionsPopup();
  }

topFeatures() {
  this.sharedDataService.topFeaturesPlan.subscribe((res) => {
    this.featureData = Object.values(res).map((item: any) => {
      return { insurer: item.insurer_name };
    });
    this.showMagma = this.featureData.some(item => item.insurer === "Magma");
    this.showEidelweiss = this.featureData.some(item => item.insurer === "Eidelweiss");
  });
}
 
}
