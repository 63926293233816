import { Routes } from "@angular/router";
import { AuthGuard } from "./services/auth.guard";
let baseURL;
if (location.pathname.split("/")[1] == "rbhealth") {
  baseURL = "quotes";
} else if (location.pathname.split("/")[1] == "rbhealth-renewals") {
  baseURL = "renewals-quotes";
} else if (location.pathname.split("/")[1] == "renewbuy-health") {
  baseURL = "buy-online";
} else if (location.pathname.split("/")[1] == "privacy-policy") {
  baseURL = "privacy-policy";
} else if (location.pathname.split("/")[1] == "terms-and-conditions") {
  baseURL = "terms-and-conditions/po";
} else if (location.pathname.split("/")[1] == "retirement-solution") {
  baseURL = "pay/";
} else if (location.pathname.split("/")[1] == "network-hospitals") {
  baseURL = "network-hospitals";
}

export const AppRoutingModule: Routes = [
  { path: "", redirectTo: baseURL, pathMatch: "full" },
  {
    path: "quotes",
    loadChildren: () =>
      import("./modules/quotes-v2/quotes-v2.module").then(
        (m) => m.QuotesV2Module
      ),
  },
  {
    path: "proposer",
    loadChildren: () =>
      import("./modules/proposer-v2/proposer-v2.module").then(
        (m) => m.ProposerV2Module
      ),
  },
  {
    path: "proposer/:plan",
    loadChildren: () =>
      import("./modules/proposer-v2/proposer-v2.module").then(
        (m) => m.ProposerV2Module
      ),
  },
  {
    path: "proposer/:plan/:orderId",
    loadChildren: () =>
      import("./modules/proposer-v2/proposer-v2.module").then(
        (m) => m.ProposerV2Module
      ),
  },
  {
    path: "declaration/:plan/:orderId",
    loadChildren: () =>
      import("./modules/declaration-v2/declaration-v2.module").then(
        (m) => m.DeclarationV2Module
      ),
  },
  {
    path: "declaration",
    loadChildren: () =>
      import("./modules/declaration-v2/declaration-v2.module").then(
        (m) => m.DeclarationV2Module
      ),
  },
  {
    path: "review/:plan/:orderId",
    loadChildren: () =>
      import("./modules/review-v2/review-v2.module").then(
        (m) => m.ReviewV2Module
      ),
  },
  {
    path: "review",
    loadChildren: () =>
      import("./modules/review-v2/review-v2.module").then(
        (m) => m.ReviewV2Module
      ),
  },

  {
    path: "invalid-agent",
    loadChildren: () =>
      import(
        "./modal-component/check-valid-agent/check-valid-agent.module"
      ).then((m) => m.CheckValidAgentModule),
  },

  {
    path: "buy-online",
    loadChildren: () =>
      import("./modules/quotes-v2/quotes-v2.module").then(
        (m) => m.QuotesV2Module
      ),
  },

  {
    path: "disclaimer",
    loadChildren: () =>
      import("./modules/customer-disclaimer/customer-disclaimer.module").then(
        (m) => m.CustomerDisclaimerModule
      ),
  },
  {
    path: "network-hospitals",
    loadChildren: () =>
      import("./modules/network-hospitals/network-hospitals.module").then(
        (m) => m.NetworkHospitalsModule
      ),
  },
  {
    path: "disclosure",
    loadChildren: () =>
      import("./modules/company-disclosure/company-disclosure.module").then(
        (m) => m.CompanyDisclosureModule
      ),
  },

  {
    path: "privacy-policy",
    loadChildren: () =>
      import(
        "./modules/customer-privacypolicy/customer-privacypolicy.module"
      ).then((m) => m.CustomerPrivacypolicyModule),
    pathMatch: "full",
  },
  {
    path: "terms-and-conditions/po",
    loadChildren: () =>
      import("./modules/customer-tandc/customer-tandc.module").then(
        (m) => m.CustomerTandcModule
      ),
  },
  {
    path: "renewals-quotes",
    loadChildren: () =>
      import("./modules/quotes-v2/quotes-v2.module").then(
        (m) => m.QuotesV2Module
      ),
  },
  {
    path: "proposer/:plan",
    loadChildren: () =>
      import("./modules/proposer-v2/proposer-v2.module").then(
        (m) => m.ProposerV2Module
      ),
    canLoad: [AuthGuard],
  },
  {
    path: "proposer/:plan/:orderId",
    loadChildren: () =>
      import("./modules/proposer-v2/proposer-v2.module").then(
        (m) => m.ProposerV2Module
      ),
    canLoad: [AuthGuard],
  },
  {
    path: "declaration/:plan/:orderId",
    loadChildren: () =>
      import("./modules/declaration-v2/declaration-v2.module").then(
        (m) => m.DeclarationV2Module
      ),
    canLoad: [AuthGuard],
  },
  {
    path: "pay/",
    loadChildren: () =>
      import("./retirementsolution-modules/pay/pay.module").then(
        (m) => m.PayModule
      ),
  },
  {
    path: "payment-success/:plan/:orderId",
    loadChildren: () =>
      import("./modules/payment/payment-success/payment-success.module").then(
        (m) => m.PaymentSuccessModule
      ),
  },
  {
    path: "payment-success",
    loadChildren: () =>
      import("./modules/payment/payment-success/payment-success.module").then(
        (m) => m.PaymentSuccessModule
      ),
  },
  {
    path: "payment-failure/:plan/:orderId",
    loadChildren: () =>
      import("./modules/payment/payment-failure/payment-failure.module").then(
        (m) => m.PaymentFailureModule
      ),
  },
  {
    path: "payment-failure",
    loadChildren: () =>
      import("./modules/payment/payment-failure/payment-failure.module").then(
        (m) => m.PaymentFailureModule
      ),
  },
  //  { path: "**", redirectTo: , pathMatch: "full" },
];
