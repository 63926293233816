import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent implements OnInit {
  healthPlan: any;
  orderId: any;
  paymentStatusData: any;
  lifeTag:boolean=true;
  isUser:boolean=true

  constructor(private apiService: ApiService,) { }

  ngOnInit(): void {
  }
/**
 *  check payment status from api function
 **/ 
getPaymentStatusApi() {
  //var baseURL= this.baseHref;
  let addpath;
  if(location.pathname=='/rblife/'||location.pathname=='/renewbuy-life/'){
      addpath='v2/rblifeorder'
      this.lifeTag=false
  }else{
      addpath= 'v1/order'
  }
  const url = '/api/'+addpath+'/get_data/?id=' + this.orderId;
  this.apiService.getRequestedResponse(url).subscribe((res: any) => {
      if (res) {
          this.paymentStatusData = res;
      }
  });
}
}
