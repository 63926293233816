<div class="dialog-content-box text-center" >
    <div class="row justify-content-center mt-3">
        <div class="col-12">Hi, please choose the policy you wish to upgrade</div>
        <br>
        <div clas="col-12" style="overflow-y: auto; max-height: 25vh;padding-top: 13px;">
            <mat-radio-group class="custom-radio-btn" >

                <mat-radio-button class="custom-radio-button-clr custom-radio-button-margin col-3"
                    [value]="details" *ngFor="let details of policy_details.policy_details;let i=index;" 
                    (change)="radioChange($event)">
                    <!-- <span class="radio-btn-image"></span> -->
                    <span class="content-align">{{details}}</span>

                </mat-radio-button>

            </mat-radio-group>
        </div>
        <div class="col-3"></div>
        <div class="col-6">
            <button  class="submit-btn" mat-button type="submit" (click)="proposer()">
                Submit
            </button>
        </div>
        <div class="col-3">
        </div>
    </div>

</div>