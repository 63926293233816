import { Component, OnInit, Optional, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../../model/data.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { ActivatedRoute,Router } from '@angular/router';
import {SharedDataService} from '../../services/shared-data.service'

@Component({
  selector: 'app-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.scss']
})
export class ShareModalComponent implements OnInit {

  shareInfo: FormGroup;
  errorMessage!: string;
  showError:boolean=true;
  msgshow:boolean=true;
  btnDisabled:boolean=true
  params:any|undefined
  constructor(
      private dialogRef: MatDialogRef<ShareModalComponent>,
      private formBuild: FormBuilder,
      private apiService: ApiService,
      private router:Router,
      private sharedData:SharedDataService,
      private activatedRoute: ActivatedRoute,
      @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData | any,
  ) {

      this.shareInfo = this.formBuild.group({
        mobile: ['', [Validators.pattern(new RegExp('^[6-9]{1}[0-9]{9}$'))]],
        email:['',[Validators.pattern(new RegExp(
          '^[_a-zA-Z0-9]+(.[_a-zA-Z0-9]+)@[a-zA-Z0-9-]+(.[a-zA-Z0-9-]+)(.[a-zA-Z]{2,4})$'))]]
      });
      this.data['share']='policy';
      this.activatedRoute.queryParams.subscribe(params => {
        this.params=params
     });
  }

  closeDialog() {
      this.dialogRef.close({ event: 'close' });
  }
  onSubmit(){
  if((this.shareInfo.value.email!=''&& this.shareInfo['controls']['email'].status=='VALID' )||(this.shareInfo.value.mobile!=''&&this.shareInfo['controls']['mobile'].status=='VALID')){
      this.sharedData.getPolicy.subscribe(res=>{
      let base_url=window.location.href
    if(this.data.select_tab!=null && this.data.dob!=null){
      let share_url
      if(this.params['category']==undefined){
        if(res==''){
          share_url=base_url+"?share="+this.data.share+"&policyId="+this.data.id+"&select_tab="+this.data.select_tab+"&planType="+this.data.playnType+"&dob="+this.data.dob
         }else{
          share_url=base_url+"?share="+this.data.share+"&policyId="+this.data.id+"&policy_no="+res+"&select_tab="+this.data.select_tab+"&planType="+this.data.playnType+"&dob="+this.data.dob
         }
      }else{
        if(res==''){
          share_url=base_url+"&share="+this.data.share+"&policyId="+this.data.id+"&select_tab="+this.data.select_tab+"&planType="+this.data.playnType+"&dob="+this.data.dob
         }else{
          share_url=base_url+"&share="+this.data.share+"&policyId="+this.data.id+"&policy_no="+res+"&select_tab="+this.data.select_tab+"&planType="+this.data.playnType+"&dob="+this.data.dob
         }
      }
     
      let dataobject={
        email:this.shareInfo.value.email,
        phone:this.shareInfo.value.mobile,
        url:share_url
      }
      let url='/api/v2/plan/share_quotation/';
      this.apiService.postRequestedResponse(url,dataobject).subscribe(res=>{
        this.msgshow=false;
        this.showError=true;
        this.btnDisabled=false
        setTimeout(() => {
          this.dialogRef.close()
        }, 2000);
      })
    }
     })
    }else{
      this.showError=false;
      this.msgshow=true;
      setTimeout(() => {
        this.showError=true;
      }, 2000);
    }
  }


  ngOnInit(): void {}
  ngDestroy(){
    location.reload();
  }
}
