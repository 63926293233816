import { Component, OnInit, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { Subscription } from "rxjs";
import { LoaderService } from "src/app/services/loader.service";
import { LoaderState, QuoteLoaderState } from "./loaderInterface";

@Component({
  selector: "app-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.scss"],
})
export class LoaderComponent implements OnInit, OnDestroy {
  show = false;
  isQuoteShow = false;
  loading = this.loaderService.loading$;
  private subscription: Subscription | undefined;
  quoteSubscription: Subscription | undefined;
  constructor(
    // private loaderService: LoaderService,
    private changeDref: ChangeDetectorRef,
    private loaderService: LoaderService
  ) {}

  ngOnInit() {
    // this.subscription = this.loaderService.loaderState
    //     .subscribe((state: LoaderState) => {
    //         this.show = state.show;
    //         // this.isQuoteShow = quoteSubscription.isQuoteShow;
    //         this.changeDref.detectChanges();
    //     });
    // this.quoteSubscription = this.loaderService.quoteloaderState
    //     .subscribe((quoteState: QuoteLoaderState) => {
    //         this.isQuoteShow = quoteState.isQuoteShow;
    //         this.changeDref.detectChanges();
    //     });
  }

  /* Hide loader  */
  ngOnDestroy() {
    // this.subscription.unsubscribe();
  }
}
