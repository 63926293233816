import { Directive, HostListener, ElementRef, Renderer2 } from '@angular/core';

@Directive({
    selector: '[appDateFormat]'
})
export class DateFormatDirective {

    constructor(
        private renderer: Renderer2,
        private elementRef: ElementRef
    ) { }

    @HostListener('keyup', ['$event'])
    onKeyUp(e: KeyboardEvent) {
        let value = this.elementRef.nativeElement.value;

        if (value && (value.length === 2 || value.length === 5) && e.key !== 'Backspace') {
            this.renderer.setProperty(this.elementRef.nativeElement, 'value', value + '/');
        }
    }
}

