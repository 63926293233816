import { Component, Inject, inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../../model/data.model';
@Component({
  selector: 'app-health-declaration-popup',
  templateUrl: './health-declaration-popup.component.html',
  styleUrls: ['./health-declaration-popup.component.scss']
})
export class HealthDeclarationPopupComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<HealthDeclarationPopupComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData | any,
  ) { }

  ngOnInit(): void {
  }
  permission(boolean){
    this.dialogRef.close({ event: close, data: boolean });
  }
}
