import { Component, OnInit, Inject, Optional } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogData } from "../../../model/data.model";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApiService } from "src/app/services/api.service";
import { ApiConstants } from "src/app/api.constant";
@Component({
  selector: "app-hospital-details",
  templateUrl: "./hospital-details.component.html",
  styleUrls: ["./hospital-details.component.scss"],
})
export class HospitalDetailsComponent implements OnInit {
  showShare: boolean = true;
  shareInfo!: FormGroup;
  whatsappStatus: boolean = false;
  numberStatus: boolean = false;
  emailStatus: boolean = false;
  mobilStatus: boolean = false;
  hospitalListData: any;
  hosipital_name: any;
  address: any;
  isSent: boolean = false;
  hospital_mobile: any;
  constructor(
    private dialogRef: MatDialogRef<HospitalDetailsComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData | any,
    private formBuild: FormBuilder,
    private apiService: ApiService
  ) {
    this.hospitalListData = data?.hospitalListData;
    this.hosipital_name = this.hospitalListData.hospital_name;
    this.address = this.hospitalListData?.address;
    this.hospital_mobile = this.hospitalListData?.["Telephone Number"]
      ? this.hospitalListData?.["Telephone Number"]
      : "";
  }

  ngOnInit(): void {
    this.shareInfo = this.formBuild.group({
      email: ["", [Validators.required, Validators.email]],
      whatsapp: ["", [Validators.required, Validators.pattern(/^\d{10}$/)]],
      mobile: ["", [Validators.required, Validators.pattern(/^\d{10}$/)]],
    });
  }
  closeDialog(): void {
    this.dialogRef.close();
  }

  shareDetails() {
    this.showShare = !this.showShare;
  }

  shareWithEmail() {
    if (this.shareInfo.get("email")?.valid) {
      this.sendHospitalData(
        this.shareInfo.get("email")?.value,
        "",
        this.hosipital_name,
        this.address,
        this.hospital_mobile
      );
      this.emailStatus = true;
    } else {
      this.emailStatus = true;
    }
  }

  shareWithWhatsapp() {
    if (this.shareInfo.get("whatsapp")?.valid) {
      this.sendHospitalData(
        "",
        this.shareInfo.get("whatsapp")?.value,
        this.hosipital_name,
        this.address,
        this.hospital_mobile
      );
      this.whatsappStatus = true;
    } else {
      this.whatsappStatus = false;
    }
  }
  shareWithNumber() {
    if (this.shareInfo.get("mobile")?.valid) {
      this.sendHospitalData(
        "",
        this.shareInfo.get("mobile")?.value,
        this.hosipital_name,
        this.address,
        this.hospital_mobile
      );
      this.numberStatus = true;
    } else {
      this.numberStatus = false;
    }
  }

  /**checks if the whatsapp input is valid **/
  onWhatsappInputChange() {
    if (!this.shareInfo.get("whatsapp")?.valid) {
      this.whatsappStatus = false;
    }
  }

  /**checks if the email input is valid **/
  onEmailInputChange() {
    if (!this.shareInfo.get("email")?.valid) {
      this.emailStatus = false;
    }
  }

  sendHospitalData(email, mobile, name, address, hospital_mobile) {
    this.apiService
      .getRequestedResponse(
        `${ApiConstants.SEND_HOSPITAL_DATA}?email=${email}&mobile=${mobile}&name=${name}&address=${address}&hospital_mobile=${hospital_mobile}`
      )
      .subscribe((res) => {
        if (res) {
          this.isSent = true;
        }
      });
  }
}
