<div class="row container-fluid bg-light topbar-border">
    <!-- Logo Added -->
  <!-- <div class="col-11"> -->
    <!-- <nav class="navbar navbar-light  navbar-expand-md bg-faded w-90">
        <a class="navbar-brand d-flex w-10 mr-auto"><img src="assets/images/rb.png" width="30px" height="20px">
            &nbsp;Finsure</a>
    </nav> -->
  <!-- </div> -->
  <!-- Close form -->
    <!-- <div  class="col-1">
        <button mat-button (click)="close()">&#10060;</button>
    </div> -->
  </div>
  <div class="dialog-header">
   
    <div class="d-flex" >
        <img src="/assets/images/rb-motor/retirement-logo.svg" class="cross-btn me-5" width="80" height="40"/>
        <h2 class="dialog-heading ">Become a Retirement Solution Expert</h2>
    </div>
    <div>
        
      </div>
</div>
  
  <!-- <div *ngIf="message" class="alert alert-success alert-dismissible fade show" role="alert">
    <strong>{{"RbHealth.Success!"|translate}}</strong>{{"RbHealth.Admin Created Successfully"|translate}}
  </div> -->
  
  <div class="container">
    <div class="d-flex justify-content-center">
      <!-- Form For Creating User -->
      
      <form [formGroup]="adminForm" (ngSubmit)="useradmin(adminForm.valid)">
        <div class="row">
            <h5 class="heading-text">Please Provide below details</h5>
        </div>
          <!-- Name Field -->
          <div class="row mt-4">
            <h6 class="col-lg-5">Are you existing RenewBuy partner?</h6>
            <div class="col-lg-7">
                <mat-radio-group aria-label="Select an option" (change)="onRadioChange($event)">
                    <mat-radio-button value="yes" #yesRadio class="me-4">Yes</mat-radio-button>
                    <mat-radio-button value="no" #noRadio checked="true">No</mat-radio-button>
                  </mat-radio-group>
            </div>
           
              <div *ngIf="employeeCodeInput">
                <div class="row">
                  <div class="col-lg-4">
                      <label for="">Partner-Code:</label>
                    </div>
                    <div class="col-lg-8 ">
                      <mat-form-field class="custom-mat-form-field" appearance="outline">
            
                        <input matInput formControlName="employee_code" placeholder="Enter EI Code" 
                        ngModel (ngModelChange)="invested($event)">
            
                        <mat-error *ngIf="adminForm.get('employee_code')?.hasError('required')">
                          This is a required field.
                        </mat-error>
            
                        <mat-error *ngIf="adminForm.get('employee_code')?.hasError('pattern')">
                            Invalid Mobile number
                        </mat-error>
            
                    </mat-form-field>
                    </div>
                    <div class="error" *ngIf="errormeassge" formControlName="error">
                      <p class="col-12 text-danger">Please Enter a valid EI Code</p>
                    </div>
                </div>
                <!-- <div class="col-lg-8">
v
                </div>
                <div class="col-lg-4">
                    <mat-form-field class="custom-mat-form-field" appearance="outline">
      
                        <input matInput formControlName="employee_code" placeholder="Enter Employee Code" 
                        ngModel (ngModelChange)="invested($event)">
            
                        <mat-error *ngIf="adminForm.get('employee_code')?.hasError('required')">
                          This is a required field.
                        </mat-error>
            
                        <mat-error *ngIf="adminForm.get('employee_code')?.hasError('pattern')">
                            Invalid Mobile number
                        </mat-error>
                        
                    </mat-form-field>
                </div> -->
                
              </div>
          </div>
          <div class="row">
            <div class="col-4 ">
                <label for="">{{"RbHealth.Name" |translate}}:</label>
              </div>
              <div class="col-8 ">
                <mat-form-field class="custom-mat-form-field" appearance="outline">
      
                  <input class="text-capitalize" matInput formControlName="name" placeholder="Enter Name" Character
                      [maxLength]="50" appRemoveWhiteSpace >
      
                  <mat-error *ngIf="adminForm.get('name')?.hasError('required')">
                    This is a required field.
                  </mat-error>
      
                  <mat-error *ngIf="adminForm.get('name')?.hasError('minlength')">
                      Name should contain Minimum 3 characters
                  </mat-error>
      
                  <mat-error *ngIf="adminForm.get('name')?.hasError('pattern')">
                      Only characters are allowed
                  </mat-error>
      
              </mat-form-field>
              </div>
          </div>
          
  
          <!-- Email Field -->
          <div class="row">
            <div class="col-4 ">
                <label for="">{{"RbHealth.Email" |translate}}:</label>
              </div>
              <div class="col-8 ">
                <mat-form-field class="custom-mat-form-field" appearance="outline">
      
                  <input matInput formControlName="email" placeholder="Enter Email" removeSpace value={{EmployeeDetails?.email}}>
      
                  <mat-error *ngIf="adminForm.get('email')?.hasError('required')">
                    This is a required field.
                  </mat-error>
                  <mat-error *ngIf="adminForm.get('email')?.hasError('pattern')">
                      Invalid Email
                  </mat-error>
      
              </mat-form-field>
              </div>
          </div>
        
  
          <!-- Mobile Number Field -->
          <div class="row">
            <div class="col-4 ">
                <label for="">{{"RbHealth.Moblie no" |translate}}:</label>
              </div>
              <div class="col-8 ">
                <mat-form-field class="custom-mat-form-field" appearance="outline">
      
                  <input matInput formControlName="mobile_no" placeholder="Enter Mobile Number" [minLength]="10"
                      [maxLength]="10" appOnlyNumbers value={{EmployeeDetails?.mobile}}>
      
                  <mat-error *ngIf="adminForm.get('mobile_no')?.hasError('required')">
                    This is a required field.
                  </mat-error>
      
                  <mat-error *ngIf="adminForm.get('mobile_no')?.hasError('pattern')">
                      Invalid Mobile number
                  </mat-error>
                  
              </mat-form-field>
              </div>
          </div>
          <div class="row">
            <div class="col-4 ">
                <label for="">Amount:</label>
              </div>
              <div class="col-8">
                {{amount}}
              </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              This fee is for training purpose for retirement solutions expert and is non refundable.

            </div>
          </div>
          
          
        
       
        <!-- </div> -->
        <div class="submit mt-5">
          <a ><button type="submit" formControlName="pay" class="btn "  [disabled]="!adminForm.valid">Pay</button></a>
        </div>
      </form>
    </div>
  