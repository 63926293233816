import { Component, OnInit, Inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { APP_BASE_HREF } from "@angular/common";
import { environment } from "../../../environments/environment";
@Component({
  selector: "customer-header",
  templateUrl: "./customer-header.component.html",
  styleUrls: ["./customer-header.component.scss"],
})
export class CustomerHeaderComponent implements OnInit {
  navbarCollapsed = true;
  source: Boolean = false;
  env = environment;
  constructor(
    private router: Router,
    @Inject(APP_BASE_HREF) private baseHref: string
  ) {}
  ngOnInit(): void {}

  redirectionToHealth() {
    // this.router.navigate(['buy-online']);
    window.location.href =
      this.env.finsureUrl + "renewbuy-health/#/buy-online/";
  }

  redirectionToLife() {
    // this.router.navigate(['buy-life-online']);
    window.location.href =
      this.env.finsureUrl + "renewbuy-suraksha/#/buy-online";
  }
}
