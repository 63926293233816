import { Component, Inject, OnInit, Optional } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DialogData } from "src/app/model/data.model";
import { ApiConstants } from "../../api.constant";
import { ApiService } from "src/app/services/api.service";
import { SharedDataService } from "src/app/services/shared-data.service";

@Component({
  selector: "app-magma-renewals-popup",
  templateUrl: "./magma-renewals-popup.component.html",
  styleUrls: ["./magma-renewals-popup.component.scss"],
})
export class MagmaRenewalsPopupComponent implements OnInit {
  plans = [
    {
      features: "Room Rent Cover",
      sub_feature: "(Single Private AC Room)",
      zuno: "Above ₹5L SI",
      magma: "For All SI (Sum Insured)",
      zunoValid: true,
      magmaValid: true,
    },
    {
      features: "PA Cover",
      sub_feature: "",
      zuno: "Not Covered",
      magma: "Upto SI",
      zunoValid: false,
      magmaValid: true,
    },
    {
      features: "Automatic Restoration",
      sub_feature: "(Unrelated Illness) ",
      zuno: "Not Covered",
      magma: "Upto SI",
      zunoValid: false,
      magmaValid: true,
    },
    {
      features: "Disease Sub - Limits",
      sub_feature: "",
      zuno: "As Per Current Plan",
      magma: "No Sub Limits",
      zunoValid: true,
      magmaValid: true,
    },
    {
      features: "Maternity Cover",
      sub_feature: "",
      zuno: "Above ₹5L SI",
      magma: "Above ₹5L SI",
      zunoValid: true,
      magmaValid: true,
    },
    {
      features: "Psychiatric Illness Cover",
      sub_feature: "",
      zuno: "Not Covered",
      magma: "Upto SI",
      zunoValid: false,
      magmaValid: true,
    },
    {
      features: "Premium",
      // zuno: "₹12,000",
      // magma: "₹12,000",
    },
  ];

  constructor(
    private dialogRef: MatDialogRef<MagmaRenewalsPopupComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData | any,
    private apiService: ApiService,
    private sharedDataService: SharedDataService
  ) {
    dialogRef.disableClose = true;
    this.insurerPremium();
  }

  ngOnInit(): void {}

  magmaJourney(insurer) {
    if (insurer === "magma") {
      this.getAddonsData();
    } else {
      this.dialogRef.close({ event: "close" });
    }
  }

  closeDialog() {
    this.dialogRef.close({ event: "close" });
  }

  zunoPremium: string | null = sessionStorage.getItem('zunoPremium');
  magmaPremium: string | null = sessionStorage.getItem('magmaPremium');
  
  /**
   * Retrieves and stores the premium amounts for Eidelweiss and Magma insurers.
   * If the premium amounts are not already stored in sessionStorage, it fetches the premium data from the shared data service,
   * iterates through the plans, and stores the premium amounts for the respective insurers.
   *
   * @returns {void}
   */
  insurerPremium() {
    this.sharedDataService.getMagmaPremium.subscribe((res) => {
      Object.values(res).forEach((plan: any) => {
        if (plan?.insurer_name === "Eidelweiss") {
          this.zunoPremium = '₹' + plan?.plan_amount;
          sessionStorage.setItem('zunoPremium', this.zunoPremium);
        } else if (plan?.insurer_name === "Magma") {
          this.magmaPremium = '₹' + plan?.plan_amount;
          sessionStorage.setItem('magmaPremium', this.magmaPremium);
        }
      });
    });
  }
  

  /**
   * Retrieves and processes the add-ons data from the Magma insurer.
   * If a "Maternity-Magma" add-on is found, it retrieves existing add-ons from sessionStorage,
   * adds the new add-on to the array, and stores the updated array back into sessionStorage.
   * Finally, it closes the dialog with the necessary data.
   *
   * @returns {void}
   */
  getAddonsData() {
    this.apiService
      .getRequestedResponse(ApiConstants.MAGMA_ADD_ONS)
      .subscribe((res: any) => {
        // Filter to find the addon with the name "Maternity-Magma"
        const maternityAddon = res.find( (addon: any) => addon.name === "Maternity-Magma" );

        // Check if the addon is found and log its ID
        if (maternityAddon) {
          // Retrieve existing addons from sessionStorage or initialize an empty array
          const existingAddons = JSON.parse( sessionStorage.getItem("maternityAddons") || "[]" );

          // Add the new addon to the array
          existingAddons.push(maternityAddon.id);

          // Store the updated array back into sessionStorage
          sessionStorage.setItem( "maternityAddons", JSON.stringify(existingAddons) );

          // Close the dialog with the necessary data
          this.dialogRef.close({ event: "close", data: { addOnsId: maternityAddon.id }, });
        }
      });
  }

  
}
