
<div class="dialog-content-box HealthDeclaration">

    <div class="row">
        <div mat-dialog-actions>
            <div mat-button mat-dialog-close class="close-popup">&#x2715;</div>
        </div>

        <div clas="col-12">
            <!-- <h6>DECLARATION OF GOOD HEALTH - </h6> -->
            <p>I declare that I am in a sound state of health.</p>

            <p>I hereby declare that, as of the date of this declaration, I do not have any history of, have never
                suffered from or currently suffering from medical conditions such as, but not limited to, high blood
                pressure, chest pain, heart attack or any other heart condition; stroke, transient ischemic attack or
                any other cerebrovascular disease; diabetes or any other endocrinal disease; kidney disease; HIV / AIDS
                or AIDS related complex; any cancer or tumor; asthma or any other respiratory disease; any mental or
                nervous disease; hepatitis or any other liver disease; blood disorders; digestive and bowel disorders;
                paraplegia, physical disability or any other disorder of the bones, spine or muscle; any other disease,
                disorder or disability, not mentioned above and excluding minor impairment such as common cough or cold.
                I have never undergone or expect to undergo any surgical procedure for any illness, ailment, disease or
                disability. In the last 5 years, I have not received any form of medication for more than 7 consecutive
                days or been absent from work for more than 7 days.
                For Female Lives: I further declare that presently I am not pregnant or I do not have a history in the
                past of an abortion, miscarriage or caesarian section due to complications during pregnancy or due to
                any other cause, I have not given birth to a child with any congenital disorder such as Down Syndrome,
                congenital heart disease, etc and I have not ever had any disease of breast, uterus, cervix, ovaries or
                any other part of the reproductive system.</p>
            <p> I further declare that, as of the date of this declaration, I do not engage or intend to engage in any
                business, sport or occupation of a hazardous nature. I declare that, I do not have any history of
                conviction under any criminal proceedings in India or abroad.</p>
                <p>I understand, agree and confirm that these statements and this declaration are basis of the contract
                between the insurer and the policyholder. If any untrue statement are contained herein or there has been
                any non disclosure of any material fact, the policy to be issued by the insurer in the name of the
                policyholder may be treated as void as far as I am concerned.</p>
                <p>I confirm that I have read and understood, the rules and any additional rules of the plan, the standard
                Policy provisions and any additional provisions that govern the policy to be issued by insurer in the
                name of the policyholder and on my life, and I agree and confirm that the same shall be binding on me. I
                authorise the policyholder to disclose to the insurer such particulars as they may require including the
                details given above and any changes to the same pay the premium payable on my behalf /collected from me
                to the Insurer. I understand that any statutory levy or charges including any indirect tax may be
                charged to me either now or in future by the insurer and I agree to pay the same. I understand that HDFC
                Life Insurance Company Ltd has the right to reject a proposal without giving reasons thereto and client
                to give an undertaking thereof that he shall not raise any claims thereof. I understand the significance
                of the contract and the contract will be governed by the provisions of the Insurance Act 1938 as amended
                from time to time and that the same will not commence until written acceptance of this application by
                Insurer issue on its normal terms and conditions is received.


            </p>
            <div class="col-12 py-3 text-center">
                <div class="row">
                    <div class="col-4">
                        <button  class="submit-btn my-3" type="submit" (click)="permission('true')" mat-button>Agree</button>

                    </div>
                    <div class="col-4">
                        <button  class="submit-btn my-3" type="submit" (click)="permission('false')" mat-button>Disagree</button>

                    </div>

                </div>
            </div>
        </div>
    </div>
</div>