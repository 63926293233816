<div class="dialog-content-box">
  <div class="price-breakup">
    <div (click)="closeDialog()" class="close-popup">&#x2715;</div>

    <div class="breakup-details">
      <div class="popup-heading">RB Health Plan Price Breakup</div>

      <div class="price-breakup-flex">
        <div class="plan-text">Wellness Wallet Plan (Excl. GST )</div>
        <div class="amount-text">
          ₹{{ viewBreakupData?.opd_plan_amount | number : "1.2-2" }}
        </div>
      </div>
      <hr />
      <div class="price-breakup-flex" *ngIf=" data?.data?.provider_name !== 'Health Assure' " >
        <div class="plan-text">
          {{data?.data?.provider_name}} Health {{ data?.data?.provider_name === 'Go Digit' ? 'Care Plus(Revision)' : '' }} Plan (Excl. GST )
        </div>
        <div class="amount-text">
          ₹{{ viewBreakupData?.health_insurance_amount | number : "1.2-2" }}
        </div>
      </div>
      <hr  *ngIf=" data?.data?.provider_name !== 'Health Assure' "/>

      <div class="price-breakup-flex">
        <div class="plan-text">GST</div>
        <div class="amount-text">
          ₹{{ viewBreakupData?.gst_amount | number : "1.2-2" }}
        </div>
      </div>


      <ng-container *ngIf="data?.data?.installment_amount" >
        <hr/>
        <div class="price-breakup-flex">
          <div class="plan-text">{{data?.data?.installment_amount ? 'Total Payable Amount (Incl. GST)' : '' }}</div>
          <div class="amount-text">
            ₹{{ viewBreakupData?.total_amount | number : "1.2-2" }}
          </div>
        </div>
        <hr/>
        <div class="price-breakup-flex">
          <div class="plan-text">Advance Payable (25% of Total Payable/Incl. GST)</div>
          <div class="amount-text">
            ₹{{ data?.data?.down_payment_amount | number : "1.2-2" }}
          </div>
        </div>
      </ng-container>

    </div>

    <div class="total-amount">
      <div class="total-amt-flex">
        <div class="total-text"> {{data?.data?.installment_amount ? 'Total Payable/month (Incl. GST / Excl. Interest Amount)' : 'Total Payable Amount (Incl. GST)' }}</div>
        <div class="total-amount-text">
          ₹{{ getDisplayAmount() | number : "1.0-0"}}
        </div>
      </div>
    </div>
  </div>
</div>
