import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from '../../services/auth.service';
//import { WindowRef } from '../../services/window.service';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { SharedDataService } from '../../services/shared-data.service';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router'

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    navbarCollapsed = true;
    headerList!: [];
    cookieToken: any;
    getUserProfileData:boolean=true;
    // headerList: {
    //     label: string;
    //     link: string;
    //     icon: string;
    // }[] = [{
    //     label: 'Marketing Manager',
    //     link: 'https://partners.renewbuy.com/beta/#/marketing-collateral',
    //     icon: 'assets/images/marketing_manager.svg',
    // }, {
    //     label: 'Advisor Connect',
    //     link: 'https://partners.renewbuy.com/beta/#/advisor-connect',
    //     icon: 'assets/images/advisor-connect-header.svg',
    // }, {
    //     label: 'Claim & Service Centre Support',
    //     link: 'https://www.renewbuy.com/renewbuy-partners/insurer-contacts/',
    //     icon: 'assets/images/claim-icon.svg',
    // }, {
    //     label: 'Dedicated Support Contact',
    //     link: 'https://partners.renewbuy.com/#/support-contact/',
    //     icon: 'assets/images/support-icon.svg',
    // }
    //     ]

    elem: any;
    isLoggedInVal: Observable<boolean> | any;

    @ViewChild('widgetId') widgetId!: ElementRef;

    constructor(
        private authService: AuthService,
     //   private win: WindowRef,
        private activatedRoute: ActivatedRoute,
        private apiService: ApiService,
        private sharedDataService: SharedDataService,
        private router: Router

    ) {
        const getQueryParams = this.activatedRoute.snapshot.queryParams;
        const isSourceApp = getQueryParams['source'];
        const fromLocalStorage = localStorage.getItem('isSource');
        if (isSourceApp && isSourceApp.length > 1) {
            // this.source = true;
            localStorage.setItem('isSource', 'app');
        }

        if (fromLocalStorage && fromLocalStorage.length > 1) {
            // this.source = true;
        }
    }

    logout() {
        this.authService.logOut();
    }

    ngOnInit(): void {
        this.isLoggedInVal = this.authService.isLoggedIn;

        // const cookies = document.cookie.split(';');

        // for (let i = 0; i < cookies.length; i++) {
        //     let value: any = cookies[i];
        //     value = value.split('=');
        //     if (value[0].trim() == 'amsToken') {
        //         this.cookieToken = value[1];
        //     }
        // }

        if (localStorage.getItem('token')) {
            localStorage.setItem('isSource', 'app');

            const url = '/api/v1/fetch_profile/';

            this.apiService.getRequestedResponse(url, 'ams-headers', false, true).subscribe((res: any) => {
                const user = {
                    first_name: res.first_name,
                    last_name: res.last_name,
                    username: res.unique_code,
                    mobile: res.mobile,
                    email: res.email,
                }
                localStorage.setItem('userInfo', JSON.stringify(user));
                this.sharedDataService.getUserExecutiveCode(user.username);
            })
        }
    }
    redirectToLoginPage(){
        this.router.navigate(['login']);
    }


    ngAfterViewInit() {
        // this.elem = this.widgetId.nativeElement;

        // new this.win.nativeWindow.RB_AMS_SDK({
        //     userInfo: this.elem,
        //     islogIn: this.authService.setUser,
        //     UserlogOut: this.authService.logOut,
        //     amsurl: environment.amsurl,
        // })
    }
}
