import { NgModule, ModuleWithProviders } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { SharedComponentModule } from "../shared-component/shared-component.module";
//import { WindowRef } from '../../services/window.service';
import { AuthService } from "../../services/auth.service";

@NgModule({
  imports: [SharedComponentModule, HttpClientModule],
  exports: [SharedComponentModule],
  declarations: [],
})

// Register here Global servicesl
export class SharedModuleModule {
  static forRoot(): ModuleWithProviders<SharedModuleModule> {
    return {
      ngModule: SharedModuleModule,
      providers: [
        //WindowRef,
        AuthService,
      ],
    };
  }
}
