<div id="remove-member" >
    <ng-container *ngIf="showPopup === 'wantToRemoveMember' " >
    <div class="img-center col-12 mt-2">
        <img src="assets/images/health-v2/icons/user-delete.svg" alt="">
    </div> 
    <div class="col-12 mt-3">
        <div class="content-text">
            Do you want to remove <br>
            member from the plan ?
        </div>
    </div>
    <div class="row wantToRemoveMember-button-row mt-3">
        <div class="col-6">
            <button mat-raised-button class="no-btn" (click)="confirmationButton('no')"> No </button>
        </div>
        <div class="col-6">
            <button mat-raised-button class="yes-btn" (click)="confirmationButton('yes')"> Yes </button>
        </div>
    </div>
</ng-container>

    <ng-container *ngIf="showPopup === 'finalConfirmation' " >
    <div class="img-center col-12 mt-2">
        <img src="assets/images/health-v2/icons/fluent_document-text.svg" alt="">
    </div> 
    <div class="col-12 mt-3">
        <p class="recalculated-content-text">
            Your Plan is now Re-calculated <br> to {{data?.familySizeWithText}}  Plan
        </p>

    </div>
    <div class="col-12 mt-3" *ngFor="let member of activeMembers">
            <ul class="list-group">
              <li class="list-group-item">
                  <span class="member-name">{{ member.name }}</span>
                  <span class="member-details">{{ member.age }} Years  <span class="slash">| </span> {{ member.gender }}</span>
              </li>
            </ul>
    </div>
    <div class="row button-row mt-3">
        <div class="col-6">
            <button mat-raised-button class="no-btn" (click)="confirmationButton('restore')"> Restore </button>
        </div>
        <div class="col-6">
            <button mat-raised-button class="yes-btn" (click)="confirmationButton('continue')"> Continue </button>
        </div>
    </div> 
</ng-container>
 </div>





<div id="remove-member-list"  *ngIf="showPopup === 'selectMemberToRemove' " >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="removeMemberModalLabel">Select Members You want to Remove <br> from your existing plan ?</h3>
        </div>
        <div class="modal-body mt-4" *ngFor="let member of membersArray">
          <ul class="list-group web">
            <li class="list-group-item">
                <mat-checkbox class="example-margin " [checked]="selectedMemberIds === member.id" (change)="toggleMemberSelection(member.id,member)"  ></mat-checkbox>
                <span class="member-name">{{ member.name }}</span>
                <span class="member-details">{{ member.age }} Years  <span class="slash">| </span> {{ member.gender }}</span>
            </li>
          </ul>

          <ul class="list-group mob">
            <li class="list-group-item">
                    <div class="member-data-mob" >
                        <span class="member-name">{{ member.name }}</span>
                        <span class="member-details mob">{{ member.age }} Years  <span class="slash">| </span> {{ member.gender }}</span>
                    </div>
                <mat-checkbox class="example-margin" [checked]="selectedMemberIds === member.id" (change)="toggleMemberSelection(member.id,member)"></mat-checkbox>
            </li>
          </ul>
        </div>
        <div class="modal-footer mt-4">
          <button type="button" class="no-btn" (click)="removeSelectedMember()"  >Remove</button>
        </div>
      </div>
    </div>
</div> 