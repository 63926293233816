import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    source: Boolean = false;

    generalList: {
        label: string;
        url: string;
    }[] = [
            {
                label: 'Article',
                url: 'https://www.renewbuy.com/articles/'
            },
            {
                label: 'Press',
                url: 'https://www.renewbuy.com/press/'
            }, {
                label: 'Carrers',
                url: 'https://www.renewbuy.com/careers/'
            }, {
                label: 'About us',
                url: 'https://www.renewbuy.com/about-us/'
            }, {
                label: 'Contact us',
                url: 'https://www.renewbuy.com/contact-us/'
            },

        ]

    otherList: {
        label: string;
        url: string;
    }[] = [
            {
                label: 'Privacy Policy',
                url: 'https://www.renewbuy.com/privacy-policy/'
            },
            {
                label: 'Terms & Conditions',
                url: 'https://www.renewbuy.com/terms-and-conditions/po'
            }, {
                label: 'Disclaimer',
                url: 'https://www.renewbuy.com/disclaimer/'
            },
        ]

    constructor(
        private activatedRoute: ActivatedRoute
    ) {
        const getQueryParams = this.activatedRoute.snapshot.queryParams;
        const isSourceApp = getQueryParams['source'];
        const fromLocalStorage = localStorage.getItem('isSource');
        if (isSourceApp && isSourceApp.length > 1) {
            this.source = true;
            localStorage.setItem('isSource', 'app');
        }

        if (fromLocalStorage && fromLocalStorage.length > 1) {
            this.source = true;
        }
    }

    ngOnInit(): void {
    }

}
