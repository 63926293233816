<div class="dialog-content-box mob-verifiy-popup">
  <div class="row">
    <div class="col-12">
      <span class="close-pop" (click)="closeDialog()"> &#x2715;</span>
    </div>
    <div class="col-12">
      <div class="otp-heading">Enter Aadhaar Verification OTP</div>
    </div>

    <div class="otp-no-flex">
      <ng-otp-input
        #ngOtpInput
        (onInputChange)="onOtpChange($event)"
        [config]="config"
        [attr.inputmode]="isMobilePlatform() ? 'numeric' : null"
      ></ng-otp-input>
    </div>

    <div class="col-12 text-center error-msg">{{ errorMessage }}</div>
    <div class="resend-otp-flex">
      <div class="resend-otp-text">
        Resend OTP in <span class="bold">{{ time }} Seconds</span>
      </div>
      <ng-container *ngIf="time == 0">
        <div
          class="resend-otp"
          (click)="initateOTP()"
          [class.disabled]="time !== 0"
        >
          RESEND OTP
        </div>
      </ng-container>
    </div>
    <button
      [ngClass]="{ disable: submitOtp == false }"
      (click)="submitOtpForm(otpNumber)"
      class="submit-btn"
      type="submit"
      mat-button
    >
      Submit OTP
    </button>
  </div>
</div>

<div class="fetch-aadhaar-data" *ngIf="fetchedData">
  <mat-spinner diameter="50"></mat-spinner>
  <p class="content">Fetching Details....</p>
</div>

<div class="fetch-aadhaar-data" *ngIf="otplLoader">
  <mat-spinner diameter="50"></mat-spinner>
  <p class="content">Please Wait...</p>
</div>
<!-- </div> -->
