import { Component, OnInit, Optional, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../../model/data.model';

@Component({
    selector: 'app-error-message',
    templateUrl: './error-message.component.html',
    styleUrls: ['./error-message.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ErrorMessageComponent implements OnInit {

    constructor(
        private dialogRef: MatDialogRef<ErrorMessageComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData | any,
    ) { }

    closeDialog() {
        this.dialogRef.close({ event: 'close' });
    }

    ngOnInit(): void {
    }

}
