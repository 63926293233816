import { Component, Inject, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../../model/data.model';
import { FormBuilder } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { ApiConstants } from 'src/app/api.constant';
import { DateToAgePipe } from 'src/app/pipes/date-to-age.pipe';
@Component({
  selector: "app-remove-member-confirmation",
  templateUrl: "./remove-member-confirmation.component.html",
  styleUrls: ["./remove-member-confirmation.component.scss"],
})
export class RemoveMemberConfirmationComponent implements OnInit {
  showPopup: string = "wantToRemoveMember";
  proposerData: any;
  relationsMap: { [key: number]: string } = {};
  membersArray: any[] = []; 
  activeMembers: any[] = []; 
  selectedMemberIds: number | null = null;

  constructor(
    private apiService: ApiService,
    private formBuild: FormBuilder,
    private dateToAgePipe: DateToAgePipe,
    private sharedDataService: SharedDataService,
    private dialogRef: MatDialogRef<RemoveMemberConfirmationComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData | any,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {}

  confirmationButton(confirmation: string) {
    if (confirmation === "no") {
      this.dialogRef.close({ event: "close", data: "no" });
    } else if (confirmation === "yes") {
      this.fetchRelationMappings();
    } else if (confirmation === "restore") {
    this.sharedDataService.clearOrderDataForUpgrade();
      this.dialogRef.close({ event: "close", data: "restore" });
    } else if (confirmation === "continue") {
      this.dialogRef.close({ event: "close",   data: {
        status: "continue",
        customer_dob : this.proposerData.customer.dob,
        customer_pincode : this.proposerData.address[0]?.pincode
      } });
       
    }
  }

  fetchRelationMappings(): void {
    this.apiService
      .getRequestedResponse(`${ApiConstants.GET_LIST_OF_INSURED_MEMBER}`)
      .subscribe((relationsResponse: any) => {
        if (relationsResponse?.relations?.length > 0) {
          this.relationsMap = relationsResponse.relations.reduce(
            (map: any, relation: any) => {
              map[relation.relation_id] = relation.relation_name;
              return map;
            },
            {}
          );
          this.getOrderData(this.data?.order_id);
        }
      });
  }

  getOrderData(orderId: any) {
    this.sharedDataService.getOrderApi2(orderId);
    this.sharedDataService.getOrderApiRes2.subscribe((res: any) => {
      if (res) {
        this.proposerData = res;
        this.processOrderData();
       this.showPopup = "selectMemberToRemove";
      }
    });
  }

  processOrderData() {
    this.membersArray = [];

    if ( this.proposerData?.customer && (this.data.familySize === "1,0" || this.data.familySize === "2,0") ) {
      const customer = this.proposerData.customer;
      this.membersArray.push({
        id: customer.id,
        name: customer.name,
        age: this.dateToAgePipe.transform(customer.dob),
        gender: this.getGender(customer.gender),
        relation: customer.relation,
        listName : 'customer'
      });
    }

    let includedMembers: any[] = [];

    includedMembers = this.proposerData.included_members
    .map((member: any) => ({
      id: member.id,
      name: member.name,
      age: this.dateToAgePipe.transform(member.dob),
      gender: this.getGender(member.gender),
      relation: this.relationsMap[member.relation],
      listName : 'included_members'
    }))
    .filter((member) => this.data.familySize === "2,1" ? member.relation !== "Spouse" : member.relation === "Spouse");
  

    includedMembers.sort((a, b) => b.age - a.age);

    this.membersArray = [...this.membersArray, ...includedMembers];

  }

  activeMembersData() {
    this.activeMembers = [];
    const data = this.sharedDataService.getOrderDataForUpgrade();


    // Store customer details if available
    if (data.customer) {
      const customer = data.customer;
      this.activeMembers.push({
        id: customer.id,
        name: customer.name,
        age: this.dateToAgePipe.transform(customer.dob),
        gender: this.getGender(customer.gender),
        relation: customer.relation,
        listName : 'customer'
      });
    }
    
    let includedMembers: any[] = [];

    includedMembers = data.included_members
    .map((member: any) => ({
      id: member.id,
      name: member.name,
      age: this.dateToAgePipe.transform(member.dob),
      gender: this.getGender(member.gender),
      relation: this.relationsMap[member.relation],
      listName : 'included_members'
    }))

    this.activeMembers = [...this.activeMembers, ...includedMembers];
  }

  // Function to convert gender number to string
  getGender(gender: number): string {
    return gender === 1 ? 'Male' : 'Female';
  }


  member:any
toggleMemberSelection(memberId: number,member:any) {

  this.selectedMemberIds = this.selectedMemberIds === memberId ? null : memberId;
  this.member = member
}

removeSelectedMember() {

  if (this.selectedMemberIds !== null) {
    if (this.member.listName === "customer") {

      // Find Spouse in included_members
      const spouseIndex = this.proposerData.included_members.findIndex(
        (member: any) => this.relationsMap[member.relation] === "Spouse"
      );

      if (this.proposerData.customer.relation === "Self") {
        const spouseData = this.proposerData.included_members.splice(spouseIndex, 1)[0];
        const proposer =  this.proposerData.customer.proposer;
        const existingAddress = this.proposerData.customer.address?.[0] || {};

        const customerPatchValue = {
          dob:  spouseData?.dob,
          email:  spouseData?.email,
          gender:  spouseData?.gender,
          height_feet:  spouseData?.height_feet,
          height_inch:  spouseData?.height_inch,
          mobile_no:  spouseData?.mobile_no,
          name:  spouseData?.name,
          weight:  spouseData?.weight
        }

        const proposerPatchValue: any = {
          address_line1: spouseData?.address_line1,
          dob:  spouseData?.dob,
          email:  spouseData?.email,
          gender:  spouseData?.gender,
          height_feet:  spouseData?.height_feet,
          height_inch:  spouseData?.height_inch,
          mobile_no:  spouseData?.mobile_no,
          name:  spouseData?.name,
          weight:  spouseData?.weight,
          city: spouseData.city  ,
          pincode: spouseData.pincode  ,
          state: spouseData.state  ,
        };

        this.proposerData.address = [ { address_line1: spouseData.address_line1  , city: spouseData.city  , pincode: spouseData.pincode , state: spouseData.state , }, ];
        this.proposerData.customer = customerPatchValue;
        this.proposerData.customer.proposer = proposerPatchValue;
        this.proposerData.included_members = []


      } else {
        const spouseData = this.proposerData.included_members.splice(spouseIndex, 1)[0];
        const proposer =  this.proposerData.customer.proposer;
        const existingAddress = this.proposerData.customer.address?.[0] || {};

        const customerPatchValue = {
          dob:  spouseData?.dob,
          email:  spouseData?.email,
          gender:  spouseData?.gender,
          height_feet:  spouseData?.height_feet,
          height_inch:  spouseData?.height_inch,
          mobile_no:  spouseData?.mobile_no,
          name:  spouseData?.name,
          weight:  spouseData?.weight
        }

        this.proposerData.customer = customerPatchValue;
        this.proposerData.address = [ { address_line1: spouseData.address_line1  , city: spouseData.city  , pincode: spouseData.pincode , state: spouseData.state , }, ];
        this.proposerData.customer.proposer = proposer;

          this.proposerData.included_members = []
      }
    } else {
      this.proposerData.included_members = this.proposerData.included_members.filter(
        (member: any) => member.id !== this.selectedMemberIds
      );
      if(this.data.familySize === '1,0') {
        this.proposerData.included_members = []
      }
    }
    this.sharedDataService.storeOrderDataForUpgrade(this.proposerData);
    this.showPopup = "finalConfirmation";
    this.selectedMemberIds = null;
    this.activeMembersData();
  }
}

}
