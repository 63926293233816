<div id="bimapay-ten-month-pop">
    <div class="text-position row">
        <div class="col-12">
            <img  src="../../../assets/images/magma-logo/magma1.png" width="220" height="80" alt="cancel">
        </div>
    </div>

    <div class="text-position row ">
        <!-- <div class="col-12"> -->
           <p class="content"> Currently, 10 months <span class="span-text">EZPay</span> is exclusively available for <span class="span-text">RB Health MAGMA</span> </p>
        <!-- </div> -->
    </div>

    <div class="text-position row ">
        <div class="col-12">
            <button class="continue" (click)="closeDialog()" >Continue</button>
        </div>
    </div>

</div>