import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SetHeaderService {

    httpHeaders: any;
    productConfig: any = environment['productModuleName'];
    finsureApiKey:any=environment['FinsureApiKey']
    constructor() { }

    gettoken() {
        return !!localStorage.getItem('token');
    }

    // getHeaders(product: string, isDoc: boolean, isToken: boolean) {
    //     const token = localStorage.getItem('token');
    //     return !!localStorage.getItem('rb_token');
    // }

    getHeaders(product: string, isDoc: boolean, isToken: boolean,isDashboardAuth:boolean,isrefresh_token:boolean) {
        const token = localStorage.getItem('token');
        const dashboardAuth=localStorage.getItem('dashboardAuth');
        const refresh_token=localStorage.getItem('refresh_token');

        {
            /**
             * product type will get from http headers
             * As this has to be maintain at UI end
             * So that product headers can be configurable according to prodct need
             * Thus it will be avoidabel to maintain at http end
             */
            // const token = localStorage.getItem('token');

            this.httpHeaders = {
                headers: new HttpHeaders(
                    this.productConfig[product],
                )
            };

        }
        if (isToken) {
            this.httpHeaders.headers = this.httpHeaders.headers.append('Authorization', `Token ${token}`);
            this.httpHeaders.headers = this.httpHeaders.headers.append('FINSURE-Api-Key', `${this.finsureApiKey}`);

        }
        if(isDashboardAuth){
            this.httpHeaders.headers = this.httpHeaders.headers.append('Authorization', `DashboardAuth ${dashboardAuth}`);
            this.httpHeaders.headers = this.httpHeaders.headers.append('FINSURE-Api-Key', `${this.finsureApiKey}`);


        }
        if(isrefresh_token){
            this.httpHeaders.headers = this.httpHeaders.headers.append('Authorization',`${refresh_token}`);
            this.httpHeaders.headers = this.httpHeaders.headers.append('FINSURE-Api-Key', `${this.finsureApiKey}`);

        }
        if (!isDoc) {
            this.httpHeaders.headers = this.httpHeaders.headers.append('Content-Type', 'application/json');
            this.httpHeaders.headers = this.httpHeaders.headers.append('FINSURE-Api-Key', `${this.finsureApiKey}`);

        }
        if (isDoc) {
            this.httpHeaders.headers = this.httpHeaders.headers.append('Accept', 'application/json');
            this.httpHeaders.headers = this.httpHeaders.headers.append('FINSURE-Api-Key', `${this.finsureApiKey}`);

        }
        return this.httpHeaders;

    }
}
