<div class="dialog-content-box share-proposal">
  <div (click)="closeDialog()" class="close-popup">&#x2715;</div>
  <div class="row">
 
      <div class="popup-heading">
        Hi, Please choose the way you wish to share the quotes
      </div>
    
  </div>

  <form [formGroup]="customerForm">
    <div class="row">
      <div class="col-sm-9 col-8">
        <mat-form-field class="custom-mat-form-field" appearance="outline">
          <div class="input-with-icon">
            <span class="img-pad">
              <img src="/assets/images/health-v2/icons/email.svg" />
            </span>
            <input matInput placeholder="Email" formControlName="email"  />
          </div>
        
          <mat-error *ngIf="customerForm.get('email')?.hasError('required')"> This is a required field </mat-error>
          <mat-error *ngIf=" customerForm.get('email')?.hasError('pattern') ">Please enter a valid email</mat-error>

        </mat-form-field>
      </div>

      <div class="col-sm-3 col-4">
        <div class="share-btn" *ngIf="!emailStatus" (click)="shareWithEmail()" >Share</div>
        <div class="sent-btn" *ngIf="emailStatus" ><img src="/assets/images/health-v2/icons/tick.svg">&nbsp;Sent!</div>

      </div>
    </div>

    <div class="row">
      <div class="col-sm-9 col-8">
        <mat-form-field class="custom-mat-form-field" appearance="outline">
          <div class="input-with-icon">
            <span class="img-pad">
              <img src="/assets/images/health-v2/icons/whatsup.svg" />
            </span>
            <input matInput placeholder="Whatsapp" formControlName="whatsapp"  minlength="10" maxlength="10" />
          </div>
          <mat-error *ngIf="customerForm.get('whatsapp')?.hasError('required')"> This is a required field </mat-error>
          <mat-error *ngIf="customerForm.get('whatsapp')?.hasError('pattern')">
            Please enter a valid 10-digit whatsapp number
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-sm-3 col-4">
        <div class="share-btn" *ngIf="!whatsappStatus" (click)="shareWithWhatsapp()">Share</div>
        <div class="sent-btn" *ngIf="whatsappStatus" ><img src="/assets/images/health-v2/icons/tick.svg">&nbsp;Sent!</div>
      </div>

    </div>

    <!-- <div class="row">
      <div class="col-sm-9">
        <mat-form-field class="custom-mat-form-field" appearance="outline">
          <div class="input-with-icon">
            <span class="img-pad">
              <img src="/assets/images/health-v2/icons/mobile.svg" />
            </span>
            <input
              matInput
              placeholder="Mobile Number"
              formControlName="Mobile"
              minlength="10"
              maxlength="10"
            />
          </div>
          <mat-error *ngIf="customerForm.get('Mobile')?.hasError('required')">
            Mobile number is required
          </mat-error>
          <mat-error *ngIf="customerForm.get('Mobile')?.hasError('pattern')">
            Please enter a valid 10-digit mobile number
          </mat-error>
        </mat-form-field>
      </div>
  
      <div class="col-sm-3">
        <div class="share-btn">Share</div>
      </div>
    </div> -->

    <div class="row">
     
        <div class="note">
          *Please note that the premium may vary in future.
        </div>
    
    </div>

    <!-- <button type="submit" [disabled]="customerForm.invalid">Submit</button> -->
  </form>
</div>
