import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  Optional,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { DialogData } from "src/app/model/data.model";
import { ApiService } from "src/app/services/api.service";
import { SharedDataService } from "../../services/shared-data.service";

@Component({
  selector: "app-previous-policy-alert",
  templateUrl: "./previous-policy-alert.component.html",
  styleUrls: ["./previous-policy-alert.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[style.display]": '"flex"',
    "[style.flex-direction]": '"column"',
    "[style.overflow]": '"auto"',
  },
})
export class PreviousPolicyAlertComponent implements OnInit {
  mobileForm!: FormGroup;
  mobileContainer: boolean = true;
  OTPContainer: boolean = false;
  time = 90;
  otpNumber: any;
  submitOtp: boolean = false;
  validOtp: boolean = false;
  errorMessage: any;
  planData: any;
  policy_number: any;
  plan_type: any;
  renewal_type: any;
  healthPlan: any;
  newPlanData: any;
  @ViewChild("ngOtpInput", { static: false }) ngOtpInput: any;
  config = { allowNumbersOnly: true, inputClass: { borderradius: "25px" } };

  constructor(
    private elRef: ElementRef,
    private dialogRef: MatDialogRef<PreviousPolicyAlertComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData | any,
    private apiService: ApiService,
    private sharedDataService: SharedDataService
  ) {
    dialogRef.disableClose = true;
    let policy_no = localStorage.getItem("policy");
    const newUrl = "/api/v2/renewal_plans/?policy_no=" + policy_no;
    this.apiService.getRequestedResponse(newUrl).subscribe((res: any) => {
      for (const key in res) {
        this.planData = res[key];
      }
    });
    this.sharedDataService.getRetrievePlan(data["plan_id"]);
    this.sharedDataService.retrievePlan.subscribe((res) => {
      this.newPlanData = res;
    });
  }

  closeDialog() {
    this.dialogRef.close({ event: "close" });
  }

  ngOnInit(): void {}

  closePopup() {
    this.dialogRef.close({ navigator: true, event: "close" });
  }
  yesProcced() {
    this.dialogRef.disableClose = false;
    this.dialogRef.close({
      existOrderId: this.data["existOrderId"],
      plan_type: this.data["planType"],
      navigator: false,
      event: "close",
    });
  }
}
