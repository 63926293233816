<div class="dialog-content-box">
   
        <div class="row">
    <div clas="col-12" >
        <div class="popup-height">
        <form>
        <!-- <mat-radio-group  >

            <mat-radio-button class="custom-radio-button-clr col-3"
                [value]="details" *ngFor="let item of nmaritalstatus" 
                (change)="radioChange($event)">
                <span class="content-align">{{details}}</span>

            </mat-radio-button>

        </mat-radio-group> -->
<input type="radio" id="Son" name="relation" value="Son">
<label for="Son">&nbsp; Son</label><br>
<hr/> 
<input type="radio" id="Daughter" name="relation" value="Daughter">
<label for="Daughter">&nbsp; Daughter</label><br>
<hr/> 
<input type="radio" id="GrandFather" name="relation" value="GrandFather">
<label for="GrandFather">&nbsp; Grand Father</label><br>
<hr/> 
<input type="radio" id="GrandMother" name="relation" value="GrandMother">
<label for="GrandMother">&nbsp; Grand Mother</label><br>
<hr/> 
<input type="radio" id="Daughter" name="relation" value="Daughter">
<label for="Daughter">&nbsp; Daughter</label><br>
<hr/> 
<input type="radio" id="Mother" name="relation" value="Mother">
<label for="Mother">&nbsp; Mother</label><br>
<hr/>
<input type="radio" id="Father" name="relation" value="Father">
<label for="Father">&nbsp; Father</label><br>
<hr/>
<input type="radio" id="Wife" name="relation" value="Wife">
<label for="Wife">&nbsp; Wife</label><br>
<hr/>
<input type="radio" id="Husband" name="relation" value="Husband">
<label for="Husband">&nbsp; Husband</label><br>
<hr/>
<input type="radio" id="Sister" name="relation" value="Sister">
<label for="Sister">&nbsp; Sister</label><br>
<hr/>
<input type="radio" id="Brother" name="relation" value="Brotther">
<label for="Brother">&nbsp; Brother</label>
<br/>
    </form>
    </div>
    </div>
    </div>
    
</div>
