<div id="footer">
  <div class="footer-border">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <div class="copyright">© 2023 RenewBuy All Rights Reserved</div>
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="footer-flex">
            <a [routerLink]="['/privacy-policy']" class="ankel-tag"
              ><div class="link-text">Privacy Policy &nbsp; |</div></a
            >
            <a [routerLink]="['/terms-and-conditions/po']" class="ankel-tag"
              ><div class="link-text">Terms & Conditions &nbsp; |</div></a
            >
            <a [routerLink]="['/disclaimer']" class="ankel-tag">
              <div class="link-text">Disclaimer &nbsp; |</div></a
            >
            <a [routerLink]="['/disclosure']"
              ><div class="link-text">Company Disclosure</div></a
            >
            <!-- <a href="/assets/images/customer-journey/RB-Disclouser.pdf"><div class="link-text">Company Disclosure</div></a> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="address-text">
          RB Info Services Private Limited (CIN U74999HR2021PTC098232),
          Principal Place of Business: RenewBuy Building 3rd Floor, Plot No.-94,
          Sec-32, Gurugram, Haryana-122001.
        </div>
      </div>
    </div>
  </div>
</div>
