<header class="mainHeader" *ngIf="!source">
  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid px-4">
      <a class="navbar-brand">
        <img
          class="img-fluid"
          src="/assets/images/health-v2/icons/rblogo-new.png"
        />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        (click)="navbarCollapsed = !navbarCollapsed"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="collapse navbar-collapse justify-content-center"
        [class.collapse]="navbarCollapsed"
      >
        <ul class="navbar-nav">
          <!-- <li class="nav-item">
            <div class="nav-link" (click)="redirectionToHealth()">
              RenewBuy Health
            </div>
          </li>
          <li class="nav-item">
            <div class="nav-link" (click)="redirectionToLife()">
              RenewBuy Life
            </div>
          </li> -->
          <!-- <li class="nav-item">
              <a class="nav-link" href="">Insurance
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="">Contact Us
              </a>
            </li> -->
        </ul>
      </div>

      <!-- <div class="collapse navbar-collapse justify-content-end" [class.collapse]="navbarCollapsed">
          <ul class="navbar-nav">
  
            <li class="nav-item login-btn">
              <a class="nav-link" href="">Login
              </a>
            </li>
          </ul>
        </div> -->
    </div>
  </nav>
</header>
