import { Directive, HostListener, ElementRef, Renderer2 } from '@angular/core';

@Directive({
    selector: '[removeWhiteSpace]'
})
export class RemoveWhiteSpaceDirective {

    constructor(
        private renderer: Renderer2,
        private elementRef: ElementRef
    ) { }

    @HostListener('blur')
    onBlur() {
        let value = this.elementRef.nativeElement.value;

        if (value) {
            value = value.replace(/\s/g, "");
            this.renderer.setProperty(
                this.elementRef.nativeElement, 'value', value);
            this.renderer.setAttribute(
                this.elementRef.nativeElement, 'value', value);
            // this.ngModel.update.emit(value);
        } else {
            this.renderer.setProperty(
                this.elementRef.nativeElement, 'value', null);
            this.renderer.setAttribute(
                this.elementRef.nativeElement, 'value', '');
            // this.ngModel.update.emit('');
        }
    }


}
