<ng-container *ngIf="data?.tenx_topup_step === 'default' ">
    <div id="magmaTopup">

        <div class="row">
            <div class="col-12">
                <img class="close-pop" (click)="closeDialog('default')" src="assets/images/magma-logo/Vector.png"
                    alt="cancel">
            </div>
        </div>

        <div class="popup-container">
            <p><span class="topup-heading">Boost your plan coverage with 10X Super Top-up </span></p>
            <p class="topup-cover"> Your Revised Cover will be ₹{{revisedCoveredPremium | tenXTopAmount }} - <span>₹
                    {{data?.health_card?.sum_insured | tenXTopAmount }} Base + ₹ {{calculatedSumInsured | tenXTopAmount
                    }} Super Top-up</span> </p>
        </div>

        <div class="row button-row">
            <div class="topup-btn-position col-6">
                <button mat-raised-button class="addtopup" (click)="addTopUp()"> Add Super Top-up </button>
            </div>
            <div class="col-6">
                <button class="continue" (click)="continueWithoutTopUp()"> Continue with 10 Lac Cover </button>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="data?.tenx_topup_step === 'select' ">
    <div id="magmaTopup">
        <div class="row">
            <div class="col-12">
                <img class="close-pop" (click)="closeDialog('select')" src="assets/images/magma-logo/Vector.png"
                    alt="cancel">
            </div>
        </div>

        <div class="popup-container">
            <p><span class="continue-topup-heading">10X Super Top-up <span>has been added</span> </span></p>
            <p class="continue-topup-cover"> Your Revised Cover is ₹{{revisedCoveredPremium | tenXTopAmount }} - <span>₹
                    {{data?.health_card?.sum_insured | tenXTopAmount }} Base + ₹{{calculatedSumInsured | tenXTopAmount
                    }} Top Up</span> </p>
            <p class="continue-topup-text"><span class="revised-amount"> Revised Plan Amount - <span
                        class="revised-amt"> ₹{{data?.health_card?.premium}}</span> </span></p>
        </div>

        <div class="row">
            <div class="col-12 contine-btn-position">
                <button mat-raised-button class="select-topup" (click)="continue()">Continue</button>
            </div>
        </div>


    </div>

</ng-container>

<ng-container *ngIf="data?.tenx_topup_step === 'remove' ">
    <div id="magmaTopup">

        <div class="row">
            <div class="col-12">
                <img class="close-pop" (click)="closeDialog('remove')" src="assets/images/magma-logo/Vector.png"
                    alt="cancel">
            </div>
        </div>

        <div class="popup-container">
            <p class="remove-topup-cover"> Are you sure you want to opt out of <span>Super Top-up?</span> </p>
            <p class="continue-topup-text"><span class="revised-amount"> Revised Plan Amount - <span
                        class="revised-amt"> ₹{{data?.health_card?.premium}}</span> </span></p>

        </div>

        <div class="row">
            <div class="topup-btn-position col-6">
                <button mat-raised-button class="removeTopUp" (click)="removeTopUp()"> Yes, Opt Out </button>
            </div>
            <div class="col-6">
                <button mat-raised-button class="keeptopup" (click)="keepTopUp()">No, Keep It </button>
            </div>
        </div>
    </div>
</ng-container>