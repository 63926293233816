import { Component, Inject, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../../model/data.model';
import { FormBuilder } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { ApiConstants } from 'src/app/api.constant';
import { DateToAgePipe } from 'src/app/pipes/date-to-age.pipe';
@Component({
  selector: "app-remove-member-confirmation",
  templateUrl: "./remove-member-confirmation.component.html",
  styleUrls: ["./remove-member-confirmation.component.scss"],
})
export class RemoveMemberConfirmationComponent implements OnInit {
  showPopup: string = "wantToRemoveMember";
  proposerData: any;
  relationsMap: { [key: number]: string } = {};
  membersArray: any[] = []; 
  selectedMemberIds: number | null = null;

  constructor(
    private apiService: ApiService,
    private formBuild: FormBuilder,
    private dateToAgePipe: DateToAgePipe,
    private sharedDataService: SharedDataService,
    private dialogRef: MatDialogRef<RemoveMemberConfirmationComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData | any,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (this.showPopup === "wantToRemoveMember") {
      dialogRef.disableClose = false;
    } else {
      dialogRef.disableClose = true;
    }
  }

  ngOnInit(): void {}

  confirmationButton(confirmation: string) {
    if (confirmation === "no") {
      this.dialogRef.close({ event: "close" });
    } else if (confirmation === "yes") {
      this.showPopup = "selectMemberToRemove";
      this.fetchRelationMappings();
    } else if (confirmation === "restore") {
      this.sharedDataService.clearOrderDataForUpgrade()
    } else if (confirmation === "continue") {
      if (this.selectedMemberIds !== null) {
        this.proposerData.included_members = this.proposerData.included_members.filter(
          (member: any) => member.id !== this.selectedMemberIds
        );
        this.sharedDataService.storeOrderDataForUpgrade(this.proposerData);
    
        console.log("Updated proposerData saved:", this.proposerData);
        this.selectedMemberIds = null;
        this.dialogRef.close({ event: "close" , data: 'continue' });
      }

    } 
  }

  fetchRelationMappings(): void {
    this.apiService
      .getRequestedResponse(`${ApiConstants.GET_LIST_OF_INSURED_MEMBER}`)
      .subscribe((relationsResponse: any) => {
        if (relationsResponse?.relations?.length > 0) {
          this.relationsMap = relationsResponse.relations.reduce(
            (map: any, relation: any) => {
              map[relation.relation_id] = relation.relation_name;
              return map;
            },
            {}
          );
          this.getOrderData(this.data?.order_id);
        }
      });
  }

  getOrderData(orderId: any) {
    this.sharedDataService.getOrderApi2(orderId);
    this.sharedDataService.getOrderApiRes2.subscribe((res: any) => {
      if (res) {
        this.proposerData = res;
        console.log("Order Data:", this.proposerData);
        this.processOrderData();
      }
    });
  }

  processOrderData() {
    this.membersArray = [];

    if (
      this.proposerData?.customer &&
      (this.data.familySize === "1,0" || this.data.familySize === "2,0")
    ) {
      const customer = this.proposerData.customer;
      this.membersArray.push({
        id: customer.id,
        name: customer.name,
        age: this.dateToAgePipe.transform(customer.dob),
        gender: this.getGender(customer.gender),
        relation: customer.relation,
      });
    }

    let includedMembers: any[] = [];

    includedMembers = this.proposerData.included_members
    .map((member: any) => ({
      id: member.id,
      name: member.name,
      age: this.dateToAgePipe.transform(member.dob),
      gender: this.getGender(member.gender),
      relation: this.relationsMap[member.relation],
    }))
    .filter((member) => this.data.familySize === "2,1" ? member.relation !== "Spouse" : member.relation === "Spouse");
  

    includedMembers.sort((a, b) => b.age - a.age);

    this.membersArray = [...this.membersArray, ...includedMembers];

    // console.log( "Final Sorted Members:", this.membersArray.length, this.membersArray );
  }

  // 🔄 Function to convert gender number to string
  getGender(gender: number): string {
    return gender === 1 ? 'Male' : 'Female';
  }


  member:any
toggleMemberSelection(memberId: number,member:any) {

  this.selectedMemberIds = this.selectedMemberIds === memberId ? null : memberId;
  this.member = member
}

removeSelectedMember() {
  this.showPopup = "finalConfirmation";
}

}
