import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'shimmer-quotes-ui',
  templateUrl: './shimmer-quotes-ui.component.html',
  styleUrls: ['./shimmer-quotes-ui.component.scss']
})
export class ShimmerQuotesUiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
