import { Component, Inject, OnInit, Optional } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogData } from "src/app/model/data.model";
import { SharedDataService } from "src/app/services/shared-data.service";
import { ApiConstants } from "src/app/api.constant";
import { ApiService } from "src/app/services/api.service";

@Component({
  selector: 'app-bimapay-ten-month-pop',
  templateUrl: './bimapay-ten-month-pop.component.html',
  styleUrls: ['./bimapay-ten-month-pop.component.scss']
})
export class BimapayTenMonthPopComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<BimapayTenMonthPopComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData | any,
    private apiService: ApiService,
    private sharedDataService: SharedDataService
  ) { 
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
  }

  /** Closes the dialog and sends a close event with a predefined payload. **/
closeDialog() {
  this.dialogRef.close({ event: "close" });
}

}
