<section class="payment-section">
    <div class="container px-4">
        <div class="row text-center justify-content-center my-4">
            <div class="col-12 image-sec">
                <img src="assets/images/sucess.png">
            </div>
            <div class="col-8 thankyou-msg my-4">
                Thank You for Registering as Retirement Solution Expert !!
            </div>



        </div>


        <div class="row justify-content-center">
            <div class="col-6 download-link">
                <button class="submit-btn" mat-button type="submit"
                    routerLink="/pay">Return to Home
                </button>
            </div>
        </div>

    </div>
</section>
