import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { MaterialModule } from "../material/material.module";
import { RouterModule } from "@angular/router";
import { HeaderComponent } from "../../component/header/header.component";
import { FooterComponent } from "../../component/footer/footer.component";
import { ErrorHandlerComponent } from "../../modal-component/error-handler/error-handler.component";
import { LoaderComponent } from "../../component/loader/loader.component";
import { CarouselModule } from "ngx-owl-carousel-o";
import { OnlyNumbersDirective } from "../../directives/only-numbers.directive";
import { DateToAgePipe } from "../../pipes/date-to-age.pipe";
import { MaskingPipe } from "../../pipes/masking.pipe";
import { OtpComponent } from "../../modal-component/otp/otp.component";
import { ErrorMessageComponent } from "../../modal-component/error-message/error-message.component";
import { PaymentLoaderComponent } from "src/app/component/payment-loader/payment-loader.component";
import { RemoveWhiteSpaceDirective } from "../../directives/remove-white-space.directive";
import { DateFormatDirective } from "../../directives/date-format.directive";
import { PlanCardComponent } from "src/app/component/plan-card/plan-card.component";
import { AmountFormatPipe } from "src/app/pipes/amount-format.pipe";
import { ExtractPlanNamePipe } from "src/app/pipes/extract-plan-name.pipe";
import { PlanNotAvailableComponent } from "src/app/modal-component/plan-not-available/plan-not-available.component";
import { QuotesListComponent } from "src/app/component/quotes-list/quotes-list.component";
import { TopFeaturesComponent } from "src/app/component/top-features/top-features.component";
import { NgOtpInputModule } from "ng-otp-input";
import { NgxCaptureModule } from "ngx-capture";
import { ShareLinkWithCustomerComponent } from "src/app/modal-component/share-link-with-customer/share-link-with-customer.component";
import { AgeChangeComponent } from "src/app/modal-component/age-change/age-change.component";
import { CustomerHeaderComponent } from "src/app/component/customer-header/customer-header.component";
import { CustomerFooterComponent } from "src/app/component/customer-footer/customer-footer.component";
import { ShimmerQuotesUiComponent } from "src/app/component/shimmer-quotes-ui/shimmer-quotes-ui.component";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    RouterModule,
    CarouselModule,
    NgOtpInputModule,
    NgxCaptureModule,
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    ErrorHandlerComponent,
    LoaderComponent,
    OnlyNumbersDirective,
    DateToAgePipe,
    ExtractPlanNamePipe,
    MaskingPipe,
    OtpComponent,
    ErrorMessageComponent,
    PaymentLoaderComponent,
    RemoveWhiteSpaceDirective,
    DateFormatDirective,
    PlanNotAvailableComponent,
    PlanCardComponent,
    QuotesListComponent,
    ShimmerQuotesUiComponent,
    TopFeaturesComponent,
    ShareLinkWithCustomerComponent,
    AgeChangeComponent,
    CustomerHeaderComponent,
    CustomerFooterComponent,
  ],

  entryComponents: [
    HeaderComponent,
    FooterComponent,
    ErrorHandlerComponent,
    OtpComponent,
    ErrorMessageComponent,
    PaymentLoaderComponent,
  ],

  providers: [
    DatePipe,
    DateToAgePipe,
    ExtractPlanNamePipe,
    MaskingPipe,
    AmountFormatPipe,
  ],

  exports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    HeaderComponent,
    FooterComponent,
    ErrorHandlerComponent,
    LoaderComponent,
    CarouselModule,
    OnlyNumbersDirective,
    DateToAgePipe,
    ExtractPlanNamePipe,
    PaymentLoaderComponent,
    MaskingPipe,
    // AmountFormatPipe,
    RemoveWhiteSpaceDirective,
    DateFormatDirective,
    PlanCardComponent,
    QuotesListComponent,
    ShimmerQuotesUiComponent,
    TopFeaturesComponent,
    CustomerHeaderComponent,
    CustomerFooterComponent,
  ],
})
export class SharedComponentModule {}
