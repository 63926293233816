<div id="home-page">
    <div class="container-fluid agent">
        <div  class="row ">
           <div class="col-12 d-flex justify-content-end">
            <img  src="/assets/images/cross.svg" alt="Your Image" class="cross-image" (click)="returnToHome()">
           </div>
            <div class="col-12 text-center mt-4">
                <img  src="/assets/images/warning.svg" alt="Your Image" class="centered-image">
              </div>

            <div class="col-sm-12 col-sm-pull-4 mt-4">
                <p class="content-text">Oops! It appears there's an issue. </p>
                <p class="content-text">Please reach out to your RM for assistance.</p>
             </div>        
        </div>
    </div>
</div>
