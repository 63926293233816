<!-- <div class="close-dialog-icon">
    <mat-icon class="cursor-pointer" matSuffix (click)="closeDialog()">close</mat-icon>
</div> -->

<div class="dialog-content-box text-center">
    <div>Hi,please choose the way you wish to share the quotes</div>
    <form [formGroup]="shareInfo" (ngSubmit)="onSubmit()">

        <div class="row justify-content-center mt-3">

            <div class="col-8">
                <label for="email" class="lableClass">Email</label>
                 <mat-form-field class="custom-mat-form-field r" appearance="outline" [ngClass]="{'disable':btnDisabled==false}">
                    <!-- <mat-label>Email</mat-label> -->
                    <input class=""  matInput id="email" placeholder="user@gmail.com" formControlName="email">
                    <mat-error *ngIf="shareInfo.get('email')?.hasError('pattern')">
                        Invalid Email
                    </mat-error>
                  </mat-form-field>
                  <label for="phone" class="lableClass">Phone</label>
                <mat-form-field class="custom-mat-form-field" appearance="outline" [ngClass]="{'disable':btnDisabled==false}">
                    <input matInput class="" formControlName="mobile"
                        placeholder="Mobile number" [maxlength]="10"  id="phone" appOnlyNumbers>
                    
                        <mat-error *ngIf="shareInfo.get('mobile')?.hasError('pattern')">
                            Invalid Mobile number
                        </mat-error>
                </mat-form-field>
                    <div class="errormsg" *ngIf="!showError">
                        Please enter valid email or mobile number
                    </div>
                  <button mat-button class="justify-content-center submit-btn btn-submit" [ngClass]="{'disable':btnDisabled==false}">Send</button>
                
                  <div class="msg mt-3" *ngIf="msgshow==false">
                    <p >Sent Successfully!</p>
                  </div>
            </div>
        </div>
    </form>

</div>