import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'masking'
})
export class MaskingPipe implements PipeTransform {

    transform(value: any, ...args: unknown[]): unknown {

        const isDigit = /^[0-9]*$/;

        if (isDigit.test(value)) {
            let val = value.toString();
            const visibleDigits = 2;
            let maskedSection = val.slice(3, -visibleDigits);
            let firstTwoDigit = val.slice(0, visibleDigits)
            let visibleSection = val.slice(-visibleDigits);
            return firstTwoDigit + maskedSection.replace(/./g, 'x') + visibleSection;

        } else {
            let arr = value.split("@");
            let arr1 = arr[1].split('.com');
            let value1 = value.substr(0, 2) + new Array(arr[0].length - 1).fill('*').join('') + "@";
            let value2 = arr1[0].substr(0, 1) + new Array(arr1[0].length - 1).fill('*').join('') + '.com'
            return value1 + value2;
        }

    }

}

