import { Component, Inject, OnInit, Optional } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogData } from "src/app/model/data.model";
import { SharedDataService } from "src/app/services/shared-data.service";
import { ApiConstants } from "src/app/api.constant";
import { ApiService } from "src/app/services/api.service";

@Component({
  selector: "app-magma-topup",
  templateUrl: "./magma-topup.component.html",
  styleUrls: ["./magma-topup.component.scss"],
})
export class MagmaTopupComponent implements OnInit {
  premium: any;
  constructor(
    private dialogRef: MatDialogRef<MagmaTopupComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData | any,
    private apiService: ApiService,
    private sharedDataService: SharedDataService
  ) {
    dialogRef.disableClose = true;
    // console.log("magma top popup dialog", data,data.health_card.premium);
    this.addTenXTopup();
  }

  ngOnInit(): void {}

  /** Closes the dialog and sends a close event with a predefined payload. **/
  closeDialog(step : any) {
    if(step === 'default' ) {
      this.dialogRef.close({ event: "close" ,step: "default"  });

    } else if (step === 'select') {
      this.dialogRef.close({ event: "close" ,step: "select"  });

    } else if (step === 'remove') {
      this.dialogRef.close({ event: "close" ,step : "remove"  });
    }
    
  }

  calculatedSumInsured: number | null = null;
  revisedCoveredPremium: number | null = null;

  addTenXTopup() {
    this.apiService .getRequestedResponse( `${ApiConstants.TOP_UP}plan_id=${this.data?.plan_id}` ) .subscribe((res: any) => {
        if (res) {
          sessionStorage.setItem("tenXTopup", 'true');
          // Calculate the enhanced sum insured
          this.calculatedSumInsured = this.data?.health_card?.sum_insured * res?.multiply;
          this.revisedCoveredPremium = this.calculatedSumInsured + this.data?.health_card?.sum_insured;
          // console.log("Calculated Sum Insured:", this.calculatedSumInsured); // Log the calculated value
        }
      });
  }

  addTopUp() {
    this.dialogRef.close({ event: "close", addTopUp: "addTopUp" });
  }

  continueWithoutTopUp() {
    sessionStorage.removeItem("tenXTopup");
    this.dialogRef.close({
      event: "close",
      continueWithoutTopUp: "continueWithoutTopUp",
    });
  }

  optOut() {
    // Handle opt-out logic here
    sessionStorage.removeItem("tenXTopup");
    this.dialogRef.close({ event: "close", remove_topup: "remove_topup" });
  }

  continue() {
    this.apiService
      .getRequestedResponse(
        `${ApiConstants.TOP_UP}plan_id=${this.data?.plan_id}`
      )
      .subscribe((res: any) => {
        if (res) {
          sessionStorage.setItem("tenXTopup", "true");
          // Calculate the enhanced sum insured
          this.calculatedSumInsured =
            this.data?.health_card?.sum_insured * res?.multiply;
          this.revisedCoveredPremium =
            this.calculatedSumInsured + this.data?.health_card?.sum_insured;
          this.dialogRef.close({ event: "close", continue: "continue" });
        }
      });
  }

  removeTopUp() {
    sessionStorage.removeItem("tenXTopup");
    this.dialogRef.close({ event: "close", remove_top_up: "remove_top_up" });
  }

  keepTopUp() {
    // Handle keeping Maternity Cover logic here
    this.dialogRef.close({ event: "close", keep_top_up: "keep_top_up" });
  }
}
