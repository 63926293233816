<div class="error-design" *ngIf="!isInternalServerErr">
  <img class="error-img" src="assets/images/fail.gif" />
  <mat-dialog-content>
    <div class="error-api" *ngFor="let message of error">
      <div *ngIf="message.msg">
        <span class="title"></span><span class="error"> {{ message.msg }}</span>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Close</button>
  </mat-dialog-actions>
</div>

<div class="error-design" *ngIf="isInternalServerErr">
  <div>Oh Snap!</div>
  <div>We're unable to connect to our server</div>
  <img class="error-img" src="assets/images/server_error_n.png" />
  <!-- <mat-dialog-content>
        <div class="error-api">
            <div>
                Kindly reload the page
            </div>

        </div>
    </mat-dialog-content> -->
  <mat-dialog-actions>
    <button class="mat-raised-button" (click)="reloadPage()">Refresh</button>
  </mat-dialog-actions>
</div>
