<div class="dialog-content-box" id="customermobile">
  <div class="row">
    <div mat-dialog-actions>
      <div mat-button mat-dialog-close class="close-popup">&#x2715;</div>
    </div>

    <div clas="col-12">
      <div class="form-pad">
     <div *ngIf="mobileContainer">
            <form [formGroup]="mobileForm" (ngSubmit)="submitMobilleForm(mobileForm.valid)" novalidate>
                <div class="mobile-no-heading">Please Enter your mobile no.</div>
                <div class="col-md-8 offset-md-2">
                  <div class="form-label asterisk-mandtry">Mobile Number</div>
      
                  <mat-form-field class="custom-mat-form-field" appearance="outline">
                    <input
                      matInput
                      class="text-capitalize"
                      appOnlyNumbers
                      placeholder="Enter Mobile Number"
                      maxlength="10"
                      formControlName="mobile"
                    />
                    <mat-error *ngIf="mobileForm.get('mobile')?.hasError('required')">
                        This is a required field.
                    </mat-error>

                    <mat-error *ngIf="mobileForm.get('mobile')?.hasError('pattern')">
                        Invalid Mobile number
                    </mat-error>
                  </mat-form-field>
      
                  <button
                    class="send-otp-btn my-3"
                    type="submit"
                    mat-button
                    [disabled]="!mobileForm.valid"
                  >
                    Send OTP
                  </button>
                </div>
                <div class="six-digit-otp-text">
                  A 6 digit OTP will be sent via SMS to verify your mobile number!
                </div>      
            </form>
     </div>
        <div *ngIf="OTPContainer">
             <div class="mobile-no-heading">
              <a (click)="entermobile()" class="page-back">
            <mat-icon>keyboard_backspace</mat-icon>
        </a>&nbsp;&nbsp;&nbsp;&nbsp; OTP Verificarion</div>
          <div class="col-md-8 offset-md-2">
            <div class="mobileno-text">
              Enter the Otp received on mobile no
              <span class="bold">+91 {{mobileForm.get('mobile')?.value}}</span
              ><span class="editmobile" (click)="entermobile()">
                &nbsp;<img src="/assets/images/health-v2/icons/edit.png"
              /></span>
            </div>

            <div class="otpno-flex">
              <ng-otp-input
                #ngOtpInput
                (onInputChange)="onOtpChange($event)"
                [config]="{ length: 6 }"
              ></ng-otp-input>
            </div>
            <div class="col-12 error-msg">{{ errorMessage }}</div>
            <div class="resendotp-text">
              Resend OTP in
              <span class="bold">{{ time }} Seconds</span>
            </div>
            <ng-container *ngIf="time == 0">
              <div
                class="resendotp"
                (click)="initateOTP()"
                [class.disabled]="time !== 0"
              >
                RESEND OTP
              </div>
            </ng-container>
            <button
               [disabled]="!submitOtp"
              (click)="submitOtpForm(otpNumber)"
              class="send-otp-btn my-3"
              type="submit"
              mat-button
            >
              Verify OTP
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
