<div class="hospital-details">
  <div (click)="closeDialog()" class="close-popup">&#x2715;</div>
  <div class="hospital-heading">{{ hospitalListData?.hospital_name }}</div>
  <div class="hospital-address">{{ hospitalListData?.address }}</div>

  <hr />
  <div class="text1">
    Your Insurance, Our Expertise:<br />
    We Work with Many Providers to Get You Covered
  </div>
  <!-- <div class="partner-flex">
    <div>
      <img
        [src]="hospitalListData?.logo"
        alt="hospital-image"
        class="partner-img"
      />
    </div>
  </div> -->

  <div class="share-details">
    <div class="hospital-detail-text">
      Please choose the way you wish to share the Hospital Details
    </div>
    <div class="plus" (click)="shareDetails()">
      <span *ngIf="showShare">&plus;</span>
      <span *ngIf="!showShare">&minus;</span>
    </div>
  </div>

  <!-- share hospotal details -->
  <div *ngIf="!showShare" class="share-hospital_details">
    <form [formGroup]="shareInfo">
      <div class="row">
        <div class="col-sm-9 col-9">
          <mat-form-field class="custom-mat-form-field" appearance="outline">
            <div class="input-with-icon">
              <span class="img-pad">
                <img src="/assets/images/health-v2/icons/email.svg" />
              </span>
              <input
                matInput
                placeholder="Email"
                formControlName="email"
                (input)="onEmailInputChange()"
              />
            </div>

            <mat-error *ngIf="shareInfo.get('email')?.hasError('email')">
              Please enter a valid email
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-sm-3 col-3">
          <div
            class="share-btn"
            *ngIf="!emailStatus"
            (click)="shareWithEmail()"
            [ngClass]="{ disabled: !this.shareInfo.get('email')?.valid }"
            [attr.disabled]="!this.shareInfo.get('email')?.valid ? true : null"
          >
            Share
          </div>
          <div class="sent-btn" *ngIf="isSent && emailStatus">
            <img src="/assets/images/health-v2/icons/tick.svg" />&nbsp;Sent!
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-9 col-9">
          <mat-form-field class="custom-mat-form-field" appearance="outline">
            <div class="input-with-icon">
              <span class="img-pad">
                <img src="/assets/images/health-v2/icons/whatsup.svg" />
              </span>
              <input
                matInput
                placeholder="Whatsapp"
                formControlName="whatsapp"
                (input)="onWhatsappInputChange()"
                minlength="10"
                maxlength="10"
              />
            </div>

            <mat-error *ngIf="shareInfo.get('whatsapp')?.hasError('pattern')">
              Please enter a valid 10-digit whatsapp number
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-sm-3 col-3">
          <div
            class="share-btn"
            *ngIf="!whatsappStatus"
            (click)="shareWithWhatsapp()"
            [ngClass]="{ disabled: !this.shareInfo.get('whatsapp')?.valid }"
            [attr.disabled]="
              !this.shareInfo.get('whatsapp')?.valid ? true : null
            "
          >
            Share
          </div>
          <div class="sent-btn" *ngIf="isSent && whatsappStatus">
            <img src="/assets/images/health-v2/icons/tick.svg" />&nbsp;Sent!
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-9 col-9">
          <mat-form-field class="custom-mat-form-field" appearance="outline">
            <div class="input-with-icon">
              <span class="img-pad">
                <img src="/assets/images/health-v2/icons/mobile.svg" />
              </span>
              <input
                matInput
                placeholder="Mobile"
                formControlName="mobile"
                (input)="onWhatsappInputChange()"
                minlength="10"
                maxlength="10"
              />
            </div>
            <mat-error *ngIf="shareInfo.get('mobile')?.hasError('required')">
              Mobile number is required
            </mat-error>
            <mat-error *ngIf="shareInfo.get('mobile')?.hasError('pattern')">
              Please enter a valid 10-digit number
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-sm-3 col-3">
          <div
            class="share-btn"
            *ngIf="!numberStatus"
            (click)="shareWithNumber()"
            [ngClass]="{ disabled: !this.shareInfo.get('mobile')?.valid }"
            [attr.disabled]="!this.shareInfo.get('mobile')?.valid ? true : null"
          >
            Share
          </div>
          <div class="sent-btn" *ngIf="isSent && numberStatus">
            <img src="/assets/images/health-v2/icons/tick.svg" />&nbsp;Sent!
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
