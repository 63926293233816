import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule, LocationStrategy } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SharedModuleModule } from "./modules/shared-module/shared-module.module";
import { RouterModule } from "@angular/router";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { BasicModalComponent } from "./modal-component/basic-modal/basic-modal.component";
import { ShareModalComponent } from "./modal-component/share-modal/share-modal.component";
import { IncorretMobileNumberComponent } from "./modal-component/incorret-mobile-number/incorret-mobile-number.component";
import { ChoosePolicyComponent } from "./modal-component/choose-policy/choose-policy.component";
import { HealthDeclarationPopupComponent } from "./modal-component/health-declaration-popup/health-declaration-popup.component";
import { OtpVerificationPopupComponent } from "./modal-component/otp-verification-popup/otp-verification-popup.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { MaterialModule } from "./modules/material/material.module";
import { HeaderModule } from "./component/header/header.module";
import { FooterModule } from "./component/footer/footer.module";
import { SharedComponentModule } from "./modules/shared-component/shared-component.module";
import { CarouselModule } from "ngx-owl-carousel-o";
import { NgOtpInputModule } from "ng-otp-input";
import { APP_BASE_HREF } from "@angular/common";
import { EnterMobilePopupComponent } from "./modal-component/enter-mobile-popup/enter-mobile-popup.component";
import { CustomHashLocationStrategy } from "./custom-hash-location-strategy";
import { AmountFormatPipe } from "./pipes/amount-format.pipe";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ReplacePipe } from "./pipes/replace.pipe";
import { AdharFormateDirective } from "./directives/adhar-formate.directive";
import { AdharVerificationPopupComponent } from "./modal-component/adhar-verification-popup/adhar-verification-popup.component";
import { PayComponent } from "./retirementsolution-modules/pay/pay.component";
import { PreviousPolicyAlertComponent } from "./modal-component/previous-policy-alert/previous-policy-alert.component";
import { IncreasePlanAlertComponent } from "./modal-component/increase-plan-alert/increase-plan-alert.component";
import { PaymentSuccessComponent } from "./retirementsolution-modules/payment-success/payment-success.component";
import { PaymentFailureComponent } from "./retirementsolution-modules/payment-failure/payment-failure.component";
import { AngularDeviceInformationService } from "angular-device-information";
import { CheckValidAgentComponent } from "./modal-component/check-valid-agent/check-valid-agent.component";
import { HospitalNetworkListComponent } from "./modal-component/hospital-network-list/hospital-network-list.component";
import { CheckValidPincodeComponent } from "./modal-component/check-valid-pincode/check-valid-pincode.component";
import { ShareProposalPopupComponent } from "./modal-component/modal-component-v2/share-proposal-popup/share-proposal-popup.component";
import { PincodeNotServiceableComponent } from "./modal-component/pincode-not-serviceable/pincode-not-serviceable.component";
import { BmiValidationComponent } from "./modal-component/bmi-validation/bmi-validation.component";
import { PriceBreakupPopupComponent } from "./modal-component/modal-component-v2/price-breakup-popup/price-breakup-popup.component";
import { NetworkHospitalsComponent } from "./modules/network-hospitals/network-hospitals.component";
import { HospitalDetailsComponent } from "./modal-component/modal-component-v2/hospital-details/hospital-details.component";
import { EazypayPaymentOptionComponent } from "./modal-component/eazypay-payment-option/eazypay-payment-option.component";
import { MagmaRenewalsPopupComponent } from './modal-component/magma-renewals-popup/magma-renewals-popup.component';
import { MagmaTopupComponent } from './modal-component/magma-topup/magma-topup.component';
import { BimapayTenMonthPopComponent } from './modal-component/bimapay-ten-month-pop/bimapay-ten-month-pop.component';
import { MagmaMaternityPopComponent } from './modal-component/magma-maternity-pop/magma-maternity-pop.component';
import { TenXTopAmountPipe } from './pipes/ten-x-top-amount.pipe';

@NgModule({
  declarations: [
    AppComponent,
    BasicModalComponent,
    ShareModalComponent,
    IncorretMobileNumberComponent,
    ChoosePolicyComponent,
    HealthDeclarationPopupComponent,
    OtpVerificationPopupComponent,
    EnterMobilePopupComponent,
    AmountFormatPipe,
    ReplacePipe,
    AdharFormateDirective,
    PaymentSuccessComponent,
    PaymentFailureComponent,
    PayComponent,
    AdharVerificationPopupComponent,
    PreviousPolicyAlertComponent,
    IncreasePlanAlertComponent,
    PayComponent,
    PaymentSuccessComponent,
    PaymentFailureComponent,
    CheckValidAgentComponent,
    HospitalNetworkListComponent,
    CheckValidPincodeComponent,
    ShareProposalPopupComponent,
    PincodeNotServiceableComponent,
    BmiValidationComponent,
    PriceBreakupPopupComponent,
    NetworkHospitalsComponent,
    HospitalDetailsComponent,
    EazypayPaymentOptionComponent,
    MagmaRenewalsPopupComponent,
    MagmaTopupComponent,
    BimapayTenMonthPopComponent,
    MagmaMaternityPopComponent,
    TenXTopAmountPipe,
  ],
  exports: [MaterialModule, CarouselModule],
  providers: [
    AngularDeviceInformationService,
    { provide: APP_BASE_HREF, useValue: window["_app_base"] || "/" },
    { provide: LocationStrategy, useClass: CustomHashLocationStrategy },
  ],
  bootstrap: [AppComponent],
  imports: [
    CommonModule,
    RouterModule.forRoot(AppRoutingModule, {
      useHash: true,
      scrollPositionRestoration: "enabled",
    }),
    BrowserModule,
    BrowserAnimationsModule,
    SharedModuleModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    HeaderModule,
    FooterModule,
    CarouselModule,
    SharedComponentModule,
    NgOtpInputModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
