import {
  Component,
  Inject,
  OnInit,
  Optional,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ApiConstants } from "src/app/api.constant";
import { DialogData } from "src/app/model/data.model";
import { ApiService } from "src/app/services/api.service";
import { SharedDataService } from "src/app/services/shared-data.service";
import { environment } from "../../../environments/environment";
@Component({
  selector: "app-increase-plan-alert",
  templateUrl: "./increase-plan-alert.component.html",
  styleUrls: ["./increase-plan-alert.component.scss"],
})
export class IncreasePlanAlertComponent implements OnInit {
  errorMessage: any;
  policy_number: any;
  newPlanData: any;
  oldPlanData: any;
  message: any;
  customer_journey: boolean = false;
  @ViewChild("ngOtpInput", { static: false }) ngOtpInput: any;
  config = { allowNumbersOnly: true, inputClass: { borderradius: "25px" } };

  constructor(
    private dialogRef: MatDialogRef<IncreasePlanAlertComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData | any,
    private router: Router,
    private apiService: ApiService,
    private sharedDataService: SharedDataService
  ) {
    dialogRef.disableClose = true;
    this.message = data["planObj"]["message"];
    this.newPlanData = data["planObj"]["new_plan"];
    this.oldPlanData = data["planObj"]["old_plan"];
    this.customer_journey = data["planObj"]["customer_journey"];
  }

  closeDialog() {
    this.dialogRef.close({ event: "close" });
  }

  ngOnInit(): void {}

  closePopup() {
    window.location.href = environment["urlPartnerPortal"];
    // this.dialogRef.close({navigator:true, event: 'close' });
  }
  yesProcced() {
    let url = `${ApiConstants.GET_RENEWAL_ORDER}?policy_no=${this.data["planObj"]["policy_number"]}&renewal_type=renewal&accepted=True`;
    this.apiService
      .postRequestedResponse(url, "", "", true, true)
      .subscribe((res: any) => {
        if (res["changed_plans"] == false) {
          this.sharedDataService.update_encrypted_token(res["order_id"]);
          this.router.navigate(["review", res["plan_id"], res["order_id"]]);
        }
      });
  }
}
