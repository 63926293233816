import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extractPlanName'
})
export class ExtractPlanNamePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return ''; // Return empty string if value is null or undefined

    // Find the index of the first occurrence of " ("
    const index = value.indexOf(' (');

    // If " (" exists, extract the substring before it, else return the original value
    return index !== -1 ? value.substring(0, index) : value;
  }
}
