<ng-container *ngIf="data?.journey === 'maternity-covered' || data?.journey === 'remove-maternity'">
    <div id="removeMaternity">
        <div class="row">
            <div class="proposer-close-row col-12">
              <img src="assets/images/eazypay-images/Vector.png" alt="close" class="close-icon" (click)="closeDialog()">
            </div>
          </div>

        <mat-card class="card-design">
            <div class="dialog-content row">
                <div class="col-sm-4">
                    <img src="assets/images/magma-logo/maternity-pop-icon.svg" alt="Pregnant Woman" class="icon">
                </div>

                <div class="col-sm-8">
                    <ng-container *ngIf="data?.journey === 'maternity-covered'; else addedMaternity">
                        <br>
                        <p class="maternity-cancel">
                            Are you sure you want to opt out of 
                            <span class="font-text">Maternity Cover?</span>
                        </p>
                        <div class="amount">
                            Total Payable Amount - <span>&nbsp; ₹{{premium}}</span>
                        </div>
                    </ng-container>

                    <ng-template #addedMaternity>
                        <div class="shield-img-position" >
                            <img src="assets/images/magma-logo/Vector.svg" >
                        </div>
                        <p class="title">
                            <span class="font-text">Maternity Cover</span> has been added
                        </p>
                        <div class="amount">
                            Revised Plan Amount - <span>&nbsp; ₹{{premium}}</span>
                        </div>
                    </ng-template>
                </div>
            </div>
        </mat-card>

        <div class="row button-row mt-2">
            <ng-container *ngIf="data?.journey === 'maternity-covered'; else singleButton">
                <div class="col-6 contine-btn-position">
                    <button mat-raised-button class="remove-maternity" (click)="optOut()">Yes, Opt Out</button>
                </div>
                <div class="col-6 contine-btn-position">
                    <button mat-raised-button class="keep-maternity" (click)="keepMaternityCover()">No, Keep it</button>
                </div>
            </ng-container>

            <ng-template #singleButton>
                <div class="col-12 contine-btn-position">
                    <button mat-raised-button class="continue" (click)="addMaternity()">Continue</button>
                </div>
            </ng-template>
        </div>
    </div>
</ng-container>
















<!-- <ng-container *ngIf="data?.journey === 'maternity-covered' " >
    <div id="removeMaternity">
        <mat-card style="background: #5384EA0F ; padding: 7px 20px 7px 20px;border-radius: 25px; box-shadow:none">
            <div class="dialog-content row">
                <div class="col-sm-4">
                    <img src="assets/images/magma-logo/maternity-pop-icon.svg" alt="Pregnant Woman" class="icon">
                </div>
    
                <div class="col-sm-8">
                   <br>
                    <p class="maternity-cancel">Are you sure you want to opt out of <span class="font-text">Maternity Cover?</span> </p>
                    <div class="amount">Total Payable Amount  - <span>&nbsp; ₹11,142</span> </div>
                </div>
            </div>
        </mat-card>
    
        <div class="row button-row mt-3">
            <div class="col-6 contine-btn-position">
                <button mat-raised-button class="remove-maternity" (click)="keepMaternityCover()"> Yes, Opt Out </button>
            </div>
            <div class="col-6 contine-btn-position">
                <button mat-raised-button class="keep-maternity" (click)="closeDialog()"> No, Keep it </button>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="data?.journey === 'remove-maternity' " >
    <div id="removeMaternity">
        <mat-card style="background: #5384EA0F ; padding: 7px 20px 7px 20px;border-radius: 25px; box-shadow:none">
            <div class="dialog-content row">
                <div class="col-sm-4">
                    <img src="assets/images/magma-logo/maternity-pop-icon.svg" alt="Pregnant Woman" class="icon">
                </div>
    
                <div class="col-sm-8">
                    <div style=" text-align: center;margin-bottom: 16px ">
                        <img src="assets/images/magma-logo/Vector.svg" style="text-align: center;width: 100%;height: 70px;">
                    </div>
                    <p class="title"><span class="font-text">Maternity Cover</span> has been added</p>
    
                    <div class="amount">Revised Plan Amount - <span>&nbsp; ₹11,142</span> </div>
                </div>
            </div>
        </mat-card>
    
        <div class="row button-row mt-2">
            <div class="col-12 contine-btn-position">
                <button mat-raised-button class="continue" (click)="addMaternity()"> Continue </button>
            </div>
        </div>
    </div>
</ng-container> -->