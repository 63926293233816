import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "tenXTopAmount",
})
export class TenXTopAmountPipe implements PipeTransform {
  transform(input: any): string {
    let val = Math.abs(input); // Ensure the value is positive
    let formattedValue = "";

    if (val >= 10000000) {
      formattedValue = (val / 10000000).toFixed(val % 10000000 === 0 ? 0 : 1) + " Cr";
    } else if (val >= 100000) {
      formattedValue = (val / 100000).toFixed(val % 100000 === 0 ? 0 : 1) + " Lacs";
    } else if (val >= 10000) {
      formattedValue = (val / 10000).toFixed(val % 10000 === 0 ? 0 : 1) + " Thousand";
    } else {
      formattedValue = val.toString(); // For values less than 10,000
    }

    return formattedValue;
  }
}
  