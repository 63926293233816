import { Component, Inject, inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../../model/data.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {ApiService} from '../../services/api.service'
import { timer } from 'rxjs';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-enter-mobile-popup',
  templateUrl: './enter-mobile-popup.component.html',
  styleUrls: ['./enter-mobile-popup.component.scss']
})
export class EnterMobilePopupComponent implements OnInit {
  mobileForm!: FormGroup;
  mobileContainer:boolean = true;
  OTPContainer: boolean = false;
  time = 90;
  otpNumber:any
  submitOtp:boolean=false
  validOtp:boolean=false
  errorMessage:any
  @ViewChild("ngOtpInput", { static: false }) ngOtpInput: any; config = { allowNumbersOnly: true, inputClass:{borderradius:"25px"} }

  constructor(
    private router: Router,
    private sharedDataService: SharedDataService,
    private formBuild:FormBuilder,
    private dialogRef: MatDialogRef<EnterMobilePopupComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData | any,
    private apiService:ApiService,

  ) {
    this.mobileForm = this.formBuild.group({
      mobile:['',[Validators.required, Validators.pattern(new RegExp('^[6-9]{1}[0-9]{9}$'))]]
    })
   }

submitMobilleForm(valid){
   if(valid){
    let url="/api/v2/health-lead/create_otp/"
    let body={"mobile_no":this.mobileForm.get('mobile')?.value}
    this.apiService.postRequestedResponse(url,body).subscribe(res=>{
      this.OTPContainer=true
      this.mobileContainer=false;
    })
    this.timer()
   }
}

   closeDialog() {
    this.time = 90;
    this.dialogRef.close({ event: 'close' });
}

onOtpChange(event){
  this.otpNumber=event
  if(this.otpNumber.length>=6){
   this.submitOtp=true
  }else{
   this.submitOtp=false
  }
}
submitOtpForm(otpnumber){
  if(otpnumber.length<=6){
      const data = {
      otp: Number(otpnumber),
      mobile_no: this.mobileForm.get('mobile')?.value,
      dob:this.data['dob'],
      plan_id:this.data['plan_id'],
      planData:this.data['planData']
      };
    this.verifyOTP(data);
  }
 }
// reset Timer

resetTimer() {
  this.time = 90;
}
verifyOTP(data) {
  let url= '/api/v2/health-lead/create_lead/';
  
    this.apiService.postRequestedResponse(url, data).subscribe((res: any) => {
        if (res && res['status']) {
          
            this.validOtp = true;
              this.dialogRef.close({ event: 'close', data: res,planData:this.data['planData'] });    
          //     let data = {
          //       data: res
          //     }
          //     this.sharedDataService.getLeadsIdData(data.data);
          //     this.router.navigate(["customer-proposer", this.data['plan_id']]);    

        } else if (res['message']) {
            this.errorMessage = res['message'];
        } else {
            this.submitOtp=false;
            this.ngOtpInput.setValue('');
            this.errorMessage = 'Invalid OTP';
        }
    });
}
  ngOnInit(): void {
  
  }

 timer(){
 // timer(0, 1000).subscribe((ellapsedCycles: any) => {
  let interval =setInterval(()=>{
    if (this.time > 0) {
      this.time--;
  }
  },1000)
   setTimeout(() => {
    clearInterval(interval)
   }, 90000);
//});
 }
  entermobile(){
    this.time = 90;
    this.OTPContainer=false;
    this.mobileContainer=true
  }
  showcontainer(){
        this.OTPContainer=true;
      this.mobileContainer=false
  
    
}
initateOTP() {
  this.resetTimer();
  this.timer()
  // this.submitMobilleForm(true)
}
}
