import { Injectable } from "@angular/core";
import { HashLocationStrategy } from "@angular/common";

@Injectable()
export class CustomHashLocationStrategy extends HashLocationStrategy {
  override prepareExternalUrl(internal: string): any {
    if (
      internal == "/terms-and-conditions/po" ||
      internal == "/disclaimer" ||
      internal == "/disclosure" ||
      internal == "/privacy-policy"
    ) {
      return internal;
    }
    return location.pathname.split("/")[1] + "/#" + internal;
  }
}
