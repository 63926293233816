import {
  Component,
  Inject,
  OnInit,
  Optional,
  ViewEncapsulation,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogData } from "src/app/model/data.model";
@Component({
  selector: "app-bmi-validation",
  templateUrl: "./bmi-validation.component.html",
  styleUrls: ["./bmi-validation.component.scss"],
})
export class BmiValidationComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<BmiValidationComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData | any
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {}
  closeDialog() {
    this.dialogRef.close({ event: "close" });
  }
  editDetails() {
    this.dialogRef.close({ event: "close" });
  }
}
