import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from "../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    getAuthStatus() {
      throw new Error('Method not implemented.');
    }

    public loggedIn = new BehaviorSubject<boolean>(false);
    public userExecutiveCode = new BehaviorSubject<string>('');
    public obtainedToken = new BehaviorSubject<string>('');

    constructor() {
        this.logOut = this.logOut.bind(this);
    }

    get isLoggedIn() {
        return this.loggedIn.asObservable();
    }

    getToken(data: any) {
        this.obtainedToken.next(data);
    }

    getUserExecutiveCode(data: any) {
        this.userExecutiveCode.next(data);
    }

    IsLoggedIn(){
        return localStorage.getItem('dashboardAuth')!=null;
      }

    setUser = (user: { token: string; user_type: string; email: string; first_name: string; tier: string; registration_date: string; username: string;}) => {

        if (user && user.token) {
            localStorage.setItem('token', user.token);
            localStorage.setItem('ta_user_type', user.user_type);
            localStorage.setItem('ta_user_email', user.email);
            localStorage.setItem('ta_user_name', user.first_name);
            localStorage.setItem('tier', user.tier);
            localStorage.setItem('registration_date', user.registration_date);
            localStorage.setItem('code', user.username);
            this.loggedIn.next(true);
            this.getToken(user.token);
            this.getUserExecutiveCode(user.username);
        } else {
            this.logOut();
        }
    }


    /**
     * We don't required logout function
     * as currenlty we are not checcking for this
     * just to clear out value
     */
    logOut() {
        localStorage.removeItem('ta_token');
        localStorage.removeItem('ta_user_type');
        localStorage.removeItem('ta_partner_id');
        localStorage.removeItem('ta_user_email');
        localStorage.removeItem('ta_user_name');
        localStorage.removeItem('registration_date');
        localStorage.removeItem('tier');
        localStorage.removeItem('code');
        localStorage.removeItem('username');
        localStorage.removeItem('password');
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');

        this.loggedIn.next(false);

        window.location.href = environment.amsurl + '?next=' + window.location;
    }

    
    logout(){
        // localStorage.removeItem('dashboardAuth');
        localStorage.clear();
    }

}