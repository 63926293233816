<section class="container px-4">
    <div class="row my-4">
        <div class="col-12">
            <!-- <a (click)="redirectionToBackPage()" class="page-back">
                <mat-icon>keyboard_backspace</mat-icon>
            </a> -->
        </div>
    </div>
</section>

<section class="payment-section">
    <div class="container px-4">
        <div class="row text-center justify-content-center my-4">
            <div class="col-12 image-sec">
                <img src="assets/images/failure.png">
            </div>
            <div class="col-8 thankyou-msg my-4">
                <br> Something went wrong.<br>Please
            </div>



            <div class="col-6 text-center">
                <button class="submit-btn" mat-button type="submit"
                    routerLink="/pay">Try Again
                </button>
            </div>


        </div>
    </div>
</section>
