<div class="dialog-content-box text-center">
    <div class="row justify-content-center mt-3">
        <div class="col-12 font-size-30 font-weight-bold">Error Occured</div>
        <div class="col-12">We could not find any policy with this number, please enter the correct number</div>
        <div class="col-3"></div>
        <div class="col-6">
            <button class="submit-btn btn" style="color: #fff;" (click)="backtohome()">Back to Homepage</button>
        </div>
        <div class="col-3">
        </div>
    </div>

</div>


