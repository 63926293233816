import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable, map } from "rxjs";
import { AuthService } from "./auth.service";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(
    private service: AuthService,
    private route: Router,
    private router: Router,
    private apiService: ApiService
  ) {
    // localStorage.getItem('partner_code')
  }
  canActivate() {
    if (this.service.IsLoggedIn()) {
      return true;
    } else {
      this.route.navigate(["rb-login"]);
      return false;
    }
  }

  /** used to check the agent is valid or not **/
  canLoad(): boolean | Observable<boolean> | Promise<boolean> {
    let url;
    if (localStorage.getItem("token") !== "undefined") {
      url = `/api/v2/get_agent_data/agent_validation`;
    } else if (
      localStorage.getItem("partner_code") != "" &&
      localStorage.getItem("token") == "undefined"
    ) {
      url = `/api/v2/get_agent_data/agent_validation/?partner_code=${localStorage.getItem(
        "partner_code"
      )}`;
    } else {
      url = `/api/v2/get_agent_data/agent_validation/?isUser=customer`;
    }
    return this.apiService
      .getRequestedResponse(url, "", false, true, false)
      .pipe(
        map((res: any) => {
          if (res.message === true) {
            this.router.navigate(["invalid-agent"]);
            return false; // Returning false to prevent loading the module
          }
          return true; // Allowing loading the module when res.message is not true
        })
      );
  }
}
