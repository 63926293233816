<div id="magma-renewals">
    <div class="web-view">
        <div class="row">
            <div class="col-12" *ngIf="data?.portMagma === true">
                <img class="close-pop" (click)="closeDialog()" src="assets/images/magma-logo/Vector.png" alt="cancel">
            </div>
        </div>
        <div class="row">
            <div class="heading col-12">
                <div>
                    <span *ngIf="data?.portMagma === true"><img src="assets/images/magma-logo/success-0u6rqMvimp.png"
                            class="header-icon"> </span>
                    <span> {{data?.portMagma === true ? 'Congratulations on Enhancing Your Plan' : 'Enhance your
                        existing RB Health Plan'}}</span>
                </div>
            </div>
        </div>
        <div class="row mt-4 table-size">
            <table class="table table-bordered comparison-table" cellPadding="0" cellSpacing="0">
                <thead>
                    <tr>
                        <th>Features</th>
                        <th><img src="assets/images/zuno-logo.svg" class="zuno-logo-img"></th>
                        <th class="magma-header"><img src="assets/images/magma-logo/magma.png" class="logo-img"></th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let plan of plans">
                        <td class="text-center"><span class="text-font-weight">{{ plan.features }}</span><br>
                            <span class="sub-feature">{{plan.sub_feature}}</span>
                        </td>
                        <td class="zuno-cell">
                            <ng-container *ngIf="plan.features !== 'Premium'; else premiumTemplate">
                                <img [src]="plan.zunoValid ? 'assets/images/magma-logo/Frame 78.png' : '/assets/images/magma-logo/Frame 76.png'"
                                    class="zuno-icon" alt="Zuno status icon">
                                <span class="text-font-weight">{{ plan.zuno }}</span>
                            </ng-container>
                            <ng-template #premiumTemplate>
                                <button class="premium-button">
                                    {{ zunoPremium ? zunoPremium : '-' }}
                                </button>
                            </ng-template>
                        </td>
                        <td class="magma-cell">
                            <ng-container *ngIf="plan.features !== 'Premium'; else premiumTemplateMagma">
                                <img [src]="plan.magmaValid ? 'assets/images/magma-logo/Frame 78.png' : '/assets/images/magma-logo/Frame 76.png'"
                                    class="zuno-icon" alt="Magma status icon">
                                <span class="text-font-weight">{{ plan.magma }}</span>
                            </ng-container>
                            <ng-template #premiumTemplateMagma>
                                <button class="premium-magma-button">
                                    {{ magmaPremium ? magmaPremium : '-' }}
                                </button>
                            </ng-template>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="row">
                <div class="col-4"></div>
                <div class="col-4">
                    <button class="btn zuno-button" (click)="magmaJourney('zuno')"
                        *ngIf="data?.portMagma === false">Continue With Zuno</button>
                </div>
                <div class="col-4">
                    <button class="btn magma-button" (click)="magmaJourney('magma')">Port To Magma</button>
                </div>
            </div>
        </div>

        <div class="col-12 footer-text">
            <p class="footer-content">Maternity Benefits will be applicable as per T&Cs</p>
            <p>**All waiting served under the existing plan will be carried forward to the new plan</p>
        </div>
    </div>

    <div class="mobile-view">
        <div class="dialog-container">
            <div class="heading">{{data?.portMagma === true ? 'Congratulations on Enhancing Your Plan' : 'Enhance your
                existing RB Health Plan'}} <span *ngIf="data?.portMagma === true"> <img class="close-pop" (click)="closeDialog()" src="assets/images/magma-logo/Vector.png" alt="cancel"></span></div>

            <div class="plan ">
                <div class="plan-header">
                    <span class="plan-name"><img src="../../../assets/images/zuno-logo.svg"></span>
                    <button class="premium zuno-premium"> Premium <span>{{ zunoPremium ? zunoPremium : '-' }}</span></button>
                </div>
                <ul class="features">
                    <li><span><img src="assets/images/magma-logo/mob-tick.png"></span> Room Rent Cover above ₹5L SI <br>
                       <span class="sub-feature sub-feature-position">(Single Private AC Room)</span></li>
                    <li><span><img src="assets/images/magma-logo/mob-tick.png"></span> Disease Sub - Limits
                        <span class="sub-feature">  (As per Current Plan)</span></li>
                    <li><span><img src="assets/images/magma-logo/mob-tick.png"></span> Maternity Cover above ₹5L SI</li>
                    <hr>
                    <li><span>❌ PA Cover</span><span style="margin-left: 12%;">❌ Psychiatric Illness Cover</span></li>
                    <li>❌ Automatic Restoration <span class="sub-feature"> (Unrelated Illness)</span></li>
                </ul>
            </div>

            <div class="plan magma">
                <div class="plan-header ">
                    <span><img src="../../../assets/images/magma-logo/magma.png" class="magma-position" width="160" height="50"
                            alt="Magma HDI Logo" /></span>
                    <button class="premium magma-premium ">Premium <span>{{ magmaPremium ? magmaPremium : '-' }}</span></button>
                </div>
                <ul class="features">
                    <li><span><img src="assets/images/magma-logo/mob-tick.png"></span> Room Rent Cover for all SI <br>
                        <span class="sub-feature sub-feature-position"> (Single Private AC Room) </span> </li>
                    <li><span><img src="assets/images/magma-logo/mob-tick.png"></span> PA Cover up to SI</li>
                    <li><span><img src="assets/images/magma-logo/mob-tick.png"></span> Automatic Restoration up to SI
                        <br>  <span class="sub-feature sub-feature-position">(Unrelated Illness)</span></li>
                    <li><span><img src="assets/images/magma-logo/mob-tick.png"></span> No Sub - Limits  <span class="sub-feature">(Disease Sub - Limits)</span></li>
                    <li><span><img src="assets/images/magma-logo/mob-tick.png"></span> Maternity Cover for all SI</li>
                    <li><span><img src="assets/images/magma-logo/mob-tick.png"></span>  Psychiatric Illness Cover up to
                        SI</li>
                </ul>
            </div>

                <div class="row">
                    <button class="port-button" (click)="magmaJourney('magma')">Port To Magma</button>
                </div>
                <div class="row" *ngIf="data?.portMagma === false">
                <button class="continue-button" (click)="magmaJourney('zuno')">Continue With Zuno</button>

                </div>

            <p class="disclaimer">
                *Maternity Benefits will be applicable as per T&Cs<br />
                *All waiting served under the existing plan will be carried forward to the new plan
            </p>


        </div>

    </div>

</div>