import { Component, Input, Inject, Optional, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogData } from "src/app/model/data.model";
@Component({
  selector: 'app-hospital-network-list',
  templateUrl: './hospital-network-list.component.html',
  styleUrls: ['./hospital-network-list.component.scss']
})
export class HospitalNetworkListComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<HospitalNetworkListComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData | any,
  ) {
    dialogRef.disableClose = true;
   }

  ngOnInit(): void {
  }

  redirection() {
    window.open('https://www.paramounttpa.com/Home/ProviderNetwork.aspx', '_blank');
    // window.location.href = url;
  }

  CloseDialog() {
    this.dialogRef.close({ event: 'close' });
  }


}