<footer class="pb-4" *ngIf="!source">

    <div class="container">

        <div class="row">

            <div class="col-12">
                <hr class="divider-line">
                <div class="footer-header">GENERAL</div>
                <ul class="ps-0">
                    <li class="d-inline pe-2 footer-list" *ngFor="let item of generalList">
                        <a href="{{item['url']}}">
                            {{item['label']}}
                        </a>
                    </li>
                </ul>
            </div>

            <div class="col-12">
                <div class="footer-header">OTHERS</div>
                <ul class="ps-0">
                    <li class="d-inline pe-2 footer-list" *ngFor="let item of otherList">
                        <a href="{{item['url']}}">
                            {{item['label']}}
                        </a>
                    </li>
                </ul>
                <hr>
            </div>


        </div>

    </div>

</footer>
