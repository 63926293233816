import { Component, Inject, OnInit, Optional } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogData } from "src/app/model/data.model";

@Component({
  selector: "app-pincode-not-serviceable",
  templateUrl: "./pincode-not-serviceable.component.html",
  styleUrls: ["./pincode-not-serviceable.component.scss"],
})
export class PincodeNotServiceableComponent {
  constructor(
    private dialogRef: MatDialogRef<PincodeNotServiceableComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData | any
  ) {
    this.dialogRef.disableClose = true;
  }
}
