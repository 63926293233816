import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterRoutingModule } from './footer-routing.module';
//import { FooterComponent } from './footer.component';

@NgModule({
    declarations: [
       // FooterComponent
    ],
    imports: [
        CommonModule,
        FooterRoutingModule
    ],
    exports: [
       // FooterComponent
    ]
})
export class FooterModule { }
