<div class="row">
  <div class="close-icon">
    <span class="cursor" (click)="closeDialog()"> &#x2715;</span>
  </div>
  <div class="col-12 text-center d-flex flex-column align-items-center">
    <div>
      Dear
      {{
        data["data"]["share_declaration_with_customer"]["agent_name"].split(
          " "
        )[0]
      }}, RB Health Plan details for
      {{ data["data"]["customer"]["proposer"]["name"] }} have been shared on
      {{ data["data"]["customer"]["proposer"]["email"] }} &
      {{ data["data"]["customer"]["proposer"]["mobile_no"] }}. The link shared
      will expire within 48 hours. Kindly contact
      {{ data["data"]["customer"]["proposer"]["name"] }} at the earliest to
      proceed further.
    </div>
    <br />
  </div>
  <div class="col-12 center">
    <button
      class="submit-btn close-submit-button"
      type="submit"
      mat-button
      (click)="closeDialog()"
    >
      Close
    </button>
  </div>
</div>
