import { Component, Inject, OnInit, Optional } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogData } from "../../model/data.model";
import { SharedDataService } from "../../services/shared-data.service";

import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ApiService } from "../../services/api.service";
import { environment } from "src/environments/environment";
import { Location } from "@angular/common";
import { WindowRef } from "../../services/window.service";
import { AngularDeviceInformationService } from "angular-device-information";

@Component({
  selector: "app-check-valid-pincode",
  templateUrl: "./check-valid-pincode.component.html",
  styleUrls: ["./check-valid-pincode.component.scss"],
})
export class CheckValidPincodeComponent implements OnInit {
  pincodeForm!: FormGroup;
  isPincode: boolean = false;
  constructor(
    private apiService: ApiService,
    private sharedDataService: SharedDataService,
    private formBuild: FormBuilder,
    private dialogRef: MatDialogRef<CheckValidPincodeComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData | any,
    private deviceInformationService: AngularDeviceInformationService,
    private win: WindowRef,
    private location: Location
  ) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.pincodeForm = this.formBuild.group({
      pincode: ["", [Validators.required, Validators.minLength(6)]],
    });
  }
  submitPincodeForm(isValid) {
    if (isValid) {
      this.dialogRef.close({ pincode: this.pincodeForm.get("pincode")?.value });
    }
  }
  getPincode() {
    if (this.pincodeForm.get("pincode")?.value.length >= 6) {
      this.sharedDataService
        .getValidPincode(this.pincodeForm.get("pincode")?.value)
        .subscribe((res) => {
          if (!res["pincode"]) {
            this.isPincode = false;
            this.pincodeForm.get("pincode")?.setErrors({ validPincode: true });
          } else {
            this.isPincode = true;
            this.pincodeForm.get("pincode")?.setErrors(null);
          }
        });
    } else {
      this.isPincode = false;
    }
  }
  backToHome() {
    if (
      this.location.prepareExternalUrl("/") == "rbhealth-renewals/#/" ||
      this.location.prepareExternalUrl("/") == "rbhealth/#/"
    ) {
      if (this.deviceInformationService.isMobile()) {
        this.win.nativeWindow.ReactNativeWebView.postMessage("home");
      } else {
        window.location.href = `${environment["partnerPortalUrl"]}home/`;
      }
    } else {
      window.location.href = environment["baseUrl"];
    }
  }
}
