import { Injectable } from "@angular/core";
import { HttpService } from "../services/http.service";
import { tap, catchError, map, Subject, Observable, throwError } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { ErrorHandlerComponent } from "../modal-component/error-handler/error-handler.component";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { LoaderService } from "./loader.service";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  url = "/api/v2/dashboard-user/";
  setHeader: any;
  headersFormulated: any;

  OnLogin(loginobj: any) {
    throw new Error("Method not implemented.");
  }
  onlogin(loginobj: any) {
    throw new Error("Method not implemented.");
  }

  getStatusEvent: Subject<any> = new Subject();

  constructor(
    private httpService: HttpService,
    public dialog: MatDialog,
    private http: HttpClient,
    private loaderService: LoaderService
  ) {}

  setStatusEvent(addOnDataEmiited: any) {
    this.getStatusEvent.next(addOnDataEmiited);
  }

  getHeaderAsProductModule(
    product: string,
    isDoc?: any,
    isToken?: any,
    isAccessToken?: any
  ) {
    this.headersFormulated = this.setHeader.getHeaders(
      product,
      isDoc,
      isToken,
      isAccessToken
    );
    return this.headersFormulated;
  }

  getRequestedResponse( url: string, productModuleName?: string, isDoc?: boolean, isToken?: boolean, isDashboardAuth?: boolean ) {
    return this.httpService.getRequest(url, productModuleName, isDoc, isToken, isDashboardAuth).pipe( map((response: any) => response),
        catchError((err: any) => JSON.stringify(this.errorHandler(err)))
      );
  }


  postRequestedResponse(
    url: any,
    body: any,
    headers?: any,
    isDoc?: boolean,
    isToken?: boolean,
    isDashboardAuth?: boolean,
    isrefresh_token?: boolean
  ) {
    return this.httpService
      .postRequest(
        url,
        body,
        headers,
        isDoc,
        isToken,
        isDashboardAuth,
        isrefresh_token
      )
      .pipe(
        map((response: any) => response),
        catchError((err: any) => JSON.stringify(this.errorHandler(err)))
      );
  }

  postRequestedResponseWithoutPopupErrorHandler( url: any, body: any, headers?: any, isDoc?: boolean, isToken?: boolean, isDashboardAuth?: boolean, isrefresh_token?: boolean ) {
    return this.httpService.postRequest( url, body, headers, isDoc, isToken, isDashboardAuth, isrefresh_token );
  }

  patchRequestedResponse(
    url: any,
    body: any,
    headers?: any,
    isDoc?: boolean,
    isToken?: boolean,
    isDashboardAuth?: boolean
  ) {
    return this.httpService
      .patchRequest(url, body, headers, isDoc, isToken, isDashboardAuth)
      .pipe(
        map((response: any) => response),
        catchError((err: any) => JSON.stringify(this.errorHandler(err)))
      );
  }
  postRequestedResponsewithoutpopup(
    url: any,
    body: any,
    headers?: any,
    isDoc?: boolean,
    isToken?: boolean,
    isDashboardAuth?: boolean,
    isrefresh_token?: boolean
  ) {
    return this.httpService
      .postRequest(
        url,
        body,
        headers,
        isDoc,
        isToken,
        isDashboardAuth,
        isrefresh_token
      )
      .pipe(catchError(this.handleError));
  }

  errorHandler(err: any) {
    this.loaderService.hide();
    let error;
    const status = err;
    this.setStatusEvent(err);
    if (err.error) {
      error = err.error;
    } else if (err.details) {
      error = err.detail;
    } else {
      error = err;
    }

    if (error.status_code !== 401) {
      if (err.status !== 200) {
        const dialogRef = this.dialog.open(ErrorHandlerComponent, {
          width: "50%",
          height: "40%",
          data: {
            errorData: error,
            statusdata: status,
          },
        });
        dialogRef.afterClosed().subscribe((result: any) => {});
      }
    } else if (error.status_code == 401) {
      localStorage.removeItem("ams_token");
      localStorage.removeItem("phone");
    }
  }
  getData(url: string, header?: any) {
    return this.httpService.getRequestwithHeader(url, header).pipe(
      map((response) => response),
      catchError((err) => JSON.stringify(err))
    );
  }
  deleteData(
    url,
    headers?: any,
    isDoc?: boolean,
    isToken?: boolean,
    isDashboardAuth?: boolean
  ) {
    return this.httpService
      .deleteRequestWithToken(url, headers, isDoc, isToken, isDashboardAuth)
      .pipe(
        map((response) => response),
        catchError((err) => JSON.stringify(err))
      );
  }

  private handleError(error: HttpErrorResponse) {
    let errormessage = "";

    // Return an observable with a user-facing error message.
    // errormessage+="invalid"
    return throwError(() => new Error(errormessage));
  }
  downloadPdf(url: string): Observable<Blob> {
    return this.http.get(url, { responseType: "blob" });
  }
}
