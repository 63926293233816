<div class="dialog-content-box mob-verifiy-popup ">

    <div class="row">
        
        <div class="col-12">
            <div class="otp-text1" *ngIf="mobile">{{mobile | masking}}</div>
            <div class="otp-text1">Enter OTP recieved on your phone*</div>
            
    </div>

    <div class="otpno-flex">
        <ng-otp-input  #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="{length:6}" ></ng-otp-input>

    </div>
      <!-- counter loading loading -->
      
    <div class="col-12 text-center error-msg">{{errorMessage}}</div>
    <div class="resendotp-text">OTP sent. Resend OTP in <span class="bold">{{time}} Seconds</span> </div>
    <ng-container *ngIf="time == 0">
        <div class="resendotp"  (click)="initateOTP()" [class.disabled]="time !== 0">RESEND OTP</div>
    </ng-container>
    <button  [ngClass]="{'disable':submitOtp==false}" (click)="submitOtpForm(otpNumber)" class="submit-btn" type="submit" mat-button>Submit OTP</button>

</div>