// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  defaultLanguage: "en",
  dummy: "http://test.rbstaging.in/",
  baseUrl: "https://dev.renewbuy.com/",
  finsureUrl: "https://rbfinance.rbstaging.in/",
  domainUrl: "https://rbfinance.rbstaging.in",
  partnerPortalUrl: "https://partners.rbstaging.in/",
  amsurl: "https://accounts.rbstaging.in/",
  bimaPayCalculatorRedirectUrl : "https://app.bimapay.in/rbhealth-emi-calculator/",
  networkHospitalList:
    "https://uat-finsure.s3.ap-south-1.amazonaws.com/finsure/media/pdf/Network+Hospital+List(1).xlsx",
  urlLib: "https://partner-advisor.credilio.in/channel/library/credit-card/",
  urlLead: "https://partner-advisor.easycardsloans.com/channel/lead-bay/",
  urlLeadCreation:
    "https://partner-advisor.credilio.in/channel/credit-card/create-lead/",
  urlPartnerPortal: "https://partners.rbstaging.in/home/due-renewals/rb-health",
  brochureUrl: "./assets/document/RB_Health_Combo.pdf",
  wellnessbrochureUrl: "./assets/document/RB_Health_Wellness.pdf",
  dossierUrl : "https://dossier.rbstaging.in",
  productModuleName: {
    "renewbuy-backend-header": {
      "api-secret-key": "YiBYbVTAOIT6TD2k8L3S22pou4IVBdR6",
      "app-id": "7b574be9-96c2-4afc-934e-a333f9d27e94",
    },
    "ams-headers": {
      "Api-key": "945dbc41-8587-4c62-82ca-741f914077cf",
      "secret-key": "dCr8hEr8WWaSlxoNrh6Dst9PLzWQJnqD",
    },
  },
  FinsureApiKey: "XB6EdBk8.j3mvZKhvFcH862vXBR2f0elfqihMYy5r",
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
