<div class="check-valid-pincode-main">
  <div class="row">
    <div class="pincode-popup-width">
      <form
        [formGroup]="pincodeForm"
        (ngSubmit)="submitPincodeForm(pincodeForm.valid)"
        novalidate
      >
        <div class="col-12">
          <p class="text-center">Please enter eldest member’s pincode</p>
          <mat-form-field class="custom-mat-form-field" appearance="outline">
            <input
              class="text-capitalize"
              matInput
              placeholder="Enter Pincode"
              appRemoveWhiteSpace
              formControlName="pincode"
              [maxLength]="6"
              (ngModelChange)="getPincode()"
            />
            <img
              src="/assets/images/right-tick.svg"
              alt=""
              class="right-image"
              *ngIf="isPincode"
            />
            <mat-error *ngIf="pincodeForm.get('pincode')?.hasError('required')">
              Pincode is required
            </mat-error>
            <mat-error
              *ngIf="pincodeForm.get('pincode')?.hasError('minlength')"
            >
              Pincode should contain Minimum 6 Number
            </mat-error>
            <mat-error
              *ngIf="pincodeForm.get('pincode')?.hasError('validPincode')"
            >
              This pincode is not serviceable
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-12 d-flex">
          <button
            class="submit-btn"
            [ngClass]="{ btn_disabled: !isPincode, btn_curser: !isPincode }"
            mat-button
            type="submit"
          >
            Continue
          </button>

          <button class="back_btn" mat-button (click)="backToHome()">
            Back to home
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
