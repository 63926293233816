<div class="dialog-content-box customermobile">
  <div class="row">
    <div mat-dialog-actions>
      <div mat-button mat-dialog-close class="close-popup" (click)="closePopup()">&#x2715;</div>
    </div>

    <div class="col-12 text-center">
     
      <div class="text-center main-heading">{{data['msg']}} </div>
      <br />
      <div class="text-center main-heading">Previous Plan</div>
      <div class="popup-policy-dtl-card ">
        <div class="popup-policy-dtl-flex">
          <div class="left-section">
            <div class="self_spouse previous-plan-text">{{planData?.plans[0]?.name}}</div>
            <!-- <div class="gold-btn">{{planData?.product_category}}</div> -->
            <div class="change-plan">
              <div class="amt-text">&#8377; {{planData?.plans[0]?.plan_amount}}</div>
              <div class="amount-txt">Premium</div>
            </div>
          </div>
          <div class="plan-type">
            <!-- <div class="fivelac_opd">{{planData?.plans[0]?.plan_type}}</div> -->
            <!-- <div class="sum-assured">Sum Insured</div> -->
          </div>
          <div class="plan-date">
            <!-- <div class="plan-id">Plan Id: {{planData?.plans[0]?.plan_id}}</div> -->
            <div class="plan-id issue-date">Issue Date: {{planData?.issue_date| date:'longDate'}}</div>
            <div class="plan-id due-date">Due Date: {{planData?.expiry_date| date:'longDate'}}</div>

          </div>
       
        </div>
      </div>
   

    <div class="text-center main-heading">New Plan</div>

      <div class="newplan-popup-policycard ">
        <div class="popup-policy-dtl-flex">
          <div>
            <div class="self_spouse ">{{newPlanData?.name}}</div>
            <!-- <div class="gold-btn">{{newPlanData?.product_category}}</div> -->
            <div class="change-plan">
              <div class="amt-text amount-section" >&#8377; {{newPlanData?.discounted_amount}}</div>
              <div class="amount-txt">Premium</div>
            </div>
          </div>
          <div class="plan-type new-plan-type">
            <div class="fivelac_opd">{{newPlanData?.plan_type}}</div>
            <!-- <div class="sum-assured">Sum Insured</div> -->
          </div>
         
        </div>
      </div>
<div class="text-center">
      <div mat-dialog-close class="submit-btn proceedBtn" (click)="yesProcced()">
        Proceed
      </div>
    </div>
    </div>
    <br/>
      
   
  </div>
</div>