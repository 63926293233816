import {
  Component,
  Inject,
  OnInit,
  Optional,
  ViewEncapsulation,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogData } from "src/app/model/data.model";

@Component({
  selector: "app-basic-modal",
  templateUrl: "./basic-modal.component.html",
  styleUrls: ["./basic-modal.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class BasicModalComponent implements OnInit {
  text: string;
  // heading: string;
  proceed: string;
  prodcut: any;
  constructor(
    private dialogRef: MatDialogRef<BasicModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData | any
  ) {
    // this.heading = data['data']['heading'];
    this.text = data["data"]["content"];
    this.proceed = "Proceed"; //data['data']['proceed'];
    this.prodcut = data["prodcut"]; //rbmotor
  }

  ngOnInit(): void {}
}
