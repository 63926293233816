<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="div close-popup-icon" >
        <span (click)="CloseDialog()">&#x2715;</span> 
      </div>
    </div>    
  </div>
    <div class="row">
        <div class="col-12">
          <h6>
            <p class="heading"><strong>Check the latest RB Health Network Hospital list from Paramount TPA's website in 5 Simple Steps</strong></p>
          </h6>
        </div>
    </div>
    <div class="row mt-2">
      <div class="col-lg-4 network-content">
        <p class="text-size"><img src="assets/images/hand-pointer-svgrepo-com 5.svg" class="icon">1. Select <strong>"All"</strong> in State</p>
        <p class="text-size"><img src="assets/images/hand-pointer-svgrepo-com 5.svg" class="icon">2. Select <strong>"All"</strong> in City</p>
        <p class="text-size"><img src="assets/images/hand-pointer-svgrepo-com 5.svg" class="icon">3. Select <strong>"Zuno General Insurance Company"</strong></p>
        <p class="text-size"><img src="assets/images/hand-pointer-svgrepo-com 5.svg" class="icon">4. Select Policy Type as <strong>"Group"</strong></p>
        <p class="text-size"><img src="assets/images/hand-pointer-svgrepo-com 5.svg" class="icon">5. Click <strong>"Submit"</strong> and Download the List</p>
      </div>
      
      <div class="col-lg-8" >
        <div class="banner-img">
        <img src="assets/images/image 4.svg" class="web-image">
        <img src="assets/images/image 5.svg" class="mobile-image">
      </div>
      </div>
      <div class="col-12 redirection-button">
        <button class="submit-btn" mat-button type="submit" (click)="redirection()" >Redirect to Paramount Website</button> 
      </div>
    </div>
</div>
  