import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SetHeaderService } from '../services/set-header.service';

@Injectable({
    providedIn: 'root'
})
export class HttpService {
  deleteDataWithoutKey(url: string) {
    throw new Error('Method not implemented.');
  }
  deleteRequestWithoutKey(url: string) {
    throw new Error('Method not implemented.');
  }
    deleteRequest(arg0: string, httpOptions: any) {
        throw new Error('Method not implemented.');
    }

    headersFormulated: any;

    constructor(
        private http: HttpClient,
        private setHeader: SetHeaderService
    ) { }

    getHeaderAsProductModule(product: string, isDoc?: any, isToken?: any,isDashboardAuth?:any,isrefresh_token?:any) {
        this.headersFormulated = this.setHeader.getHeaders(product, isDoc, isToken,isDashboardAuth,isrefresh_token);
        return this.headersFormulated;
    }
    deleteRequestWithToken(url,productModules?: string,isDoc?: any, isToken?: any,isDashboardAuth?:any){
      const productHeaders = productModules ? productModules : ''
      return this.http.delete(url,this.getHeaderAsProductModule(productHeaders, isDoc, isToken,isDashboardAuth));
    }

    // Invokes HTTP Get Request
    getRequest(url: string, productModules?: string, isDoc?: boolean, isToken?: boolean,isDashboardAuth?:boolean) {
        let returnValue;
        const productHeaders = productModules ? productModules : ''
        return this.http.get(url, this.getHeaderAsProductModule(productHeaders, isDoc, isToken,isDashboardAuth));
    }

    // Invokes HTTP Post Request
    postRequest(url: string, dataObtained: any, productModules?: string, isDoc?: boolean, isToken?: boolean,isDashboardAuth?:boolean,isrefresh_token?:boolean) {
        const data = dataObtained ? dataObtained : {};
        const productHeaders = productModules ? productModules : ''
        return this.http.post(url, data, this.getHeaderAsProductModule(productHeaders, isDoc, isToken,isDashboardAuth,isrefresh_token));
    }

    // Invokes HTTP patch Request
    patchRequest(url: string, dataObtained: any, productModules?: string, isDoc?: boolean, isToken?: boolean,isDashboardAuth?:boolean) {
        const data = dataObtained ? dataObtained : {};
        const productHeaders = productModules ? productModules : ''
        return this.http.patch(url, data, this.getHeaderAsProductModule(productHeaders, isDoc, isToken,isDashboardAuth));
    }
 /* Invokes HTTP Get Request */
 getRequestwithHeader(
    url: string,
    headers?: HttpHeaders | { [header: string]: string | string[] } | undefined
  ) {
    return this.http.get(url, { headers });
  }

}
