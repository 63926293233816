<div class="row justify-content-center width">
    <div class="col-12 close-icon">
        <i class="material-icons" (click)="closeDialog()"><span class="cursor"> &#x2715;</span></i>
    </div>
    <div class="col-12 text-center d-flex flex-column align-items-center">
        <div>{{message}}</div><br>
    </div>
    <div class="col-12 center">
        <button class="submit-btn close-submit-button"  type="submit" mat-button (click)="closeDialog()">Back</button>
    </div>
</div>
