import {
  Component,
  OnInit,
  ViewEncapsulation,
  Optional,
  Inject,
  HostListener,
} from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { DialogData } from "../../model/data.model";
import { Router } from "@angular/router";

@Component({
  selector: "app-error-handler",
  templateUrl: "./error-handler.component.html",
  styleUrls: ["./error-handler.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ErrorHandlerComponent implements OnInit {
  errorResponse: any;
  classNameObtained!: string;
  isInternalServerErr = false;
  statusMessage: any;
  public title!: string;
  private msg!: string;
  error: Array<any> = [];
  // tslint:disable-next-line: variable-name
  display_msg: any = "";
  private callback: Function = () => console.log("dialog closed");

  constructor(
    private router: Router,
    private dialogRef: MatDialogRef<ErrorHandlerComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData | any
  ) {
    // Getitng response from modal data

    this.errorResponse = data;

    this.statusMessage = data["statusdata"]["status"];
    if (this.statusMessage !== 400) {
      this.isInternalServerErr = true;
    } else {
      this.isInternalServerErr = false;
      this.errorResponse = data["errorData"];
    }
    if (this.errorResponse) {
      this.create_display_msg("Error", this.errorResponse);
    }
    dialogRef.disableClose = true;
  }

  create_display_msg(key: string, massege: any) {
    if (typeof massege === "object") {
      if (massege instanceof Array) {
        this.create_display_msg(key, massege[0]);
      } else {
        // tslint:disable-next-line: forin
        for (const keys in massege) {
          this.create_display_msg(keys, massege[keys]);
        }
      }
    } else {
      this.error.push({ key, msg: massege });
    }
  }

  close() {
    this.callback();
    this.dialogRef.close();
    // this.reloadPage()
  }

  reloadPage() {
    const urlOBtained = this.router["url"];
    // this.router.navigate([urlOBtained]).then(() => {
    window.location.reload();
    // });
  }

  ngOnInit(): void {}
  ngOnDestroy(): void {
    //  this.reloadPage()
  }
}
