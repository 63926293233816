import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../src/app/services/api.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'rb-finance';

  constructor(private apiService: ApiService, private router: Router) {
  }


}
