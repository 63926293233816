import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "amountFormat",
})
export class AmountFormatPipe implements PipeTransform {
  val: any;
  transform(input: any, precision: "fixed" | "rounded" = "rounded"): string {
    let val = Math.abs(input); // Ensure the value is positive
    const digits = precision === "fixed" ? 2 : 0; // Set the number of digits dynamically
    
    if (val >= 10000000) {
        return (val / 10000000).toFixed(digits) + " Cr";
    } else if (val >= 100000) {
        return (val / 100000).toFixed(digits) + " Lacs";
    } else if (val >= 10000) {
        return (val / 10000).toFixed(digits) + " Thousand";
    }
    return val.toString(); // Default fallback if no condition matches
}

}
