<div class="dialog-content-box">
    <div class="float-r">
        <div mat-button mat-dialog-close class="close-popup">&#x2715;</div>
    </div>  
    <div class="policytndc">
    <div class="row">
           
    <div class="col-12">
       <!-- <h4 class="text-center"><strong>{{heading}}</strong></h4> -->
        {{text}}
    </div>
        <br/>
        <ng-container *ngIf="prodcut!='rbmotor'">
            <div *ngIf="proceed" mat-button mat-dialog-close class="proceed-btn">{{proceed}}</div>
        </ng-container>
        
   
    </div>
    </div>
</div>
