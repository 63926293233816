<div id="network-hospital-card">
  <div class="container-fluid">
    <div class="network-card">
      <form [formGroup]="seachHospitalForm">
        <div class="row">
          <div class="col-sm-12 col-md-12 input-pad">
            <div class="row">
              <div class="col-sm-6 col-md-6 input-pad">
                <div class="input-subheading">View Hospital in</div>
                <div class="form-label">
                  <mat-form-field
                    class="custom-mat-form-field"
                    appearance="outline"
                  >
                    <mat-icon matSuffix class="icon-color">room</mat-icon>
                    <input
                      type="text"
                      placeholder="Enter city,state or pincode"
                      matInput
                      formControlName="networkcity"
                      [matAutocomplete]="citylist"
                      (ngModelChange)="getCityStateData($event)"
                    />
                    <mat-autocomplete
                      autoActiveFirstOption
                      [displayWith]="cityStateDisplay"
                      (optionSelected)="onCitySelection($event)"
                      #citylist="matAutocomplete"
                    >
                      <mat-option
                        *ngFor="
                          let option of this.filteredCityStateList | async
                        "
                        [value]="option.city + ',' + option.state"
                      >
                        {{ option.city }}, {{ option.state }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
              </div>

              <div class="col-sm-6 col-md-6 input-pad">
                <div class="input-subheading">
                  Hospital in {{ selectedCity }}
                </div>
                <div class="form-label">
                  <mat-form-field
                    class="custom-mat-form-field"
                    appearance="outline"
                  >
                    <input
                      matInput
                      formControlName="networkhospitals"
                      placeholder="Enter hospital name"
                      (ngModelChange)="searchByHospitalsName($event)"
                    />
                    <mat-icon matSuffix class="icon-color">search</mat-icon>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-sm-12 col-md-12">
                <div class="change-location-flex">
                  <!-- <div class="location">
                    <span
                      ><img
                        src="/assets/images/health-v2/icons/location.svg"
                        alt="location"
                        class="location-img"
                    /></span>
                    Change Location
                  </div> -->
                </div>
                <hr class="line-color" />
              </div>

              <div class="cashlash-hospitals">
                <div class="cashless-text">
                  <span
                    ><img
                      src="/assets/images/health-v2/icons/hospital-icon.svg"
                      alt="hospitals"
                  /></span>
                  {{ cashlessHospitalCount }} Cashless hospitals in
                  <span class="icon-color">{{ selectedCity }}</span>
                </div>
                <div class="sort-by" (click)="sortCities()">Sort by A-Z</div>
              </div>
              <div class="cashlash-hospitals">
                <div class="cashless-text"></div>
                <div class="sort-by" (click)="clearAll()">Clear All</div>
              </div>

              <div>
                <div class="searchby-insurer">Search by Insurer:</div>
                <div class="radio-container">
                  <mat-radio-group
                    class="radio-button mr30"
                    color="primary"
                    formControlName="selectedInsurer"
                  >
                    <mat-radio-button
                      class="custom-radio-button"
                      *ngFor="let insurerName of insurer"
                      [value]="insurerName.updated_name"
                      (click)="getInsurerByName(insurerName.updated_name)"
                    >
                      {{ insurerName.name }}
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>

              <div class="cashlash-hospitals">
                <div class="nearby-hospital">Nearby Hospitals</div>
                <div class="download-hospital" (click)="downloadHospitals()">
                  Download Hospitals List
                </div>
              </div>
            </div>

            <div class="row">
              <div
                class="col-sm-4 col-md-4 col-12"
                *ngFor="let getList of hospitalList"
              >
                <div class="hospital-list-card card">
                  <div class="card-list">
                    <div class="flex-1">
                      <mat-icon class="location-icon">room</mat-icon>
                    </div>
                    <div class="flex-2">
                      <div class="hospital-name">
                        {{ getList?.hospital_name }}
                        <!-- <span class="arrow-icon"
                          ><mat-icon>keyboard_arrow_right</mat-icon></span
                        > -->
                      </div>
                      <div class="hospital-address">{{ getList?.address }}</div>
                      <!-- <div class="km-away">0.3 KMs away</div> -->
                      <div
                        class="share-btn"
                        (click)="hospitalNameModal(getList)"
                      >
                        <img
                          src="/assets/images/health-v2/icons/share.svg"
                          alt="share"
                          class="share-icon"
                        />
                        Share
                      </div>
                    </div>
                    <div class="flex-3">
                      <mat-icon>keyboard_arrow_right</mat-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <mat-paginator
              [length]="totalPageHospitals"
              [pageSize]="pageSize"
              (page)="onPageChange($event)"
            >
            </mat-paginator>
          </div>

          <!-- embeded map -->
          <!-- <div class="col-sm-6 col-md-6 input-pad">
            <div class="map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d112235.9733536249!2d76.95122480392459!3d28.468276311380592!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sMilann%20A%20Unit%20Of%20Bacc%20Healthcare%20Private%20Limited!5e0!3m2!1sen!2sin!4v1718886473054!5m2!1sen!2sin"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                class="location-map"
              ></iframe>
            </div>
            <br />
          </div> -->
        </div>
      </form>
    </div>
  </div>
</div>
