import { Component, OnInit, Inject, Optional } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogData } from "../../../model/data.model";
import { HttpClient } from "@angular/common/http";
import {
  FormBuilder,
  FormGroup,
  FormArray,
  Validators,
  FormControl,
} from "@angular/forms";
import { ApiService } from "src/app/services/api.service";
import { ApiConstants } from "src/app/api.constant";
import { HttpResponse } from "@angular/common/http";
import { environment } from "src/environments/environment";
declare const webengage: any;

@Component({
  selector: "app-share-proposal-popup",
  templateUrl: "./share-proposal-popup.component.html",
  styleUrls: ["./share-proposal-popup.component.scss"],
})
export class ShareProposalPopupComponent implements OnInit {
  customerForm!: FormGroup;
  whatsappStatus: boolean = false;
  emailStatus: boolean = false;
  baseURl: string = "";

  constructor(
    private apiService: ApiService,
    private formBuild: FormBuilder,
    private dialogRef: MatDialogRef<ShareProposalPopupComponent>,
    private https : HttpClient,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData | any
  ) {
    this.baseURl = location.pathname;
   
  }

  ngOnInit(): void {
    
    this.customerForm = this.formBuild.group({
      email: ['', [ Validators.email]],
      whatsapp: ['', [ Validators.pattern(/^\d{10}$/)]],
    });
  }


  closeDialog(): void {
    this.dialogRef.close();
  }

    /** funtion of email to share the screenshot **/
  shareWithEmail() {
    const emailControl = this.customerForm.get('email');
  // Add the 'required' validator to the email form control
  emailControl?.setValidators([Validators.required,Validators.pattern( new RegExp( "^[_a-zA-Z0-9.!#$%&*+/=?^_%+-<>|  ]+(.[_a-zA-Z0-9  ]+)@[a-zA-Z0-9-  ]+(.[a-zA-Z0-9-  ]+)(.[a-zA-Z  ]{2,4})$" ) )]);
  emailControl?.updateValueAndValidity(); // Update control validity after adding the validator
    if (emailControl?.valid) {
                  /**web engage start**/
                  if (environment?.dev && this.baseURl === "/rbhealth-renewals/" ) {
                    webengage.track( 'RB_Health_Hospital_Details_Shared' , {
                      User_Type: 'Customer',
                      Channel: 'Email'
                    });
                  } 
              /**web engage end**/
    let formData: FormData = new FormData();
    formData.append("FILES", this.dataURLtoBlob(this.data.data),); // Set the file name as 'data.png'
    formData.append('email', this.customerForm.get('email')?.value);
    this.https.post( `${ApiConstants.SHARE_SCREENSHOT}`, formData ) .subscribe((res: any) => {
      if (res) {
        this.emailStatus = true
      } else if (res && res.status){
        this.emailStatus = false
      }
    });
    }else {
      this.emailStatus = false
      // this.customerForm.get("email")?.setErrors({ customError: "This is a required field" });
      this.customerForm.get("email")?.markAsTouched();
    }
  }
    
    /** funtion of whatsapp to share the screenshot **/
    shareWithWhatsapp() {
    const whatsappControl = this.customerForm.get('whatsapp');
    whatsappControl?.setValidators([Validators.required,Validators.pattern(new RegExp("^[6-9]{1}[0-9]{9}$"))]);
    whatsappControl?.updateValueAndValidity(); // Update control validity after adding the validator

      if (whatsappControl?.valid) {

            /**web engage start**/
                if (environment?.dev && this.baseURl === "/rbhealth-renewals/" ) {
                  webengage.track( 'RB_Health_Hospital_Details_Shared' , {
                    User_Type: 'Customer',
                    Channel: 'Whatsapp'
                  });
                } 
            /**web engage end**/

        const formData: FormData = new FormData();
        formData.append("FILES", this.dataURLtoBlob(this.data.data),); // Set the file name as 'data.png'
        formData.append('mobile', this.customerForm.get('whatsapp')?.value);
        // Send FormData to server
        this.https.post(`${ApiConstants.SHARE_SCREENSHOT}`, formData)
          .subscribe((res: any) => {
            if (res) {
              this.whatsappStatus = true;
            }
          });
      } else {
        this.whatsappStatus = false
      this.customerForm.get("whatsapp")?.markAsTouched();

      }
    }
    
        /** Convert a base64 encoded image data URL to a Blob object.**/
        dataURLtoBlob(dataURL: string): Blob {
          const arr = dataURL.split(",");
          const mime = arr[0].match(/:(.*?);/)[1];
          const bstr = atob(arr[1]);
          let n = bstr.length;
          const u8arr = new Uint8Array(n);
          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }
          return new Blob([u8arr], { type: mime });
        }
    

}
