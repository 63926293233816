import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DialogData } from "../model/data.model";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { Overlay } from "@angular/cdk/overlay";
import { OtpComponent } from "../modal-component/otp/otp.component";
import { RelationPopupComponent } from "../modal-component/relation-popup/relation-popup.component";
import { HealthDeclarationPopupComponent } from "../modal-component/health-declaration-popup/health-declaration-popup.component";
import { ErrorMessageComponent } from "../modal-component/error-message/error-message.component";
import { BasicModalComponent } from "../modal-component/basic-modal/basic-modal.component";
import { ShareModalComponent } from "../modal-component/share-modal/share-modal.component";
import { IncorretMobileNumberComponent } from "../modal-component/incorret-mobile-number/incorret-mobile-number.component";
import { ChoosePolicyComponent } from "../modal-component/choose-policy/choose-policy.component";
import { OtpVerificationPopupComponent } from "../modal-component/otp-verification-popup/otp-verification-popup.component";
import { EnterMobilePopupComponent } from "../modal-component/enter-mobile-popup/enter-mobile-popup.component";
import { AdharVerificationPopupComponent } from "../modal-component/adhar-verification-popup/adhar-verification-popup.component";
import { PreviousPolicyAlertComponent } from "../modal-component/previous-policy-alert/previous-policy-alert.component";
import { IncreasePlanAlertComponent } from "../modal-component/increase-plan-alert/increase-plan-alert.component";
import { HospitalNetworkListComponent } from "../modal-component/hospital-network-list/hospital-network-list.component";
import { CheckValidPincodeComponent } from "../modal-component/check-valid-pincode/check-valid-pincode.component";
import { ShareProposalPopupComponent } from "../modal-component/modal-component-v2/share-proposal-popup/share-proposal-popup.component";
import { PlanNotAvailableComponent } from "../modal-component/plan-not-available/plan-not-available.component";
import { PincodeNotServiceableComponent } from "../modal-component/pincode-not-serviceable/pincode-not-serviceable.component";
import { ShareLinkWithCustomerComponent } from "../modal-component/share-link-with-customer/share-link-with-customer.component";
import { AgeChangeComponent } from "../modal-component/age-change/age-change.component";
import { BmiValidationComponent } from "../modal-component/bmi-validation/bmi-validation.component";
import { PriceBreakupPopupComponent } from "../modal-component/modal-component-v2/price-breakup-popup/price-breakup-popup.component";
import { HospitalDetailsComponent } from "../modal-component/modal-component-v2/hospital-details/hospital-details.component";
import { EazypayPaymentOptionComponent } from "../modal-component/eazypay-payment-option/eazypay-payment-option.component";
import { MagmaRenewalsPopupComponent } from "../modal-component/magma-renewals-popup/magma-renewals-popup.component";
import { MagmaTopupComponent } from "../modal-component/magma-topup/magma-topup.component";
import { BimapayTenMonthPopComponent } from "../modal-component/bimapay-ten-month-pop/bimapay-ten-month-pop.component";
@Injectable({
  providedIn: "root",
})
export class CommonModalService {
  dialogConfig = new MatDialogConfig();

  constructor(public dialog: MatDialog, private overlay: Overlay) {
    this.dialogConfig.height = "50%";
    this.dialogConfig.width = "50%";
    // Over-riding default data value for modal window - if required
    this.dialogConfig.maxWidth = "90vw";
    this.dialogConfig.maxHeight = "100vh";
    this.dialogConfig.position = {
      top: "5%",
      left: "auto",
    };
    this.dialogConfig.minWidth = "50%";
  }

  openDialog(obj: DialogData): Observable<any> {
    let childComponent: any = null;
    const { width, height, minWidth, classNameObtained, dataInfo } = obj;
    const scrollStrategy = this.overlay.scrollStrategies.reposition();
    this.dialogConfig.data = dataInfo;

    /**
     * These need to be changed  as number should be configurable.
     * Curently it is not .
     * TODOS- On urgent basis need to change
     */
    if (width) {
      this.dialogConfig.width = width;
    }
    if (height) {
      this.dialogConfig.height = height;
    }
    if (minWidth) {
      this.dialogConfig.minWidth = minWidth;
    }
    if (classNameObtained) {
      this.dialogConfig.panelClass = classNameObtained;
    }

    if (this.dialogConfig.data["top"]) {
      this.dialogConfig.position = {
        top: this.dialogConfig.data["top"],
      };
    }

    if (this.dialogConfig.data["left"]) {
      this.dialogConfig.position = {
        left: this.dialogConfig.data["left"],
      };
    }

    // if (obj.type === 1) {
    //   childComponent = ChangePlanComponent;
    // }

    if (obj.type === 2) {
      childComponent = OtpComponent;
    }

    if (obj.type === 3) {
      childComponent = ErrorMessageComponent;
    }

    if (obj.type === 4) {
      childComponent = BasicModalComponent;
    }
    if (obj.type === 5) {
      childComponent = ShareModalComponent;
    }
    if (obj.type === 6) {
      childComponent = IncorretMobileNumberComponent;
    }
    if (obj.type === 7) {
      childComponent = ChoosePolicyComponent;
    }
    if (obj.type === 9) {
      childComponent = RelationPopupComponent;
    }
    if (obj.type === 10) {
      childComponent = HealthDeclarationPopupComponent;
    }
    if (obj.type === 11) {
      childComponent = OtpVerificationPopupComponent;
    }
    if (obj.type === 13) {
      childComponent = EnterMobilePopupComponent;
    }
    // if (obj.type === 14) {
    //   childComponent = CustomerChangePlanComponent;
    // }
    // if (obj.type == 18) {
    //   childComponent = CsvSanityComponent;
    // }
    if (obj.type === 20) {
      childComponent = AdharVerificationPopupComponent;
    }

    if (obj.type == 21) {
      childComponent = PreviousPolicyAlertComponent;
    }
    if (obj.type == 22) {
      childComponent = IncreasePlanAlertComponent;
    }
    if (obj.type == 23) {
      childComponent = HospitalNetworkListComponent;
    }
    if (obj.type == 24) {
      childComponent = CheckValidPincodeComponent;
    }
    if (obj.type == 25) {
      childComponent = PincodeNotServiceableComponent;
    }
    if (obj.type == 26) {
      childComponent = PlanNotAvailableComponent;
    }
    if (obj.type == 27) {
      childComponent = ShareProposalPopupComponent;
    }
    if (obj.type == 28) {
      childComponent = ShareLinkWithCustomerComponent;
    }
    if (obj.type == 29) {
      childComponent = AgeChangeComponent;
    }
    if (obj.type == 30) {
      childComponent = BmiValidationComponent;
    }
    if (obj.type == 31) {
      childComponent = PriceBreakupPopupComponent;
    }
    if (obj.type == 32) {
      childComponent = HospitalDetailsComponent;
    }
    if(obj.type == 33) {
      childComponent =  EazypayPaymentOptionComponent;
    }
    if(obj.type == 34) {
      childComponent =  MagmaRenewalsPopupComponent;
    }
    if(obj.type == 35) {
      childComponent =  MagmaTopupComponent;
    }
    if(obj.type == 36) {
      childComponent =  BimapayTenMonthPopComponent;
    }

    if (childComponent === null) {
      return null as any;
    }

    const dialogRef = this.dialog.open(childComponent, {
      data: this.dialogConfig.data,
      position: this.dialogConfig.position,
      width: this.dialogConfig.width,
      height: this.dialogConfig.height,
      minWidth: this.dialogConfig.minWidth,
      panelClass: this.dialogConfig.panelClass,
      scrollStrategy,
    });

    return dialogRef.afterClosed();
  }
  /***
   * get Device Type function
   * **/
  DeviceTypeChoices(DeviceType) {
    let deviceNumber = 0;
    switch (DeviceType.toUpperCase()) {
      case "MOBILE":
        deviceNumber = 1;
        break;
      case "DESKTOP":
        deviceNumber = 2;
        break;
      case "LAPTOP":
        deviceNumber = 3;
        break;
      case "TABLET":
        deviceNumber = 4;
        break;
      default:
    }
    return deviceNumber;
  }
  /***get os type */
  OSTypeChoices(ostype) {
    let osNumber = 0;
    switch (ostype.toUpperCase()) {
      case "ANDROID":
        osNumber = 1;
        break;
      case "WINDOWS":
        osNumber = 2;
        break;
      case "MAC":
        osNumber = 3;
        break;
      case "LINUX":
        osNumber = 4;
        break;
      default:
    }
    return osNumber;
  }
  /**
   * get brower type
   * ****/
  BrowserTypeChoices(browserType) {
    let browerNumber = 0;
    switch (browserType.toUpperCase()) {
      case "FIREFOX":
        browerNumber = 1;
        break;
      case "CHROME":
        browerNumber = 2;
        break;
      case "INTERNET_EXPLORER":
        browerNumber = 3;
        break;
      case "SAFARI":
        browerNumber = 4;
        break;
      case "OPERA":
        browerNumber = 5;
        break;
      case "UC_BROWSER":
        browerNumber = 6;
        break;
      case "EDGE":
        browerNumber = 7;
        break;
      case "SAMSUNG_INTERNET":
        browerNumber = 8;
        break;
      default:
    }
    return browerNumber;
  }
  /**get plat from type**/
  PlatfromType() {
    let platfromNumber = 0;
    let platfromtype = localStorage.getItem("is_web_user");
    switch (platfromtype) {
      case "True":
        platfromNumber = 3;
        break;
      default:
        platfromNumber = 1;
        break;
    }
    return platfromNumber;
  }
  clearStorage() {
    return localStorage.clear();
  }
  checkAgentToken(token) {
    let flag;
    switch (token) {
      case "undefined":
      case undefined:
      case null:
        flag = false;
        break;
      default:
        flag = true;
    }
    return flag;
  }
}
