<div id="eazypay-page">
<ng-container *ngIf="proceedJourney === false">
  <div >
  <div class="row">
    <div class="proposer-close-row col-12">
      <img src="assets/images/eazypay-images/Vector.png" alt="close" class="close-icon" (click)="closeDialog()">
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-12 proposer-col-3">
        <img src="../../../assets/images/eazypay-images/why-choose-eazypay.png" alt="Why Choose EazyPay" class="info-image" />
        <div class="overlay-text web-only">
          Powered by <img src="../../../assets/images/eazypay-images/Main Logo (1) 1.svg" alt="Logo" />
        </div>
      </div>
      <div class="col-12 proposer-col-9">
        <div class="row">
          <div class="col-12">Please fill the information below to get the best possible quotes.</div>
        </div>
        <!-- Right Section (Form) -->
        <form class="mt-2" [formGroup]="eazypayForm" >
          <div class="row">
            <div class="col-12 col-md-6 form-label">
              <mat-form-field class="custom-mat-form-field" appearance="outline" class="col-12" >
                <mat-label class="label-style">Name (As per PAN)</mat-label>
                <input class="text-capitalize"  matInput placeholder="Jay Singh" formControlName="name">
                <mat-error *ngIf="eazypayForm.get('name')?.hasError('required')"> This is a required field. </mat-error>
                <mat-error *ngIf="eazypayForm?.get('name')?.hasError('minlength')"> Name should contain Minimum 3 characters </mat-error> 
                <mat-error *ngIf="eazypayForm.get('name') ?.hasError('pattern')  && !eazypayForm.get('name')?.hasError('minlength')"> Only characters are allowed </mat-error>
              </mat-form-field>
            </div>
            
            <div class="col-12 col-md-6 form-label">
              <div class="gender-buttons">
                <button *ngFor="let gender of genders" class="gender-button" [disabled]="disableGenderButtons" [class.active]="selectedGender === gender?.value" (click)="selectGender(gender?.value)">
                  <img [src]="gender.icon" [alt]="gender.label" class="gender-icon">
                  {{ gender?.label }}
                </button>
              </div>
            </div>

          </div>

          <div class="row">
            <div class="col-12 col-md-6 form-label">
              <mat-form-field class="custom-mat-form-field" appearance="outline" class="col-12">
                <mat-label class="label-style">Contact Number</mat-label>
                <input matInput placeholder="9999999999" formControlName="mobile" appOnlyNumbers [minLength]="10" [maxLength]="10" >
                <mat-error *ngIf="eazypayForm.get('mobile')?.hasError('required')"> This is a required field. </mat-error>
                <mat-error class="text-font" *ngIf="eazypayForm?.get('mobile')?.hasError('pattern')"> Invalid Number </mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6 form-label">
              <mat-form-field class="custom-mat-form-field" appearance="outline" class="col-12">
                <mat-label class="label-style" >Date of Birth (D.O.B)</mat-label>
                <input matInput [matDatepicker]="dob" placeholder="01/01/1990" appDateFormat formControlName="dob" [min]="minDate" [max]="maxDate" >
                <div>
                  <span *ngIf="eazypayForm.get('dob')?.value !== 0 " class="age">
                    {{ this.eazypayForm.get("dob")?.value | dateToAge }} Years
                  </span>
                </div>
                <mat-datepicker-toggle matSuffix [for]="dob"></mat-datepicker-toggle>
                <mat-datepicker-toggle matSuffix [for]="dob"></mat-datepicker-toggle>
                <mat-datepicker #dob></mat-datepicker>
                <mat-error *ngIf=" eazypayForm.get('dob')?.hasError('required') && !eazypayForm.get('dob')?.hasError('validAgeFormat') "> Please enter your D.O.B. </mat-error>
                <mat-error *ngIf=" eazypayForm.get('dob')?.hasError('matDatepickerMin') "> Maxium allowed age is up to 65 Years. </mat-error>
                <mat-error *ngIf=" eazypayForm.get('dob')?.hasError('matDatepickerMax') "> Must be 18 years or above. </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6 form-label">
              <mat-form-field class="custom-mat-form-field" appearance="outline" class="col-12">
                <mat-label class="label-style">PAN</mat-label>
                <input matInput placeholder="FPDCS1234F"  formControlName="pan" [minLength]="10" [maxLength]="10"  (input)="panAlphaNumericValue($event)">
                <mat-error *ngIf="eazypayForm.get('pan')?.hasError('required')"> This is a required field. </mat-error>
                <mat-error *ngIf="eazypayForm.get('pan')?.hasError('minlength') && !eazypayForm.get('pan')?.hasError('required') && !eazypayForm.get('pan')?.hasError('pattern')"> Pan must be at least 10 characters long. </mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6 form-label" *ngIf="hostPath === 'test.rbstaging.in' || hostPath === 'rbfinance.rbstaging.in'"  >
              <mat-form-field class="custom-mat-form-field" appearance="outline" class="col-12">
                <mat-label class="label-style">Original PAN</mat-label>
                <input matInput class="text-capitalize" placeholder="FPDCS1234F"  formControlName="original_pan" [minLength]="10" [maxLength]="10" >
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6 form-label">
              <mat-form-field appearance="outline" class="col-12">
                <mat-label class="label-style">Pincode</mat-label>
                <input matInput placeholder="122010" [maxLength]="6" formControlName="pincode" appOnlyNumbers (ngModelChange)="getPincode($event)" >
                
                <mat-error *ngIf="eazypayForm.get('pincode')?.hasError('required')"> This is a required field. </mat-error>
                <mat-error *ngIf="eazypayForm.get('pincode')?.hasError('pattern') && !eazypayForm.get('pincode')?.hasError('required')  && !eazypayForm.get('pincode')?.hasError('minlength')"> Pincode must contain only numbers. </mat-error>
                <mat-error *ngIf="eazypayForm.get('pincode')?.hasError('minlength') && !eazypayForm.get('pincode')?.hasError('required')"> Pincode must be at least 6 characters long. </mat-error>
                <mat-error *ngIf=" eazypayForm.get('pincode')?.hasError('customError') "> {{ eazypayForm.get('pincode')?.getError('customError') }}</mat-error>
              </mat-form-field>
              
            </div>
          </div>
          <div class="row form-actions">
            <div class="col-12 button-container">
                <button mat-button type="submit" class="eligibility-button" [ngClass]="{'disabled-eligibility-button': eazypayForm.invalid || !getStatefromPincode || buttonDisabled }" [disabled]="eazypayForm.invalid || !getStatefromPincode || buttonDisabled " (click)="submitEazyPayForm(eazypayForm.valid)"  > Check EZPay Eligibility </button>
                <button mat-button class="continue-with-fullpayment-btn" (click)="closeDialog()"> Continue with Full Payment </button>
            </div>
          </div>
        </form>
        <div class="row mt-3">
          <div class="col-12">
            <div class="proposer-footer-text">*Please review the information entered above for better experience</div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="overlay-text mobile-only">
            Powered by <img src="../../../assets/images/eazypay-images/Main Logo (1) 1.svg" alt="Logo" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</ng-container>

<ng-container *ngIf="proceedJourney === true">
  <div class="row">
    <div class="proposer-close-row col-12">
      <img src="assets/images/eazypay-images/Vector.png" alt="close" class="close-icon" (click)="closeDialog()">
    </div>
  </div>
  <div class="eazypay-container" >
    <div class="col-3 congragulation-img">
      <div class="congratulations-text" *ngIf="storeEazyPayResponse === true">Congratulations</div>
      <div class="eazypay-image-container">
        <img [src]="storeEazyPayResponse === true ? 'assets/images/eazypay-images/EP Congrats Graphic.svg' : 'assets/images/eazypay-images/easypay-not-eligible.svg'" 
        alt="Badge" class="svg-image" />
        <img *ngIf="storeEazyPayResponse === true" src="assets/images/eazypay-images/EP.gif" alt="Badge" class="gif-image" />
        <!-- <div class="congrats-overlay-text web-only">
          Powered by <img src="../../../assets/images/eazypay-images/Main Logo (1) 1.svg" alt="Logo" />
        </div> -->
      </div>
      <div class="congrats-overlay-text web-only">
        Powered by <img src="../../../assets/images/eazypay-images/Main Logo (1) 1.svg" alt="Logo" />
      </div>
    </div>
    <div class="col-9 proposer-content">
      <div class="row">
        <!-- <div class="col-9" style="font-weight: 500;font-size: 20px;line-height: 24px;color: #282828; text-align: left;">Your customer is eligible for EazyPay!</div>
        <div class="col-3" style="color: #5384EA;">Refill & Recheck</div> -->
        <div class="col-12 proposer-header">
          <div class="proposer-title" > {{storeEazyPayResponse === true ? 'Your customer is eligible for EZPay!' : 'We’re sorry but you’re not eligible for EZPay, yet!' }} </div>
          <div class="proposer-refill" *ngIf="!paymentDataObject" (click)="editEazyPayForm()"> Refill & Recheck </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6 proposer-label">Name (As per PAN)</div>
        <div class="col-6 proposer-label">Gender</div>
      </div>
      <div class="row">
        <div class="col-6 text-capitalize proposer-detail text-truncate">{{easyPayEligibilityConfirmData?.full_name}}</div>
        <div class="col-6 proposer-detail">{{easyPayEligibilityConfirmData?.gender}}</div>
      </div>
      <div class="row mt-3">
        <div class="col-6 proposer-label"> Contact Number</div>
        <div class="col-6 proposer-label">Date of Birth (D.O.B)</div>
      </div>
      <div class="row">
        <div class="col-6 proposer-detail">{{easyPayEligibilityConfirmData?.phone_no}}</div>
        <div class="col-6 proposer-detail">{{easyPayEligibilityConfirmData?.dob | date:'dd/MM/yyyy' }} 
          <span class="age-class">{{ easyPayEligibilityConfirmData?.dob | dateToAge }} years </span>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6 proposer-label">PAN</div>
        <div class="col-6 proposer-label">Pincode</div>
      </div>
      <div class="row">
        <div class="col-6 proposer-detail">{{easyPayEligibilityConfirmData?.PAN}}</div>
        <div class="col-6 proposer-detail">{{easyPayEligibilityConfirmData?.pincode}}</div>
      </div>
      <div class="row mt-3">
        <div class="col-12 button-container">
          <button mat-button type="submit" [ngClass]="storeEazyPayResponse === true && baseURl === 'rbhealth-renewals' && urlEndPoint === 'review' ? 'check-payment-option-button' : 'confirm-eligibility-button'"  [ngClass]="" (click)="proceed(storeEazyPayResponse)">
            {{ storeEazyPayResponse === true ? ((baseURl === 'rbhealth-renewals' && urlEndPoint === 'review') ? 'Check Payment Options' : 'Continue with EZPay') : 'Continue with Full Payment' }}
          </button>
          <button  mat-button class="confirm-with-fullpayment-btn" *ngIf="storeEazyPayResponse === true && urlEndPoint !== 'review'"  (click)="proceed(false)"> Continue with Full Payment</button>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <div class="proposer-footer-text">*Please review the information entered above for better experience</div>
          <div class="proposer-footer-text">*Keep Your Aadhar & PAN ready </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="overlay-text mobile-only">
          Powered by <img src="../../../assets/images/eazypay-images/Main Logo (1) 1.svg" alt="Logo" />
        </div>
      </div>
    </div>
  </div>
</ng-container>
</div>