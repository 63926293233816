import {
  Component,
  OnInit,
  Optional,
  Inject,
  ViewEncapsulation,
  PLATFORM_ID,
  ViewChild,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogData } from "../../model/data.model";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { timer } from "rxjs";
import { ApiService } from "../../services/api.service";
import { isPlatformBrowser } from "@angular/common";
import { ApiConstants } from "src/app/api.constant";
import { SharedDataService } from "src/app/services/shared-data.service";
import { environment } from "src/environments/environment";
declare const webengage: any;


@Component({
  selector: "app-otp",
  templateUrl: "./otp.component.html",
  styleUrls: ["./otp.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class OtpComponent implements OnInit {
  otp: Boolean = true;
  @ViewChild("ngOtpInput", { static: false }) ngOtpInput: any;
  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: "",
    inputStyles: { width: "40px", height: "40px" },
    inputClass: { borderradius: "25px" },
  };

  webengageData :any;
  mobile: string;
  orderId: any;
  time = 90;
  validOtp: boolean = false;
  errorMessage!: string;
  otpNumber: any | undefined;
  submitOtp: boolean = false;
  bimaPayApiCall: any;
  baseURl : any;

  constructor(
    private apiService: ApiService,
    private formBuild: FormBuilder,
    private sharedDataService: SharedDataService,
    private dialogRef: MatDialogRef<OtpComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData | any,
    @Inject(PLATFORM_ID) private platformId: Object // Inject PLATFORM_ID
  ) {
    this.webengageData = data;
    this.baseURl = location.pathname;
    dialogRef.disableClose = false;
    this.mobile = data["mobile"];
    this.orderId = data["order_id"];
    this.bimaPayApiCall = data["bima_payment_period"];
  }

  onOtpChange(event: any) {
    const enteredValue = event;
    const numericValue = enteredValue.replace(/\D/g, "");
    this.otpNumber = numericValue;
    if (this.otpNumber.length >= 6) {
      this.submitOtp = true; // Enable OTP submission
    } else {
      this.submitOtp = false; // Disable OTP submission
    }
  }

  // Function to check if platform is mobile
  isMobilePlatform() {
    return (
      isPlatformBrowser(this.platformId) &&
      /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        navigator.userAgent.toLowerCase()
      )
    );
  }

  submitOtpForm(otpnumber) {
    if (otpnumber.length <= 6) {
      const data = {
        otp: otpnumber,
        mobile_no: this.mobile,
        order_id: Number(this.orderId),
      };
      this.verifyOTP(data);
    }
  }

  // reset Timer
  resetTimer() {
    this.time = 90;
  }

  closeDialog() {
    this.dialogRef.close({ event: "close" });
  }

  verifyOTP(data ) {

                  /**web engage start**/
                  if (environment?.dev ) {
                    webengage.track("RB_Health_OTP_Submitted", {
                      Plan_Selected: this.webengageData?.Plan_Selected,
                      User_Type: this.webengageData?.User_Type,
              
                    });
                  }
                  /**web engage end**/

    this.submitOtp = false;
    const url = "/api/v2/otp/validate_otp/";
    this.apiService.postRequestedResponse(url, data).subscribe((res: any) => {
      if (res && res["valid"]) {
        this.validOtp = true;
        if(this.bimaPayApiCall === null){
          this.dialogRef.close({ event: "close",  data: res });
        } else {
          this.eazypayCreateWithPlanApi(data?.order_id);
        }
      } else if (res["message"]) {
        this.errorMessage = res["message"];
      } else {
        this.errorMessage = "Invalid OTP";
      }
    });
  }

  eazypayCreateWithPlanApi(orderId) {
    const paymentDataObject = this.sharedDataService.getPaymentData();
    const data: any = {
      plan_id: Number(paymentDataObject?.plan_id),
      bima_pay_id: paymentDataObject?.eazyPayLeadId,
      bima_payment_period: paymentDataObject?.selectedPaymentMonth,
      order_id:orderId,
    };
    this.apiService .postRequestedResponse(`${ApiConstants.UPDATE_EAZYPAY_ID}`, data) ?.subscribe((response: any) => {
        if (response) {
          const eazyPayData = {
            insurer: "EazyPay",
            order_id: orderId,
            eazyPayLeadId: paymentDataObject?.eazyPayLeadId,
            selectedPaymentMonth: paymentDataObject?.selectedPaymentMonth,
            eazypay_redirectURL: response?.shortURL,
          };
          
          this.sharedDataService.storePaymentData(eazyPayData);
        this.dialogRef.close({ event: "close",  data: {valid: true,eazypay_redirectURL: response?.shortURL,} });

        }
      });
  }



  initateOTP() {
    this.resetTimer();
    const url = "/api/v2/otp/create_otp/";
    const data = {
      order_id: this.orderId,
      mobile_no: this.mobile,
    };
    this.apiService.postRequestedResponse(url, data).subscribe((res: any) => {
      if (res) {
        const otpres = res;
      }
    });
  }

  ngOnInit(): void {
    timer(0, 1000).subscribe((ellapsedCycles: any) => {
      if (this.time > 0) {
        this.time--;
      }
    });
  }
}
