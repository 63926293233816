<div id="bmi-popup">
<div class="row">
  <div class="close-icon">
    <span class="cursor" (click)="closeDialog()"> &#x2715;</span>
  </div>
  <div class="col-12 text-center d-flex flex-column align-items-center">
    <div class="popup-text">Insured member’s BMI is outside acceptable limit.</div>
    
    <div class="popup-text">Please try again later.</div>
    
  </div>
  <div class="col-12 center">
    <button
      class="submit-btn close-submit-button"
      type="submit"
      mat-button
      (click)="editDetails()"
    >
      Edit Details
    </button>
  </div>
</div>

</div>