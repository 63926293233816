<div class="dialog-content-box customermobile">
    <div class="row">
      <div mat-dialog-actions *ngIf="!customer_journey">
        <div mat-button mat-dialog-close class="close-popup" (click)="closePopup()">&#x2715;</div>
      </div>
  
      <div class="col-12 text-center">
       
        <div class="text-center main-heading">{{message}}</div>
        <br />
        <div class="text-center main-heading">Your plan details</div>
        <div class="popup-policy-dtl-card ">
          <div class="popup-policy-dtl-flex">
            <div>
              <div class="gold-btn">{{newPlanData?.plan}}</div>
              <div class="change-plan">
                <div class="amount-rate">
                <div class="amt-text">&#8377; {{newPlanData?.amount}}</div>
                <div class="amount-txt">Premium</div>
              </div>
              </div>
            </div>
            <div class="plan-type">
              <div class="self_spouse">{{newPlanData?.sub_title}}</div>
              <div class="fivelac_opd">{{newPlanData?.sum_assured}}</div> 
              <div class="sum-assured">Sum Insured</div>
             </div> 
            <div class="plan-date">
              <!-- <div class="plan-id">Policy No: {{oldPlanData?.policy_no}}</div> -->
              <div class="plan-id">Issue Date: {{oldPlanData?.issue_date| date:'longDate'}}</div>
              <div class="plan-id">Due Date: {{oldPlanData?.due_date| date:'longDate'}}</div>
  
            </div>
         
          </div>
        </div>
        <div class="text-center">
        <div mat-dialog-close class="submit-btn proceedBtn" (click)="yesProcced()">
          Proceed
        </div>
      </div>
      </div>
      <br/>
        
     
    </div>
  </div>