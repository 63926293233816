<div class="dialog-content-box text-center">
    <div class="col-12 my-3">
        <svg width="53" height="72" viewBox="0 0 53 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2372_1586)">
                <path d="M0 35.9289C0 25.7334 0 15.5428 0 5.34734C0.00486462 1.5522 1.52749 0.0194511 5.29757 0.0145542C14.5306
                -0.000136716 23.7685 -0.0295185 33.0016 0.0635238C34.0961 0.0733177 35.3804 0.558117 36.2365 1.24859C41.4222
                5.4208 46.5447 9.67136 51.5795 14.0248C52.3481 14.6907 52.9368 16.0276 52.9416 17.056C53.0243 33.6126 53.0049
                50.1643 52.9951 66.7209C52.9951 70.4671 51.4433 71.9901 47.6538 71.995C33.5561 72.0048 19.4633 72.0097
                5.36567 71.995C1.45452 71.995 0.00972923 70.4965 0.00486462 66.5104C0.00486462 56.3149 0.00486462 46.1243
                0.00486462 35.9289H0ZM33.3761 2.15942C32.3497 2.10556 31.7124 2.04679 31.0752 2.04679C22.8102 2.0419
                14.5452 2.037 6.28022 2.04679C2.54906 2.04679 2.13557 2.47283 2.13557 6.28756C2.1307 26.1937 2.13557
                46.0949 2.13557 66.0011C2.13557 69.429 2.66095 69.9676 6.07591 69.9676C19.692 69.9725 33.3032 69.9676
                46.9192 69.9627C50.4023 69.9627 50.8693 69.4926 50.8693 66.0207C50.879 50.4386 50.8839 34.8613 50.879
                19.2792C50.879 18.481 50.8061 17.6828 50.7525 16.6446C45.8296 16.6446 41.0671 16.5124 36.3095 16.6985C33.9112
                16.7915 33.2302 15.9737 33.3324 13.6575C33.4977 9.85744 33.3761 6.04271 33.3761 2.16432V2.15942ZM48.797
                14.4165C44.1804 10.5773 40.0309 7.12984 35.5993 3.44732V14.5781C39.0872 14.5781 42.4584 14.5879 45.8247
                14.5732C46.5982 14.5732 47.3668 14.4949 48.7921 14.4214L48.797 14.4165Z" fill="#E7C37D"/>
            </g>
            <g clip-path="url(#clip1_2372_1586)">
                <path d="M27.3849 50.3576C30.6233 50.5337 33.7487 52.3113 35.5454 56.0053C35.9112 56.7526 35.9381 57.943
                35.5669 58.6476C35.3625 59.0373 33.9423 59.0853 33.2 58.8665C28.6975 57.5373 24.2434 57.5426 19.7409
                58.8718C18.9985 59.0907 17.729 59.048 17.3578 58.5996C16.949 58.1031 17.0674 56.9074 17.3578 56.1707C18.7296
                52.7063 22.5704 50.3362 27.3795 50.3576H27.3849Z" fill="#E7C37D"/>
                <path d="M41 33.6176C37.4443 29.0001 36.0188 29.0001 31.4194 33.2652C31.4356 30.6336 33.2323
                29.0215 36.164 29.0108C39.1711 29.0001 40.9355 30.5482 41 33.6229V33.6176Z" fill="#E7C37D"/>
                <path d="M21.4676 33.2918C18.2078 29.4378 16.3358 28.4502 12 33.2812C11.9946 30.516 14.0657
                28.8185 17.2126 29.016C20.0421 29.1922 21.699 30.8577 21.4676 33.2918Z" fill="#E7C37D"/>
            </g>
            <defs>
                <clipPath id="clip0_2372_1586">
                    <rect width="53" height="72" fill="white"/>
                </clipPath>
                <clipPath id="clip1_2372_1586">
                    <rect width="29" height="30" fill="white" transform="translate(12 29)"/>
                </clipPath>
            </defs>
        </svg>
    </div>

    <div class="row justify-content-center mt-3">
        <div class="col-12 font-size-30">Oops!</div>
        <div class="col-12">You can not take this policy basis your medical declaration.</div>
    </div>

</div>


