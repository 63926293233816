import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "../../services/api.service";
import { Location, LocationStrategy } from "@angular/common";

@Component({
  selector: "app-payment-loader",
  templateUrl: "./payment-loader.component.html",
  styleUrls: ["./payment-loader.component.scss"],
})
export class PaymentLoaderComponent implements OnInit {
  @Input("order_id") order_id: any;
  @Input() plan: any;

  constructor(
    private locationStrategy: LocationStrategy,
    private apiService: ApiService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getOrderStatus();
  }
  /**
   *  check order status
   **/
  getOrderStatus() {
    let url;
    if (
      location.pathname == "/rblife/" ||
      location.pathname == "/renewbuy-life/"
    ) {
      url = `/api/v2/rblifeorder/order_status/?order_id=${this.order_id}`;
    } else {
      url = `/api/v1/order/order_status/?order_id=${this.order_id}`;
    }
    // const url = `/api/v1/order/order_status/?order_id=${this.order_id}`;
    this.apiService.getRequestedResponse(url).subscribe((res: any) => {
      if (res.status == "success") {
        this.router.navigate([ `/payment-success/${this.plan}/${this.order_id}`, ]);
      } else if (res.status == "failure") {
        this.router.navigate([
          `/payment-failure/${this.plan}/${this.order_id}`,
        ]);
      }
    });
  }

  refresh() {
    this.getOrderStatus();
  }
}
