import {
  Component,
  Inject,
  inject,
  OnInit,
  Optional,
  PLATFORM_ID,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../../model/data.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { timer } from 'rxjs';
import { ApiService } from '../../services/api.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { isPlatformBrowser } from '@angular/common';
import { ApiConstants } from 'src/app/api.constant';
import { Border } from '@syncfusion/ej2-angular-charts';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-adhar-verification-popup',
  templateUrl: './adhar-verification-popup.component.html',
  styleUrls: ['./adhar-verification-popup.component.scss'],
})
export class AdharVerificationPopupComponent implements OnInit {
  otp: Boolean = true;
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: { width: '40px', height: '40px', borderRadius: '25px' },
  };

  


  mobile: any;
  aadhaar_id: any;
  ref_id: any;
  widthObtained;
  time = 30;
  validOtp: boolean = false;
  errorMessage!: string;
  otpNumber: any | undefined;
  submitOtp: boolean = false;
  isUser: boolean = false;
  fetchedData: boolean = false;
  otplLoader : boolean = false;

  constructor(
    private apiService: ApiService,
    private formBuild: FormBuilder,
    private sharedDataService: SharedDataService,
    private dialogRef: MatDialogRef<AdharVerificationPopupComponent>,
    private snackBar: MatSnackBar,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData | any,
    @Inject(PLATFORM_ID) private platformId: Object, // Inject PLATFORM_ID
  ) {
    this.ref_id = data['aadhaar_data']['ref_id']
    dialogRef.disableClose = true;
  }


  /** Event handler for OTP input change.**/
  onOtpChange(event: any) {
    const enteredValue = event;
    const numericValue = enteredValue.replace(/\D/g, '');
    this.otpNumber = numericValue;
    if (this.otpNumber.length >= 6) {
      this.submitOtp = true; // Enable OTP submission
    } else {
      this.submitOtp = false; // Disable OTP submission
    }
  }

  // Function to check if platform is mobile
  isMobilePlatform() {
    return (
      isPlatformBrowser(this.platformId) &&
      /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        navigator.userAgent.toLowerCase(),
      )
    );
  }

  // Submit AADHAR OTP
  submitOtpForm(otpnumber) {
    if (otpnumber.length <= 6) {
      const data = {
        otp: otpnumber,
        ref_id: this.ref_id,
      };
      this.verifyOTP(data);
    }
  }

  // reset Timer
  resetTimer() {
    this.time = 60;
    this.ngOtpInput.setValue('');
  }

  //Resend OTP
  initateOTP() {
    this.otplLoader = true
    this.resetTimer()
    let data = {
      adhaar: this.data['aadhaar_data']['aadhaar_number']?.replace(/\s/g, ''),
    };
    this.apiService .postRequestedResponse(`${ApiConstants.Aadhaar_OTP}`,data, '', false, true) .subscribe(
        (res: any) => {
          if (res && res.status == "SUCCESS") {
            this.ref_id = res['ref_id']
            this.otplLoader = false;
            
            this.snackBar.open(`${res?.message}`, 'Ok', {
              duration: 3000,
              verticalPosition: 'top',
              horizontalPosition:'right'
            });
          } else {
             this.otplLoader = false
          }
        },
      );
  }

  closeDialog() {
    this.dialogRef.close({ event: 'close' });
  }

  /**
   * verify otp on the basis of aadhar monile no
   *
   * **/
  verifyOTP(data) {
    this.fetchedData = true;
    
    this.apiService .postRequestedResponse( `${ApiConstants.Aadhaar__VERIFICATION_OTP}`, data, false, false, true, false, false, ) .subscribe( (res: any) => {
          if (res['status']) {
            this.fetchedData = false;
            this.otplLoader =false;
            this.validOtp = true;
            this.dialogRef.close({ event: 'close', data: res });
            this.errorMessage = "";
            this.sharedDataService.saveAadhaarData(res);
          } else if (res['message']) {
            this.fetchedData = false;
            this.otplLoader =false;
            this.ngOtpInput.setValue('');
            this.errorMessage = res['message'];
            this.submitOtp = false;
          } else {
            this.fetchedData = false;
            this.submitOtp = false;
            this.otplLoader =false;
            this.ngOtpInput.setValue('');
            this.errorMessage = 'Invalid OTP';
          }
        },
        (error) => {
          this.fetchedData = false;
          this.otplLoader =false;
          error.isOtp = true;
          this.apiService.errorHandler(error);
          this.submitOtp = false;

        },
      );
  }

  // to close the popup when click on cancel button
  closePopup() {
    this.dialogRef.close({ event: 'close' });
  }

  ngOnInit(): void {
    timer(0, 1000).subscribe((ellapsedCycles: any) => {
      if (this.time > 0) {
        this.time--;
      }
    });
  }
}


