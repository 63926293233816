<ng-container *ngIf="memberSwapped === true">
  <div class="row">
    <div class="close-icon">
      <span class="cursor" (click)="closeDialog()"> &#x2715;</span>
    </div>
    <div class="col-12 text-center d-flex flex-column align-items-center">
      <div>
        The age of the eldest member is changed to "{{
          plan_data_response["included_members"][0]["dob"] | dateToAge
        }}
        Years" <br />
        to "{{ plan_data_response["customer"]["dob"] | dateToAge }} Years", Do
        you want to continue?
      </div>
      <br />
    </div>
    <div class="col-12 center">
      <button
        class="submit-btn close-submit-button change-btn"
        type="submit"
        mat-button
        (click)="closeDialog()"
      >
        Back</button
      >&nbsp;
      <button
        class="submit-btn close-submit-button"
        type="submit"
        mat-button
        (click)="proceedJourney()"
      >
        Continue
      </button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="noPlan === true">
  <div class="row">
    <div class="close-icon">
      <span class="cursor" (click)="closeDialog()"> &#x2715;</span>
    </div>
    <div class="col-12 text-center d-flex flex-column align-items-center">
      <!-- <div>
        The age of the eldest member is changed to "{{
          plan_data_response["included_members"][0]["dob"] | dateToAge
        }}
        Years" <br />
        to "{{ plan_data_response["customer"]["dob"] | dateToAge }} Years"
      </div> -->
      <div>{{ plan_data_response["status"] }}</div>
      <br />
    </div>
    <div class="col-12 center">
      <button
        class="submit-btn close-submit-button change-btn"
        type="submit"
        mat-button
        (click)="closeDialog()"
      >
        Back</button
      >&nbsp;
      <!-- <button class="submit-btn close-submit-button" type="submit" mat-button (click)="proceedJourney()">Continue</button> -->
    </div>
  </div>
</ng-container>

<ng-container *ngIf="planMemberSwapped === true">
  <div class="row">
    <div class="close-icon">
      <span class="cursor" (click)="closeDialog()"> &#x2715;</span>
    </div>
    <div class="col-12 text-center d-flex flex-column align-items-center">
      <div>
        The premium has been revised as the eldest member's age <br />is changed
        to "{{
          plan_data_response["included_members"][0]["dob"] | dateToAge
        }}
        Years" to "{{ plan_data_response["customer"]["dob"] | dateToAge }}
        Years"
      </div>
      <br />
    </div>
    <div
      class="col-12 text-center d-flex flex-column align-items-center revised-premium-text"
    >
      <div>Revised Premium</div>
    </div>
    <ng-container>
      <div class="plan-card">
        <div class="plan-flex">
          <ng-container>
            <div class="insurance-company">
              <div>
                <ng-container
                  *ngFor="
                    let logo of plan_data_response.plan_data.logo;
                    let last = last
                  "
                >
                  <div class="logo-container">
                    <img [src]="logo" style="width: 100px" />
                    <span *ngIf="!last" class="plus-icon">+</span>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="plan-detail">
              <div class="plan-name">
                <strong>{{
                  plan_data_response.plan_data.plan_name | extractPlanName
                }}</strong>
              </div>
              <table class="table-design">
                <thead>
                  <tr>
                    <th class="plan-text">Sum Insure :</th>
                    <th class="plan-text">Family Size</th>
                    <th class="premium-amount">Premium</th>
                  </tr>
                  <tr>
                    <th class="plan-period">
                      &#8377;{{ plan_data_response?.plan_data?.sum_insured }}
                    </th>
                    <th class="plan-period">
                      {{ plan_data_response?.plan_data?.family_size }}
                    </th>
                    <th class="premium-amount">
                      &#8377;{{ plan_data_response?.plan_data?.premium
                      }}<span class="premium-year">/year</span>
                    </th>
                  </tr>
                </thead>
              </table>
              <!-- <div class="benefits-grid">
                            <div *ngFor="let benefit of getSelectedBenefits(plan_data_response?.plan_data?.benefits)" class="plan-text">
                                {{ benefit.key }}:
                                <span class="plan-period">{{ benefit.value }}</span>
                            </div>
                        </div> -->
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <div class="col-12 text-center d-flex flex-column align-items-center">
      <div>Do you want to continue with the same details?</div>
      <br />
    </div>
    <div class="col-12 center">
      <button
        class="submit-btn close-submit-button change-btn"
        type="submit"
        mat-button
        (click)="closeDialog()"
      >
        Back</button
      >&nbsp;
      <button
        class="submit-btn close-submit-button"
        type="submit"
        mat-button
        (click)="proceedJourney()"
      >
        Continue
      </button>
    </div>
  </div>
</ng-container>
