import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '../../model/data.model';
import { environment } from 'src/environments/environment';
import { AngularDeviceInformationService } from 'angular-device-information';
import { WindowRef } from '../../services/window.service';

@Component({
  selector: 'app-check-valid-agent',
  templateUrl: './check-valid-agent.component.html',
  styleUrls: ['./check-valid-agent.component.scss'],
})
export class CheckValidAgentComponent implements OnInit {
  validToken: any;

  constructor(
    // public dialogRef: MatDialogRef<CheckValidAgentComponent>,
    // @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData | any,
    private deviceInformationService: AngularDeviceInformationService,
    private win: WindowRef,
  ) {
    // this.dialogRef.disableClose = true;
    this.validToken = localStorage.getItem('token')    
  }

  ngOnInit(): void {}

  returnToHome() {
    if(localStorage.getItem('token') !== 'undefined') {
      if (this.deviceInformationService.isMobile()) {
        this.win.nativeWindow.ReactNativeWebView.postMessage('home');
      } else {
        window.location.href = `${environment['partnerPortalUrl']}home/`;
      }
    } 
  }
}
