import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../../model/data.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-relation-popup',
  templateUrl: './relation-popup.component.html',
  styleUrls: ['./relation-popup.component.scss']
})
export class RelationPopupComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<RelationPopupComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData | any,
  ) { }

  nmaritalstatus: {
    name: string;
    value: number;
}[] = [{
    name: 'Single',
    value: 1,
}, {
    name: 'Married',
    value: 2,
}];

  ngOnInit(): void {
  }

}
