import { DatePipe } from "@angular/common";
import { Component, Input, Inject, Optional, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { map, take } from "rxjs";
import { DialogData } from "src/app/model/data.model";
import { SharedDataService } from "src/app/services/shared-data.service";
import { AngularDeviceInformationService } from "angular-device-information";
import { WindowRef } from "../../services/window.service";
import { environment } from "src/environments/environment";
import { CommonModalService } from "src/app/services/common-modal.service";
import { ApiService } from "src/app/services/api.service";
import { PlanCardComponent } from "src/app/component/plan-card/plan-card.component";

@Component({
  selector: "app-age-change",
  templateUrl: "./age-change.component.html",
  styleUrls: ["./age-change.component.scss"],
})
export class AgeChangeComponent implements OnInit {
  agentName: any;
  plan_data_response: any;

  noPlan: boolean = false;
  memberSwapped: boolean = false;
  planMemberSwapped: boolean = false;
  baseURl: string;
  orderId: any;

  constructor(
    private dialogRef: MatDialogRef<AgeChangeComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData | any,
    private sharedDataService: SharedDataService,
    private router: Router,
    private datePipe: DatePipe,
    private activatedRoute: ActivatedRoute,
    private deviceInformationService: AngularDeviceInformationService,
    private win: WindowRef,
    private commonModal: CommonModalService,
    private apiService: ApiService
  ) {
    this.baseURl = location.pathname;
    if (
      data.data["plan_swapped"] === false &&
      data.data["member_swapped"] === true
    ) {
      this.memberSwapped = true;
    } else if (
      data.data["plan_swapped"] === true &&
      data.data["member_swapped"] === true
    ) {
      this.planMemberSwapped = true;
    } else if (
      data.data["plan_swapped"] === false &&
      data.data["member_swapped"] === false
    ) {
      this.dialogRef.close({ event: "close" });
    } else {
      this.noPlan = true;
    }
    this.plan_data_response = data["data"];
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {}

  /** This function is used to close the dialog. **/
  closeDialog() {
    this.dialogRef.close({ event: "close" });
    this.dialogRef.close({
      event: "close",
      data: this.plan_data_response,
      removeAdult2Data: "true",
    });

    // if (this.deviceInformationService.isMobile()) {
    //   this.win.nativeWindow.ReactNativeWebView.postMessage("home");
    // } else {
    //   window.location.href = `${environment["partnerPortalUrl"]}home/`;
    // }
  }

  /** This function is used to filter and retrieve specific benefits from the given array. **/
  // getSelectedBenefits(benefits: any[]): any[] {
  //   const benefitKeys = [
  //     "Tele Consultations-GP+ Routine Specialists",
  //     "Flexi Health Wallet",
  //     "Dental check up by Clove Dental",
  //     "Health Risk Assessment",
  //   ];
  //   if (Object.keys(benefits).length > 0) {
  //     return [];
  //   } else {
  //     return benefits
  //       ? benefits
  //           .filter((benefit) => benefitKeys.includes(benefit.Key))
  //           .map((benefit) => ({ Key: benefit.Key, Value: benefit }))
  //       : [];
  //   }
  // }

  /**
   * This function is used to proceed with the plan selection journey.
   * It retrieves the plan data using the shared data service and then navigates to the proposerv2 route.
   */
  proceedJourney() {
    this.sharedDataService.getUpdatePlanId(
      this.plan_data_response.plan_data.plan_id
    );
    let planCard = new PlanCardComponent(
      this.sharedDataService,
      this.commonModal,
      this.router,
      this.activatedRoute,
      this.apiService
    );
    planCard;
    this.sharedDataService.getRetrievePlan(
      "plan_id=" + this.plan_data_response.plan_data.plan_id
    );
    this.sharedDataService.retrievePlan.pipe(take(2)).subscribe((res) => {
      this.sharedDataService.getPlanData({
        dob: this.datePipe.transform(
          this.plan_data_response["customer"]["dob"],
          "yyyy-MM-dd"
        ),
        amount: res?.["discounted_amount"],
        plan_id: res?.["id"],
        type: res?.["plan_type"],
        max_child: res?.["max_child"],
        is_opd: res?.["is_opd"],
        plan_name: res?.["name"],
        pincode: this.plan_data_response["customer"]["pincode"],
        // insurer_name  : insurer,
      });

        // Store the data in session storage as a string (JSON format)
        // sessionStorage.setItem('planData', JSON.stringify(planData));
        // // Call the service method
        // this.sharedDataService.getPlanData(planData);

      this.dialogRef.close({
        event: "close",
        data: this.plan_data_response,
        plan_details: res,
      });

      if (this.baseURl === "/renewbuy-health/") {
        if (this.data?.ids?.orderId) {
          this.router.navigate([
            "proposer",
            res?.["id"],
            this.data?.ids?.orderId,
          ]);
        } else {
          this.router.navigate(["proposer", res?.["id"]]);
        }
      } else if (this.baseURl === "/rbhealth-renewals/") {
        if (this.data?.ids?.plan) {
          this.router.navigate(["proposer", res?.id]);
        } else {
          this.router.navigate(["proposer"]);
        }
      } else {
        const token = sessionStorage.getItem("encrypted_token");
        if (token) {
          this.router.navigate(["proposer"]);
        } else {
          this.router.navigate(["proposer", res?.["id"]]);
        }
      }
    });
  }
}
