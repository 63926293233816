import { Component, OnInit,OnDestroy, Optional, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../../model/data.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-incorret-mobile-number',
  templateUrl: './incorret-mobile-number.component.html',
  styleUrls: ['./incorret-mobile-number.component.scss']
})
export class IncorretMobileNumberComponent implements OnInit,OnDestroy {

  constructor(
    private router:Router,
    private dialogRef: MatDialogRef<IncorretMobileNumberComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData | any,
) { }
ngOnInit(): void {
}
closeDialog() {
    this.dialogRef.close({ event: 'close' });
}
backtohome(){
  this.dialogRef.close();
  window.location.reload();
//  this.reloadPage()
}
reloadPage() {
  // const urlOBtained = this.router['url'];
  // this.router.navigate([urlOBtained]).then(() => {
      window.location.reload();
  //});
}
ngOnDestroy(): void {
    this.reloadPage()
  }
}
